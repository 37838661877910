/* .animation-container {
  position: absolute;
  animation: moveAnimation 5s infinite;
}

@keyframes moveAnimation {
  0% { top: 0; left: 0; }
  50% { top: 50%; left: 50%; }
  100% { top: 100%; left: 100%; }
} */

.animation-container {
  position: fixed;
  transform: translate(0px, 0px);
  transition: transform 5s ease-in-out;
  z-index: 999999 !important;
}
