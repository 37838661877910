@import "../scss/variables";

/*------custom toggle switch-------*/
@mixin can-toggle-branding(
  $can-toggle-inactive-text: rgba(white, 0.5),
  $can-toggle-transition: cubic-bezier(0, 1, 0.5, 1)
) {
  input[type="checkbox"] {
    &[disabled] ~ label {
      color: rgba(var(--can-toggle-off-color), 0.5);
    }

    &:focus ~ label,
    &:hover ~ label {
      .can-toggle__switch {
        background-color: var(--can-toggle-off-color);
        &:after {
          color: hsl(from var(--can-toggle-off-color) h s calc(l - 10%));
        }
      }
    }
    &:hover ~ label {
      color: hsl(from var(--can-toggle-off-color) h s calc(l - 5%));
    }
    &:checked {
      ~ label {
        &:hover {
          color: hsl(from var(--can-toggle-on-color) h s calc(l - 3%));
        }

        .can-toggle__switch {
          background-color: hsl(from var(--can-toggle-on-color) h s calc(l + 5%));
          &:after {
            color: hsl(from var(--can-toggle-on-color) h s calc(l - 5%));
          }
        }
      }

      &:focus,
      &:hover {
        ~ label {
          .can-toggle__switch {
            background-color: var(--can-toggle-on-color);
            &:after {
              color: hsl(from var(--can-toggle-on-color) h s calc(l - 10%));
            }
          }
        }
      }
    }
  }

  label {
    .can-toggle__label-text {
      flex: 1;
    }

    .can-toggle__switch {
      transition: background-color 0.3s $can-toggle-transition;
      background: hsl(from var(--can-toggle-off-color) h s calc(l + 5%));
      &:before {
        color: $can-toggle-inactive-text;
      }
      &:after {
        // Autoprefixer choked here, so making the prefixes explicit
        -webkit-transition: -webkit-transform 0.3s $can-toggle-transition;
        transition: transform 0.3s $can-toggle-transition;
        color: var(--can-toggle-off-color);
      }
    }
  }
}

@mixin can-toggle-appearance(
  $can-toggle-width: 134px,
  $can-toggle-height: 36px,
  $can-toggle-border-radius: 4px,
  $can-toggle-offset: 2px,
  $can-toggle-label-font-size: 14px,
  $can-toggle-switch-font-size: 12px,
  $can-toggle-shadow: 0 3px 3px rgba(black, 0.4)
) {
  $can-toggle-switch-width: $can-toggle-width/2;

  input[type="checkbox"] {
    &:focus ~ label,
    &:hover ~ label {
      .can-toggle__switch {
        &:after {
          box-shadow: $can-toggle-shadow;
        }
      }
    }

    &:checked {
      ~ label {
        .can-toggle__switch {
          &:after {
            transform: translate3d($can-toggle-width - ($can-toggle-switch-width + $can-toggle-offset), 0, 0);
          }
        }
      }
      &:focus,
      &:hover {
        ~ label {
          .can-toggle__switch {
            &:after {
              box-shadow: $can-toggle-shadow;
            }
          }
        }
      }
    }
  }

  label {
    font-size: $can-toggle-label-font-size;

    .can-toggle__switch {
      height: $can-toggle-height;
      flex: 0 0 $can-toggle-width;
      border-radius: $can-toggle-border-radius;

      &:before {
        left: $can-toggle-width/2;
        font-size: $can-toggle-switch-font-size;
        line-height: $can-toggle-height;
        width: $can-toggle-width/2;
        padding: 0 12px;
      }

      &:after {
        top: $can-toggle-offset;
        left: $can-toggle-offset;
        border-radius: $can-toggle-border-radius/2;
        width: $can-toggle-switch-width - $can-toggle-offset;
        line-height: $can-toggle-height - ($can-toggle-offset * 2);
        font-size: $can-toggle-switch-font-size;
      }

      &:hover {
        &:after {
          box-shadow: $can-toggle-shadow;
        }
      }
    }
  }
}

.can-toggle {
  position: relative;
  display: flex;
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  //overflow: hidden;

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    &[disabled] ~ label {
      pointer-events: none;
      .can-toggle__switch {
        opacity: 0.4;
      }
    }

    &:checked {
      ~ label {
        .can-toggle__switch {
          &:before {
            content: attr(data-unchecked);
            left: 0;
          }

          &:after {
            content: attr(data-checked);
          }
        }
      }

      &:focus,
      &:hover {
        ~ label {
        }
      }
    }
  }

  label {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;

    .can-toggle__label-text {
      flex: 1;
      padding-left: 32px;
    }

    .can-toggle__switch {
      position: relative;

      &:before {
        content: attr(data-checked);
        position: absolute;
        top: 0;
        text-transform: uppercase;
        text-align: center;
      }

      &:after {
        content: attr(data-unchecked);
        position: absolute;
        z-index: 5;
        text-transform: uppercase;
        text-align: center;
        background: white;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  // Default values for .can-toggle class
  @include can-toggle-branding;
  @include can-toggle-appearance;

  // Create toggles of different sizes by overriding the can-toggle-appearance mixin defaults
  &.can-toggle--size-small {
    @include can-toggle-appearance(
      90px,
      // Toggle width
      28px,
      // Toggle height
      2px,
      // Toggle border radius
      1px,
      // Offset (distance btw switch and box)
      13px,
      // Label font size
      10px,
      // Switch font size
      0 2px 2px rgba(black, 0.4) // Switch shadow on hover/focus
    );
  }
}

/*---------------*/
.tri-state-toggle {
  background: rgba(165, 170, 174, 0.25);
  box-shadow: inset 0 2px 8px 0 rgba(165, 170, 174, 0.25);
  border-radius: 24px;
  display: inline-block;
  overflow: hidden;
  display: inline-flex;
  flex-direction: row;
  transition: all 500ms ease;
}

.tri-state-toggle-button {
  border-radius: 22px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  background-color: transparent;
  border: 0px solid transparent;
  margin: 2px;
  color: #727c8f;
  cursor: pointer;

  /*    -webkit-transition: all 0.5s ease-in-out;
-moz-transition:    all 0.5s ease-in-out;
-ms-transition:     all 0.5s ease-in-out;
-o-transition:      all 0.5s ease-in-out; */
  transition: all 0.5s ease;
}

.tri-state-toggle-button.active {
  //background-image: linear-gradient(-180deg, #fff 0%, #FAFAFA 81%, #F2F2F2 100%);
  background-color: var(--primary-color);
  border: 1px solid rgba(207, 207, 207, 0.6);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  color: #fff;
  font-weight: 500;
  transition: all 0.5s ease-in;
}

.tri-state-toggle-button:focus {
  outline: none;
  //background-image: linear-gradient(-180deg, #fff 0%, #FAFAFA 81%, #F2F2F2 100%);
  background-color: var(--primary-color);
  border: 1px solid rgba(207, 207, 207, 0.6);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  color: #fff;
  font-weight: 500;
  transition: all 0.5s ease-in;
}
