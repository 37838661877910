/* Base styles for the character container */
.animation-container-character-mothers-day {
  z-index: 99; /* High z-index to keep on top */
  width: 80px; /* Default width for very small screens */
  height: 80px; /* Default height for very small screens */
}

/* Small screens (e.g., phones in portrait) */
@media (min-width: 480px) {
  .animation-container-character-mothers-day {
    width: 90px;
    height: 90px;
  }
}

/* Medium screens (e.g., tablets in portrait) */
@media (min-width: 768px) {
  .animation-container-character-mothers-day {
    width: 100px; /* Default size */
    height: 100px; /* Default size */
  }
}

/* Large screens (e.g., laptops) */
@media (min-width: 1024px) {
  .animation-container-character-mothers-day {
    width: 120px;
    height: 120px;
  }
}

/* Extra large screens (e.g., desktops) */
@media (min-width: 1440px) {
  .animation-container-character-mothers-day {
    width: 150px;
    height: 150px;
  }
}

/* Ultra large screens (e.g., large desktops, TVs) */
@media (min-width: 1920px) {
  .animation-container-character-mothers-day {
    width: 180px;
    height: 180px;
  }
}
