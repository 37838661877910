@import '../../assets/styles/scss/variables';
@import '../../assets/styles/scss/mixins';
@import '../../assets/styles/scss/typography';

/*---shift swap---*/
.swap-icn-bg{
    background: hsl(from var(--primary-color) h s calc(l - 5));
    margin: -4px -4px -4px 5px;
    border-radius: 0 2px 2px 0;
  }
  .swap-icn-bg:has(.p-button){
    background: none;
    margin: 0;
    border-radius: 0;
  }
  
  .swap-req-tooltip{
    &.p-tooltip{
      .p-tooltip-text{
        background: #fff !important;
        box-shadow: 0px 0px 15px -3px rgba(0,0,0,0.1);
        border: 1px solid #d2d2d2 !important;
        font-weight: 500;
        color: var(--text-color);
      }
    
    }
  }

.bg-active-user {
    .p-datatable-wrapper {
        .p-datatable-table {
            .p-datatable-tbody {
                tr {
                    &:nth-child(1) {
                        background-color: #e5f2ff;
                    }
                }
            }
        }
    }
}

.swap-request-form {
    .p-inputtext {
        padding: 0.5rem 0.5rem !important;
    }
}

.swap-schedule-card {
    &-default {
        background-color: #87b6fb !important;
    }
    &-approved {
        background-color: #89D99D !important;
    }
    &-rejected {
        background-color: #FF9182 !important;
    }
    &-expired {
        background-color: #f3f3f3  !important;
        border: 1px solid #e5e5e5  !important;
        color: #7e7e7e;
    }
    &-pending {
        background-color: #fbaa07 !important;
    }
}