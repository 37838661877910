@keyframes moveRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.animation-container-cincodemayo-logo {
  position: absolute;
  /* transform: translate(0px, 0px); */
  /* transition: transform 5s ease-in-out; */
  z-index: 999999 !important;
  top: 0 !important;
  max-width: 100px !important;
  max-height: 100px !important;    
  right: 0;

    img{
      position: relative;
      top: -15px;
      height: 45px;
      right: -15px; 
    }

}
