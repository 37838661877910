@import "../../assets/styles/scss/variables";
@import "../../assets/styles/scss/mixins";
@import "../../assets/styles/scss/typography";

.help-banner {
    /* backgroundColor: '#FFD966',
    margin: '2rem',
    textAlign: 'center',
    backgroundImage: `url(${helpBanner})`,
    height: '230px',
    borderRadius: "6px",
    back*/
    background-image:
        url("../../assets/images/help_bg.png"),
        /* Background image */ linear-gradient(90deg, #feaa0e 0%, #ffc768 100%); /* Gradient overlay */
    //margin: 2rem;
    text-align: center;
    height: 230px;
    //border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.help-search-highlight {
    //background-color: yellow;
    //color: #000;
}

.help-list-content {
    margin-left: 1.5rem;
    padding-left: 1rem;
    margin-bottom: 2rem;
}

.help-accordion-section {
    padding: 2rem;
    h4 {
        font-size: 1.2rem;
        margin-bottom: 0.8rem;
    }
    ol {
        margin-bottom: 1.5rem;
        &.help-list-content {
            li {
                margin-bottom: 0.5rem;
            }
        }
    }

    .help-img {
        width: auto;
        max-width: 600px;
        display: block;
        margin-bottom: 2rem;
        margin-top: 1rem;
    }
    @media (max-width: 740.98px) {
        .help-img {
            width: 100%;
        }
    }
}

@media (max-width: 992.98px) {
    .help-accordion-section {
        padding: 1rem !important;

        .p-accordion-header-link {
            font-weight: 500;
        }
    }
}


.ant-image-preview-root {
    .ant-image-preview-mask, .ant-image-preview-wrap {
        z-index: 1040 !important;
    }
}