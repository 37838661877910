@import "../../../assets/styles/scss/_variables.scss";
@import "../../../assets/styles/scss/_typography.scss";

body {
    //height: 100% !important;
}

/*-----roles------*/
.roles-link {
    h5 {
        font-weight: 600;
        font-size: 1rem;
        margin-bottom: 10px;
    }

    ul {
        padding: 0;
        li {
            list-style: none;
            background: var(--bg-color);
            padding: 0.5rem;
            border-radius: 5px;
            color: var(--font-color);
            margin-bottom: 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:hover {
                background: var(--primary-color);
                color: var(--white-color);
                transition: all;
            }
            &.active {
                background: var(--primary-color);
                color: var(--white-color);
                transition: all;
            }

            span {
                color: var(--red-color);
                align-items: center;
                display: flex;
                padding: 0 0.5rem;
                font-size: 1.1rem;

                &:hover {
                    color: hsl(from var(--red-color) h s calc(l - 20));
                    cursor: pointer;
                }

                &.disabled {
                    pointer-events: none;
                    filter: none;
                    opacity: 0.5;
                }
            }
        }
    }
}

.roles-btn-group {
    position: absolute;
    left: 0;
    bottom: 0;
}

.min-H {
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}
.min-H-inner {
    //height: calc(100% - 48px);
    height: calc(100% - 42px);

    .over-fl-at {
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
}

.roles-sticky-header {
    position: sticky;
    background-color: #fff;
    top: 0rem;
    padding: 1rem 0 0 0;
}

.pb-6 {
    padding-bottom: 4rem !important;
}

@media (max-width: 767.98px) {
    .min-H {
        overflow-y: auto;
        height: auto;

        .save-btn-section {
            position: fixed;
            left: 0;
            bottom: 0px;
        }
    }

    .min-H-inner {
        .over-fl-at {
            overflow-y: auto;
            padding-bottom: 3rem;
        }
    }
}
