@import "../../../assets/styles/scss/_variables.scss";
@import "../../../assets/styles/scss/_typography.scss";
.display-qstn-set-ttl {
    //color: var(--primary-color);
    // background-color: hsl(from var(--primary-color) h s calc(l + 40));
    // padding: 0.5rem;
    //  border-bottom: 1px solid hsl(from var(--primary-color) h s calc(l + 20));
    /* color: #FBAA07; */
    background-color: #e6e6e6;
    padding: 0.5rem;
    border-bottom: 1px solid #ffffff;
    border-radius: 0.2rem;
}
.display-qstn-container {
    background-color: #fff;
    padding-top: 1rem;
    //border-bottom: 1px solid #ebebeb;
    box-shadow: -2px 0px 10px 0px #e1e1e3;
    border: solid 1px #e6e6e6;
    border-radius: 0.3rem;
}
.save-btn-section-footer {
    width: 100%;
    background-color: #feefd0;
    /* padding: 1rem; */
    position: sticky;
    bottom: 0px;
    text-align: right;
    z-index: 9;
}
.ques-icon {
    color: #878787;
}
.survey-report-border {
    border-bottom: 1px solid #ebe7e7;
}
