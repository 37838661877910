.custom-input-spinner {
    position: relative;
    i {
        position: absolute;
        right: 10px;
        top: 10px;
    }
}

.user-block-ui {
    opacity: 0.2 !important;
}