@import "../../../assets/styles/scss/_variables.scss";
@import "../../../assets/styles/scss/_typography.scss";

.qr-status {
    background-color: rgba(var(--blue-color-rgb), 0.12);
    border-radius: 1rem;
    text-align: center;
    padding: 0.2rem 0.8rem;
    min-width: 90px;
    color: var(--blue-color);

    &.redeemed {
        color: var(--primary-color);
        background-color: rgba(var(--primary-color-rgb), 0.12);
    }
    &.expired {
        color: var(--red-color);
        background-color: rgba(var(--red-color-rgb), 0.12);
    }
}

.qr-description {
    text-align: center;
    position: relative;
    top: -1.5rem;
    color: #979797;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
        margin-right: 0.3rem;
    }
}

.grecaptcha-badge {
    display: none !important;
}
