@keyframes moveRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.animation-container-mothers-day-logo {
  position: absolute;
  /* transform: translate(0px, 0px); */
  /* transition: transform 5s ease-in-out; */
  z-index: 999999 !important;
  top: -5px !important;
  max-width: 100px !important;
  max-height: 100px !important;
  right: -38px;

    img{
      position: relative;
      top: 0px;
      height: 45px;
      right: 0px;
    }

}

.welcomePage .animation-container-mothers-day-logo {
  right: -45px;
  }
  
  .welcomePage .mobile-logo .animation-container-mothers-day-logo {
    right: -38px;
    }
