@font-face {
     font-family: "Poppins";
     src:
          url("../../fonts/Poppins-Bold.ttf") format("truetype"),
          url("../../fonts/poppins-bold-webfont.woff") format("woff"),
          url("../../fonts/poppins-bold-webfont.woff2") format("woff2");
     font-weight: bold;
     font-style: normal;
}

@font-face {
     font-family: "Poppins";
     src:
          url("../../fonts/Poppins-SemiBold.ttf") format("truetype"),
          url("../../fonts/poppins-semibold-webfont.woff") format("woff"),
          url("../../fonts/poppins-semibold-webfont.woff2") format("woff2");
     font-weight: 600;
     font-style: normal;
}

@font-face {
     font-family: "Poppins";
     src:
          url("../../fonts/Poppins-Medium.ttf") format("truetype"),
          url("../../fonts/poppins-medium-webfont.woff") format("woff"),
          url("../../fonts/poppins-medium-webfont.woff2") format("woff2");
     font-weight: 500;
     font-style: normal;
}

@font-face {
     font-family: "Poppins";
     src:
          url("../../fonts/Poppins-Regular.ttf") format("truetype"),
          url("../../fonts/poppins-regular-webfont.woff") format("woff"),
          url("../../fonts/poppins-regular-webfont.woff2") format("woff2");
     font-weight: normal;
     font-style: normal;
}

/*----font size---*/
$normal-font-sz: 1rem;
$small-font-sz: 0.8125rem;
$button-font-sz: 1.125rem;
$button-font-lg: 1.25rem;
$normal-title-sz: 1.1rem;
$item-title-size: 1.5rem;
$sidebar-icn-sz: 1.3rem;
$winner-msg: 2rem;
$nav-font-sz: 1.4rem;
$custom-font-family: "Poppins", sans-serif;
$font-for-mobile: 12px;
/**---mediaQuery for font sizing---*/

:root {
     --normal-font-sz: 1rem;
     --small-font-sz: 0.8125rem;
     --button-font-sz: 1.125rem;
     --button-font-lg: 1.25rem;
     --normal-title-sz: 1.1rem;
     --item-title-size: 1.5rem;
     --sidebar-icn-sz: 1.3rem;
     --winner-msg: 2rem;
     --nav-font-sz: 1.4rem;
     --custom-font-family: "Poppins", sans-serif;
     --font-for-mobile: 12px;
}
