@import "../../../assets/styles/scss/_variables";
@import "../../../assets/styles/scss/_typography";
@import "../../../assets/styles/scss/_mixins";

.btn-9 {
  background: var(--primary-color);
  font-size: 20px;
  color: white;
  border-radius: 7px;
  box-shadow: 0 7px 0px hsl(from var(--primary-color) h s calc(l - 20));
  display: inline-block;
  transition: all 0.2s;
  position: relative;
  padding: 20px 25px;
  position: relative;
  top: 0;
  cursor: pointer;
  margin: 0 20px;
}

.btn-9:active {
  top: 3px;
  box-shadow: 0 2px 0px hsl(from var(--primary-color) h s calc(l - 20));
  transition: all 0.2s;
}
/* Buton 9 Bitti */

.gameouter {
  //min-height: calc(100vh - 101.3px);
  //height:  calc(100vh - 101.3px);
  height: 100%;
  background-image: url(../../../assets/images/game-bg.svg);
  background-position: bottom center;
  background-size: cover;
  overflow-x: hidden;
}

.winner-modal {
  .game-winner-img-container {
    display: inline-flex;
    justify-content: center;
  }
  h4 {
    &.winner-msg {
      font-size: var(--winner-msg);
      color: var(--primary-color);
    }
    &.betterluck-msg {
      font-size: var(--winner-msg);
      color: var(--red-color);
    }
  }
  .modal-header {
    background: transparent;
    border: none;
  }
}

.gameBg {
  // min-height: calc(100vh - 142.75px);
}

.spin-button {
  background: linear-gradient(180deg, #efb643 0%, #fbaa07 100%);
  border: 1px solid rgba(255, 197, 0, 0.9);
  padding: 1rem 2rem !important;
  filter: drop-shadow(1px 5px 15px rgba(237, 152, 0, 0.3));

  &.btn-sm {
    padding: 0.5rem 2rem !important;
  }
}

.game-zip {
  .p-chips {
    display: block;
    .p-inputtext {
      min-height: 35.75px;
      padding: 0.1rem 0.15rem;
      border-radius: 0.25rem !important;

      &:hover {
        border-color: var(--primary-color);
      }

      .p-chips-token {
        padding: 0.175rem 0.35rem;
        margin-right: 0.3rem;
        margin-bottom: 0.1rem;

        // color: var(--font-color);

        &.p-chips-token-icon {
          margin-left: 0.3rem;
        }
      }

      .p-chips-input-token {
        padding: 0rem 0;
      }
    }

    .p-focus {
      @include box-shadow-primary-custom;
      border-color: var(--primary-color);

      &.p-inputtext {
        border-color: var(--primary-color);
      }
    }
  }
  .ui-chips {
    background-color: #000 !important;
  }
}
.bg-no-offer {
  background: #f1f1f1;
}

/*----view all price---*/
.prize-list-table {
  max-height: 450px;
  overflow: auto;

  .leaderboard {
  }

  /* head */
  .leaderboard .head {
    padding: 0.5rem 0.5rem;
    color: var(--white-color);
    font-size: 1rem;
    //text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary-color);
    border-radius: 5px 5px 0 0;
    position: sticky;
    top: 0;

    i {
      font-size: 1.5rem;
    }
  }
  .leaderboard .head h1 {
    display: inline-block;
    margin-left: 4px;
    font-size: 1.5rem;
    margin-bottom: 0;
  }

  /* body */
  .leaderboard .body {
    color: #fff;
    font-size: 1rem;
  }
  .leaderboard ol {
    counter-reset: number;
    padding: 0;
    margin-bottom: 0rem;
  }
  .leaderboard li {
    padding: 0.5rem 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .leaderboard li mark {
    flex-grow: 1;
    color: var(--font-color);
    background-color: transparent;
  }
  .leaderboard li:before {
    counter-increment: number;
    content: counter(number) ".";
    margin-right: 0.5rem;
    color: var(--font-color);
  }
  .leaderboard li {
    background: #e3e3e3;
    border-bottom: 2px solid #fff;
  }
  .leaderboard li:last-child {
    border-radius: 0 0 5px 5px;
  }
  .leaderboard li:first-child {
    border-top: 2px solid #fff;
  }
}
