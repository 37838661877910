@media all and (orientation: portrait) {
    .user-style {
        background: url(../../images/bg_new.jpg) no-repeat !important;
        background-size: cover !important;
        background-position: 50% 50% !important;
    }
}

@media (max-width: 1399.98px) {
    .ingredients-gallery {
        .p-galleria-thumbnail-item-content {
            img {
                width: 65px !important;
            }
        }
    }
}

@media (max-width: 1199.98px) {
    .forget-pass-sec {
        justify-content: center !important;
        gap: 10px;
    }
}

@media (max-width: 990.98px) {
    .forget-pass-sec {
        justify-content: space-between !important;
    }
}

@media (max-width: 1180.98px) {
    #layoutSidenav {
        #layoutSidenav_nav {
            //width: 77px;
            width: 295px;
            padding-right: 1rem;
            transition: all 0.5s ease;

            .copyright-container {
                .copyright {
                    display: block;
                }

                .copyright-collapse {
                    display: none !important;
                }
            }

            .menu-text {
                // display: none;
                display: inline-block;
            }

            .sb-nav-link-icon {
                margin-right: 0.5rem !important;
            }
        }

        #layoutSidenav_content {
            // margin-left: 70px;
            transition: margin 0.15s ease-in-out;
            overflow-y: auto;
            overflow-x: hidden;
            margin-left: 0;
        }
    }

    .sb-sidenav-toggled {
        #layoutSidenav {
            #layoutSidenav_nav {
                transform: translateX(0);
                //width: 295px;
                width: 77px;
                padding-right: 0;
                transition: all;
                //display: none;
                width: 0;
                transition: all 0.5s ease;

                .copyright-container {
                    .copyright {
                        display: none;
                    }

                    .copyright-collapse {
                        display: block !important;
                        padding-left: 0;
                    }
                }

                .menu-text {
                    display: none;
                }

                .sb-sidenav {
                    .sb-sidenav-menu {
                        .nav {
                            padding-left: 0;
                            align-items: center;

                            .nav-link {
                                transition: all;
                                width: 100%;
                                padding-left: 23px;
                                border-radius: 0;

                                .sb-nav-link-icon {
                                    margin-right: 0;
                                    transition: all;
                                }
                            }

                            .sb-sidenav-menu-nested {
                                display: none;
                                transition: all;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 999.98px) {
    .ingredients-gallery {
        .p-galleria-thumbnail-item-content {
            img {
                width: 60px !important;
            }
        }
    }
}

@media only screen and (max-width: 1112px) and (min-width: 991.98px) {
    .custom-datepicker {
        &.react-date-picker {
            .react-date-picker__wrapper {
                .react-date-picker__inputGroup {
                    .react-date-picker__inputGroup__input {
                        width: 28.33% !important;
                    }
                }
            }
        }
    }
}

@media (max-width: 991.98px) {
    .sb-nav-fixed {
        #layoutSidenav {
            #layoutSidenav_content {
                margin-left: 0;
                overflow: hidden;
                min-height: 100vh;
            }
        }
    }

    .image-menu-sticky {
        //top: 45.5px;
    }

    /*  .nav-link {
        &::after {
            content: none !important;
        }

        &.active {
            &::after {
                content: none !important;
            }
        }
    } */

    .gameouter {
        // min-height: calc(100vh - 107.3px) !important;
        // height: calc(100vh - 107.3px) !important;
    }

    .user-dp {
        margin-bottom: 0.5rem;

        label {
            display: none;
        }

        margin-left: 1rem;
    }

    .update {
        justify-content: end;
        width: 100%;
        margin-bottom: 0 !important;

        label {
            display: none;
        }
    }

    #GuestMain {
        .item-list-main {
            .item-list-image {
                aspect-ratio: 16/9;
            }
        }
    }

    .scroll-menu-container {
        .nav-scroller-nav {
            .nav-scroller-item {
                width: 150px !important;
                height: 100px;

                img {
                    //aspect-ratio: 3 / 2;
                }
            }
        }
    }
}

@media all and (max-height: 600px) {
    .scroll-menu-container {
        .nav-scroller-nav {
            .nav-scroller-item {
                width: 140px !important;
                height: 75px;
            }
        }
    }
}

@media (max-width: 786.98px) {
    .card-header {
        .title-section {
            margin-bottom: 0.5rem;
        }

        .d-flex {
            &.header-outer {
                display: block !important;
            }
        }

        .filter-section {
            * {
                margin-bottom: 0;
                justify-content: start;
            }
        }
    }
}

@media (max-width: 840px) {
    .welcomePage {
        > .container {
            max-width: 100%;
            //padding:0;
        }
    }
}
@media (max-width: 460px) {
    .welcomePage {
        > .container {
            max-width: 100%;
            padding: 0.3rem;
        }
    }
}

@media (max-width: 768.98px) {
    #layoutSidenav_content {
        display: flex !important;
    }

    .adbox-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .p-5 {
            padding: 1.5rem !important;
        }
    }

    #GuestMain {
        .adbox-container {
            h3 {
                font-size: 3rem;
            }

            .flex-grow-1 {
                display: none !important;
            }

            .survey-header {
                .flex-grow-1 {
                    display: flex !important;
                    text-align: center;
                }
            }
        }

        .image-large-sticky {
            position: relative;
            top: 0;
            height: auto;
        }
    }

    .addMenubox-container {
        padding: 1.5rem 0.5rem;
        min-width: 100px;
    }

    .add-btn-outer {
        .addMenubox-container {
            padding: 1.5rem 0.5rem !important;
            min-width: 100px !important;
        }
    }

    .bg-no-offer {
        .row {
            flex-direction: column !important;
            align-items: flex-start !important;

            * {
                //margin-bottom: 0.5rem!important;
                width: 100%;
            }

            .help-main {
                padding-right: 0.5rem;

                i {
                    width: auto;
                }
            }

            .display-count {
                &.p-0 {
                    padding-left: 0.5rem !important;
                }
            }

            .btn {
                width: auto !important;
            }
        }
    }

    .user-dp {
        margin-bottom: 0.5rem;

        label {
            display: none;
        }

        margin-left: 1rem;
    }

    .update {
        justify-content: end;
        width: 100%;
        margin-bottom: 0 !important;

        label {
            display: none;
        }
    }

    .card-header {
        .title-section {
            margin-bottom: 0.5rem;
        }

        .d-flex {
            &.header-outer {
                display: block !important;
            }
        }

        .filter-section {
            * {
                margin-bottom: 0;
                justify-content: start;
            }
        }
    }
}

@media (max-width: 752.98px) {
    .icon-only-btn {
        min-width: 40px;
        align-items: center;
        display: flex;
        justify-content: center;

        .btn-text {
            display: none;
        }

        i {
            margin: 0 !important;
        }

        .font-ic {
            height: 21px;
            margin: 0 !important;
        }
    }

    .has-search {
        .form-control-feedback {
            color: var(--primary-color);
        }
        &.search-small-box {
            .form-control-feedback {
                color: var(--primary-color);
            }

            .form-control {
                border: 1px solid var(--primary-color);
            }

            input[type="text"] {
                width: 47px;
                color: transparent;

                &::placeholder {
                    visibility: hidden;
                }

                &:focus {
                    width: auto !important;
                    color: inherit;
                }
            }
        }
    }
}

@media (max-width: 718.98px) {
    .card-header {
        .title-section {
            margin-bottom: 0.5rem;
        }

        .d-flex {
            &.header-outer {
                display: block !important;
            }
        }

        .filter-section {
            * {
                margin-bottom: 0;
                justify-content: start;
            }
        }
    }
}

@media (max-width: 700.98px) {
    .branding-upload {
        .ant-upload-select {
            height: 150px !important;
            width: 150px !important;
        }
    }

    .upload-container {
        .ant-upload-list-item-container {
            height: 150px !important;
            width: 150px !important;
        }
    }
}

/*--small screen and mobile----*/
@media (max-width: 680.98px) {
    .wine-history-inner {
        display: flex;
        flex-direction: column;
    }

    .adbox-container {
        img {
            display: none;
        }
    }

    .history-img-view {
        width: 180px;
        height: 120px;
    }

    /*---event recurrence--*/
    .every-month-main {
        flex-wrap: wrap;
    }
    .every-check {
        width: 100%;
        margin-bottom: 0.5rem;
        //flex: 0;
    }
    .width-110 {
        width: 48%;
        margin-bottom: 0.5rem;
    }
    .every-month-main-second {
        flex-wrap: wrap;

        .width-50 {
            margin-bottom: 0.5rem;
        }
    }
}

@media (min-width: 577px) {
    .login-user-menu-item {
        display: none !important;
    }
}

@media (max-width: 576.98px) {
    .coupon-outer {
        display: block !important;

        .flex-grow-1 {
            width: 100% !important;
            margin-left: 0 !important;
            margin-bottom: 0.3rem;

            .btn-small-qr {
                display: block !important;
                width: 100% !important;
            }
        }
    }

    .image-left {
        width: 100%;
    }

    .login-user-menu-item {
        display: block !important;
    }

    .logout-container {
        button {
            .login-user {
                display: none;
            }
        }
    }
    .prime-icon-only-btn {
        .btn-text {
            display: none;
        }
    }
    .accordion-custom-schedule {
        display: flex;
        flex-direction: column;

        .accordion-button {
            flex-direction: column;
            align-items: flex-start;
            gap: 5px;

            .staff-availability-chip {
                &.text-nowrap {
                    white-space: normal !important;
                }
            }
        }
    }
}

@media (max-width: 500.98px) {
    .login {
        .login-box {
            .login-header {
                img {
                    height: 45px;
                }
                .country-item {
                    img {
                        height: 30px;
                    }
                }
            }

            .login-body {
                padding: 1rem;
            }
        }

        .btn-lg {
            font-size: 1.125rem;
        }

        .form-control-lg {
            font-size: 1rem;
        }
    }
}

/*--small screen and mobile----*/

@media (max-width: 480.98px) {
    #GuestMain {
        .headerBar {
            padding-left: 0 !important;
        }
    }

    .login {
        .login-box {
            .login-header {
                padding: 1rem 0rem;

                img {
                    height: 40px;
                }
                .country-item {
                    img {
                        height: 20px;
                    }
                }
            }
        }
    }

    .logout-container {
        .btn {
            min-width: auto;
        }
    }

    .item-list-main {
        flex-direction: row;
        /*height: auto !important;*/

        .item-list-image {
            margin-right: 0 !important;

            img {
                //width: 60% !important;
                /* width: 100%!important;
                aspect-ratio: 16/9; */
            }
        }

        /** {
            width: 100%;
        }*/
    }

    .scroll-menu-container {
        .nav-scroller-nav {
            .nav-scroller-item {
                width: 160px !important;
                height: 100px;

                .menu-title {
                    font-size: 1rem;
                }
            }
        }
    }

    #GuestMain {
        .item-list-main {
            .item-list-image {
                aspect-ratio: inherit;
                height: 150px;
                /*height: 120px;*/

                img {
                    width: auto;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;
                }
            }
        }
    }

    .survey-btn-container {
        .btn-lg {
            min-width: auto;
            padding: 0.8rem;
            font-size: 1rem;
        }
    }

    .adbox-container {
        .survey-header {
            h5 {
                font-size: 1.5rem !important;
            }
        }
    }

    .wine-history-title {
        h2 {
            font-size: 1.2rem;
            font-weight: bold;
        }
    }
}

@media (max-width: 450.98px) {
    .floating-container {
        .search-bg {
            gap: 3px;
            .rounded-circle {
                width: 35px;
                height: 35px;
            }
        }
    }
}

@media (max-width: 420.98px) {
    .star-rate-outer {
        .star {
            width: 100%;
            text-align: center;
        }

        .result {
            width: 100%;
            text-align: center;
            justify-content: center;

            .can-toggle {
                display: inline-block;
                transform: translateX(-67px);
            }
        }
    }
    .floating-container {
        .search-bg {
            gap: 3px;
            .rounded-circle {
                width: 30px;
                height: 30px;

                .pi {
                    font-size: 13px !important;
                }
            }
        }
        .search-container {
            .search-icn-left {
                display: none;
            }
        }
    }
    .every-month-main-second {
        .width-110 {
            width: 100%;
        }
    }
}

/*--small screen and mobile----*/
@media (max-width: 389.98px) {
    .forget-pass-sec {
        justify-content: center !important;
        gap: 10px;
    }
    .floating-container {
        .search-bg {
            gap: 3px;
            .rounded-circle {
                width: 28px;
                height: 28px;

                .pi {
                    font-size: 13px !important;
                }
            }
        }
        .search-container {
            .search-icn-left {
                display: none;
            }
        }
    }
}

@media (max-width: 382.98px) {
    .user-landing {
        .login-header {
            .welcome-user-profile {
                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }

    .logout-container {
        img {
            &.user-image {
                width: 30px !important;
                height: 30px !important;
            }
        }
    }

    .customLangTranslate {
        .country-item {
            img {
                width: 20px !important;
                height: 20px !important;
            }
        }
    }

    .increase-font-container {
        .increaseFont {
            .increase-button {
                margin-right: 2px;
                margin-left: 2px;
            }
        }
    }

    #GuestMain {
        .item-list-main {
            .item-list-image {
                height: 120px;
            }
        }
    }
}

@media (max-width: 380.98px) {
    .item-list-main {
        /* * {
            width: 100%;
            flex-direction: column;
        }*/

        .item-arrow {
            // text-align: center !important;
        }
    }

    .branding-upload {
        .ant-upload-select {
            height: 100px !important;
            width: 100px !important;
        }
    }

    .upload-container {
        .ant-upload-list-item-container {
            height: 100px !important;
            width: 100px !important;
        }
    }
    .btn-sm-block {
        width: 100%;
    }
}

@media (max-width: 320.98px) {
    .winner-modal {
        h4 {
            &.winner-msg {
                font-size: 1.5rem !important;
            }

            &.betterluck-msg {
                font-size: 1.5rem !important;
            }
        }
    }

    .spin-button {
        &.btn-lg {
            font-size: 1rem;
        }
    }
}

@media screen and (max-height: 500px) {
    .copyright-container {
        display: block;
        position: relative;
        left: 0;
        width: 100%;
        margin-left: 0rem;
    }
}

.iconLove {
    width: 20px !important;
    height: 20px !important;
    position: absolute;
    z-index: 999999999 !important;
}

.top-left {
    top: 0;
    left: 0;
}

.top-right {
    top: 0;
    right: 0;
}

.center-right {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.lower-right {
    bottom: 50%;
    right: 0;
}

.bottom-right {
    bottom: 0;
    right: 0;
}

.bottom-left {
    bottom: 0;
    left: 0;
}

/*----for high resolution screens(50 96 100 144 150 200 250 300 350 400)----*/
//large screen- 96dpi
//dell laptop-120dpi
//on tab- 150 dpi-200
//samsung tab -144 dpi
//samsung s21-250dpi

@media (min-resolution: 144dpi) and (max-resolution: 149dpi) {
    :root {
        //font-size: calc(var(--primary-font-size) + 2px);
        font-size: calc(var(--font-size) + 2px);
    }
    .welcome-txt {
        p {
            font-size: 1.3rem;
            line-height: 1.8rem;
        }
    }
    .updateAvailable {
        .updateFooter {
            text-align: center;
            gap: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;

            button {
                width: 100%;
                margin: 0 !important;
            }
        }
    }
}
@media (min-resolution: 150dpi) and (max-resolution: 200dpi) {
    :root {
        //font-size: var(--primary-font-size);
        font-size: var(--font-size);
    }
    .welcome-txt {
        p {
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }
}
@media (min-resolution: 201dpi) and (max-resolution: 300dpi) {
    :root {
        //font-size: var(--primary-font-size);
        font-size: var(--font-size);
    }
    .welcome-txt {
        p {
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }
}

/*@media (-webkit-min-device-pixel-ratio: 1.5),
(min-resolution: 144dpi) and (max-width:480px) {
    :root {
       // font-size: 14px;
    }
    .welcome-txt{
        p{
            //font-size:22.1rem;
            //line-height: 1.5rem;
        }
    }
}*/

/* Devices with a screen width of 450px and a resolution of 300dpi */
//@media only screen and (min-width: 450px) and (min-resolution: 300dpi) and (max-width: 450px) and (max-resolution: 300dpi) {
/* Your CSS rules for devices with a screen width of 450px and a resolution of 300dpi */
//}
