@mixin box-shadow-primary-custom {
   box-shadow: 0 0 0 0.25rem hsl(from var(--primary-color) h s calc(l + 30)) !important;
  

}
@mixin box-shadow-primary-custom-lg {
  box-shadow: 0 0 0 0.25rem hsl(from var(--primary-color) h s calc(l + 30));


}
@mixin box-shadow-primary-custom-txtBx {
   box-shadow: 0 0 0 0.25rem hsl(from var(--primary-color) h s calc(l + 30));
  

}
@mixin box-shadow-secondary-custom {
   box-shadow: 0 0 0 0.25rem hsl(from var(--secondary-color) h s calc(l + 30)) !important;
  

}


