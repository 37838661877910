@import "../../assets/styles/scss/_variables.scss";
//@import '../../../assets/users/styles/scss/_typography';
//@import '../../../assets/users/styles/scss/_mixins';

/*---------toggle menu-----*/

.ham-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .tg-btn-clps {
       // padding-left: 1rem;
        //margin-right: 1rem;

        .btn {
            padding: 0.6rem 0.2rem;
            font-size: 1.8rem;
            color: var(--primary-color);
            min-width: 0;

            &:hover {
                color: hsl(from var(--primary-color) h s calc(l - 20%));
            }

            &.active {
                box-shadow: none;
            }

            &:focus {
                box-shadow: none;
            }
        }
    }
}
