@import "../../../assets/styles/scss/variables";
@import "../../../assets/styles/scss/mixins";
@import "../../../assets/styles/scss/typography";

.custom-file-upload {
    .chonky-chonkyRoot {
        font-size: var(--font-size) !important;
        font-family: var(--custom-font-family) !important;
        color: var(--font-color) !important;
        position: relative;
        border-radius: 0;

        .chonky-navbarWrapper {
            padding-bottom: 0;

            div[class^="MuiBox-"] {
                padding: 0.5rem 0;
                background: #f5f5fd;
                margin-bottom: 0.8rem;
                border-radius: 0.2rem 0.2rem 0 0;
            }

            .chonky-navbarContainer {
                div[class^="MuiBox-"]button {
                    &:first-child {
                        color: var(--primary-color);
                        font-size: 1.5rem !important;
                        padding: 0 1.5rem;
                        background: #fff;
                        border-radius: 0.1rem;
                        margin-left: 8px;
                    }
                }
            }
        }

        .MuiBox-root-3 {
            nav {
                div[class^="buttonContainer-"] {
                    button {
                        background: no-repeat;
                        font-size: inherit !important;
                        /*  padding: inherit; */
                    }
                }
            }
        }

        .chonky-toolbarWrapper {
            .chonky-toolbarContainer {
                padding-top: 0.5rem;

                .chonky-toolbarLeft {
                    padding-bottom: 16px;
                }

                .chonky-toolbarRight {
                    padding-bottom: 16px;
                    flex-wrap: wrap;
                }
            }
        }

        .MuiTypography-body1 {
            font-family: var(--custom-font-family) !important;
        }

        .MuiButton-label {
            font-family: var(--custom-font-family) !important;
            font-size: 1.1rem;
            font-weight: 500;
            // color: var(--font-color);
        }

        .chonky-searchFieldContainer {
            width: auto;
            height: 30px;
        }

        div[class^="selectionIndicator-"] {
            background: repeating-linear-gradient(
                    45deg,
                    rgb(255 152 0 / 21%),
                    rgb(255 152 0 / 28%) 10px,
                    rgb(255 152 0 / 14%) 0px,
                    rgb(255 152 0 / 12%) 20px
                )
                rgb(255 152 0 / 12%);
        }

        div[class^="focusIndicator-"] {
            box-shadow: #ff990080 0px 0px 0px 2px inset;
        }

        .chonky-activeButton {
            color: var(--primary-color) !important;
        }

        .MuiMenu-list {
            .chonky-activeButton {
                color: var(--primary-color) !important;
            }
        }
    }

    .chonky-fileEntryClickableWrapper {
        div[class^="listFileEntryProperty-"] {
            flex: 0 1 180px;
        }
    }

    .MuiOutlinedInput-root.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
            border-color: var(--primary-color) !important;
            border-width: 2px;
        }
    }

    // hiding chonky file manager's file size placeholder
    div[class^="listFileEntryProperty"] span {
        display: none !important;
    }

    .chonky-gridContainer {
        div[class^="gridFileEntryNameContainer-"] {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    // hiding chonky file manager's default search box
    .MuiFormControl-root.MuiTextField-root.chonky-searchFieldContainer {
        visibility: hidden;
    }

    .custom-breadcrumbs {
        position: absolute;
        top: 68px;
        left: -6px;
    }

    .chonky-infoContainer {
        display: none;
    }
}

// hiding chonky file manager's file size placeholder
/* div[class^="listFileEntryProperty"] span {
    display: none !important;
  } */

/*----custom search---*/
.custom-search-chonky {
    .form-control {
        padding-right: 24px;
        width: 225px;
    }

    .has-search {
        .form-control-feedback {
            pointer-events: all;
        }
    }
}

.chonky-iconWithText svg {
    font-size: larger;
}

.custom-ch-card-header {
    margin: -8px;
}

/*----dropzone---*/
.custom-dropzone {
    display: flex;
    flex: 1;
    height: 100%;
}

.dropzone-inner {
    flex: 1;
}

.doc-viewer-body {
    max-height: 75vh;
    min-height: 75vh;
    overflow: auto;
}

.doc-viewer-body-for-excel-files {
    height: 75vh;
    overflow: auto;
}

.drop-zone-highlight {
    box-shadow: 1px 1px 7px 6px var(--primary-color);
    -webkit-box-shadow: 1px 1px 7px 6px var(--primary-color);
    -moz-box-shadow: 1px 1px 7px 6px var(--primary-color);
}

#header-bar {
    display: none;
}

#proxy-renderer {
    justify-content: center;
}

#no-renderer {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#no-renderer-download {
    padding: 10px;
    margin: 10px;
    width: auto;
    background-color: var(--primary-color);
    color: white;
}

#pdf-download {
    display: none;
}

/*---responsive----*/
@media (max-width: 684.98px) {
    .custom-file-upload {
        .custom-breadcrumbs {
            position: absolute;
            top: 113px;
            left: 0 !important;
            right: auto;
        }
    }
}
