:root {
    /*--dynamic---*/
    /*----primary color---*/
    --primary-color-hue: 36; /* Hue of #fbaa07 */
    --primary-color-saturation: 96%; /* Saturation of #fbaa07 */
    --primary-color-lightness: 52%; /* Lightness of #fbaa07 */
    --lightness-adjustment-1: 5%; /* Amount to adjust the lightness */
    --lightness-adjustment-2: 20%; /* Amount to adjust the lightness */
    --lightness-adjustment-3: 30%; /* Amount to adjust the lightness */
    --lightness-adjustment-4: 40%; /* Amount to adjust the lightness */
    --lightness-adjustment-5: 45%; /* Amount to adjust the lightness */
    --lightness-adjustment-6: 60%; /* Amount to adjust the lightness */
    --lightness-adjustment-7: 70%; /* Amount to adjust the lightness */
    --lightness-adjustment-8: 80%; /* Amount to adjust the lightness */

    /*--secondary color---*/
    --secondary-color-hue: 220; /* Hue of #262930 */
    --secondary-color-saturation: 10%; /* Saturation of #262930 */
    --secondary-color-lightness: 17%; /* Lightness of #262930 */
    --lightness-adjustment-1: 5%; /* Amount to adjust the lightness */
    --lightness-adjustment-2: 20%; /* Amount to adjust the lightness */
    --lightness-adjustment-3: 30%; /* Amount to adjust the lightness */
    --lightness-adjustment-4: 40%; /* Amount to adjust the lightness */
    --lightness-adjustment-5: 45%; /* Amount to adjust the lightness */
    --lightness-adjustment-6: 60%; /* Amount to adjust the lightness */
    --lightness-adjustment-7: 70%; /* Amount to adjust the lightness */
    --lightness-adjustment-8: 80%; /* Amount to adjust the lightness */


    /*---red color--*/
    --red-color-hue: 10; /* Hue of #ff6550 */
    --red-color-saturation: 100%; /* Saturation of #ff6550 */
    --red-color-lightness: 66%; /* Lightness of #ff6550 */
    --lightness-adjustment-red-1: 10%; /* Lightness adjustment */
    --lightness-adjustment-red-3: 30%; /* Lightness adjustment */
    --lightness-adjustment-red-4: 40%; /* Lightness adjustment */
    --lightness-adjustment-red-5: 45%; /* Lightness adjustment */


    color-scheme: light;

    // Colors to update real-time

    --primary-color: #fbaa07;
    --primary-color-rgb: 251, 170, 7;
    --primary-color-1: hsl(from var(--primary-color) h s calc(l + 14));
    --primary-color-2: hsl(from var(--primary-color) h s calc(l + 4));
    --primary-color-3: hsl(from var(--primary-color) h s calc(l - 6));
    --primary-background-color: hsl(from var(--primary-color) h s calc(l + 40));
    // Colors to update real-time

   

    --secondary-color: #262930;
    --font-color: #262930;
    --white-color: #fff;
    --light-red-color: #fff0ee;
    --red-color: #ff6550;
    --red-color-rgb: 255, 101, 80;
    --green-color: #00c40a;
    --bg-color: #f5f5fd;
    --dashboard-green: #3c9b51;
    --dashboard-green-2: #98ab1e;
    --blue-color: #085fe1;
    --blue-color-rgb: 8, 95, 225;
    --light-blue-color: #4c93fd;
    --table-violet: #551ffe;
    --side-menu-icn-color: #555555;
    --reservation-color: #e0ecfe;
    --role-based-view-color: #feefd0;
    --mandatory-day-color: #feefd0;
    --mandatory-day-color-schedule: #feefd096;

    --info-color: #0ea5e9;
    --warning-color: #fbaa07;
    --danger-color: #ff6550;
    --success-color: #00c40a;

    --primary-color-text: #ffffff;
    --text-color: #4b5563;
    --text-color-secondary: #6b7280;

    --info-color-1: #0284c7;
    --info-color-2: #3b82f6;

    --warning-color-1: #f97316;
    --warning-color-2: #ea580c;

    --danger-color-1: #ef4444;
    --danger-color-2: #dc2626;

    --success-color-1: #3c9b51;
    --success-color-2: #98ab1e;

    --border-color: #e5e7eb;
    --background-color: #f9fafb;
    --background-color-secondary: #f3f4f6;
    --background-color-tertiary: #f7f7f7;
    --background-color-quaternary: #f5f5f5;

    --content-padding: 1.25rem;
    --inline-spacing: 0.5rem;
    --border-radius: 6px;
    --focus-ring: 0 0 0 0.2rem #c7d2fe;

    --maskbg: rgba(0, 0, 0, 0.4);
    --highlight-bg: #eef2ff;

    --font-size: 14px;

    // Border radius
    --rd-5px: 5px;
    --rd-10px: 10px;
}
