@keyframes heartbeat {
  0% { transform: scale(1); }
  14% { transform: scale(1.1); }
  28% { transform: scale(1); }
  42% { transform: scale(1.1); }
  70% { transform: scale(1); }
}

.valentines-day-particles {
    z-index: 9999 !important;
    /* animation: heartbeat 2s infinite; */

}

#ts-particles > canvas {
    z-index: 10; /* Adjust z-index as needed */
}
