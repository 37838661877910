@import "../../../assets/styles/scss/variables";
@import "../../../assets/styles/scss/mixins";
@import "../../../assets/styles/scss/typography";

/* PickListDemo.css */
.picklist-demo .product-item .image-container {
    background: #fff;
    margin-right: 1rem;
    padding: 0.2rem;
}
.picklist-demo .p-picklist-item {
    border-bottom: 1px solid #ebebeb !important;
}
.picklist-demo .product-item {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    width: 100%;
}

.picklist-demo .product-item img {
    width: 75px;
    height: 75px;
    box-shadow: none;
    margin-right: none;
    overflow: hidden;
    object-fit: contain;
}

.picklist-demo .product-item .product-list-detail {
    flex: 1 1 0;
}

.picklist-demo .product-item .product-list-detail h5 {
    font-size: 1rem;
}

.picklist-demo .product-item .product-list-action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.picklist-demo .product-item .product-category-icon {
    vertical-align: middle;
    margin-right: 0.5rem;
}

.picklist-demo .product-item .product-category {
    vertical-align: middle;
    line-height: 1;
}
.picklist-demo {
    &.p-picklist {
        .p-picklist-list {
            .p-picklist-item {
                padding: 0.75rem 1rem;
                &.p-highlight {
                    color: var(--primary-color);
                    background: hsl(from var(--primary-color) h s calc(l + 45));
                }
                &:focus {
                    box-shadow: inset 0 0 0 0.15rem hsl(from var(--primary-color) h s calc(l + 40)) !important;
                }
            }
        }
        .p-picklist-buttons {
            .p-button {
                background: var(--primary-color);
                color: var(--white-color);
                border: 1px solid hsl(from var(--primary-color) h s calc(l - 5));

                &:focus {
                    box-shadow:
                        0 0 0 2px #ffffff,
                        0 0 0 4px var(--primary-color),
                        0 1px 2px 0 black;
                }
            }
        }

        .p-picklist-filter {
            .p-inputtext {
                &:enabled {
                    &:hover {
                        border-color: var(--primary-color);
                    }

                    &:focus {
                        box-shadow: 0 0 0 0.2rem hsl(from var(--primary-color) h s calc(l + 40));
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 967px) and (min-width: 769.98px) {
    .picklist-demo .product-item {
        flex-wrap: wrap;
    }

    .picklist-demo .product-item .image-container {
        width: 100%;
        text-align: center;
    }

    .picklist-demo .product-item img {
        margin: 0 0 1rem 0;
        width: 100px;
    }
}

.new-btn-height {
    height: 96px;
}
.white-bg {
    background-color: var(--white-color) !important;
}
.custom-accordion {
    .p-accordion-header {
        a {
            font-size: 1.2rem !important;
            font-weight: 500 !important;
            font-family: $custom-font-family;
            color: var(--font-color);
        }
    }
}
@media screen and (min-width: 769.98px) {
    .p-picklist-buttons {
        &.p-picklist-source-controls {
            // display: none;
        }
    }
    .p-picklist-buttons {
        &.p-picklist-target-controls {
            //display: none;
        }
    }
}

@media screen and (max-width: 576px) {
    .picklist-demo .product-item {
        flex-wrap: wrap;
    }

    .picklist-demo .product-item .image-container {
        width: 100%;
        text-align: center;
    }

    .picklist-demo .product-item img {
        margin: 0 0 1rem 0;
        width: 100px;
    }
}

.new-btn-height {
    height: 96px;
}
.white-bg {
    background-color: var(--white-color) !important;
}
.custom-accordion {
    .p-accordion-header {
        a {
            font-size: 1.2rem !important;
            font-weight: 500 !important;
            font-family: $custom-font-family;
            color: var(--font-color);
        }
    }
}
