//@import url('https://fonts.googleapis.com/css2?family=Water+Brush&display=swap');

:root {
  font-size: var(--font-size);
}

html {
  scroll-behavior: smooth;
  scroll-snap-type: y proximity;
  scroll-padding-top: 50px;
}

body {
  background: var(--white-color);
  height: 100vh;
  font-size: var(--font-size);
  font-family: var(--custom-font-family);
  position: relative;
  font-weight: normal;
  color: var(--font-color);
  word-wrap: break-word !important;
  word-break: break-word !important;
  line-height: 1.5rem;

  &.user-style {
    background: url(../../images/bg_new_landscape.jpg) no-repeat center center
      fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    font-size: var(--font-size);
    font-family: var(--custom-font-family);
    position: relative;
    font-weight: normal;
    height: 100%;
    overflow: auto;
  }
}

dl,
ol,
ul {
  margin-bottom: 0rem;
  margin-top: 0;
}

ol,
ul {
  padding-left: 0;
}

.btn {
  &:hover {
    transition: all 0.5s ease;

    i {
      //right: 2px;
      position: relative;
      transition: all 0.5s ease;
    }
  }
}

.filter-btn {
  &:hover {
    i {
      right: 0px;
    }
  }
}

.color-primary-custom {
  color: var(--primary-color) !important;
}

.text-secondary {
  color: hsl(from var(--font-color) h s calc(l + 25)) !important;
}

.color-secondary-custom {
  color: var(--secondary-color);
}

.dropdown-item {
  color: var(--font-color);
}

.loader-outer {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loader-inner {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.fw-semibold {
  font-weight: 500;
}

a {
  color: var(--font-color);
  text-decoration: none;

  &:hover {
    color: var(--primary-color);
  }
}

.mb-7 {
  margin-bottom: 5rem !important;
}

.custom-bg {
  background-color: var(--background-color-tertiary);
}

.modal-header-bg {
  background-color: white !important;
}

.disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.custom-shadow {
  box-shadow: 0px 0px 17px 0px rgb(154 161 171 / 18%);
}

.border-radius-05 {
  border-radius: 0.5rem !important;
}

.border-radius-card-header-05 {
  border-radius: 0.5rem 0.5rem 0 0 !important;
}

.table-help-main {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: hsl(from var(--font-color) h s calc(l + 40));
  font-weight: 500;

  &:hover {
    color: hsl(from var(--font-color) h s calc(l + 30));
    cursor: pointer;
  }
}

.help-main {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: hsl(from var(--font-color) h s calc(l + 50));
  font-weight: 500;

  &:hover {
    color: hsl(from var(--font-color) h s calc(l + 40));
    cursor: pointer;
  }
}

.help-small {
  display: flex;
  justify-content: space-between;
  align-items: end;

  .help-main {
    font-size: 1.3rem;
  }
}

/*---login releted styles----*/
.login {
  min-height: 100vh;

  .login-box {
    background: rgba(254, 254, 254, 0.6);
    border-radius: 10px;

    .form-control:focus {
      @include box-shadow-primary-custom-lg;
      border: 1px solid var(--primary-color);
      // border: none;
    }

    .login-header {
      background: var(--white-color);
      border-radius: 10px 10px 0px 0px;
      padding: 1rem 1rem;
      position: relative;
    }

    .login-body {
      padding: 2.125rem 2.125rem 1.125rem 2.125rem;

      .form-control-lg {
        font-size: var(--font-size);
        min-height: calc(1.5em + 2rem + 2px);
      }

      ::-webkit-input-placeholder {
        /* WebKit browsers */
        color: hsl(from var(--secondary-color) h s calc(l + 20));
      }

      :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: hsl(from var(--secondary-color) h s calc(l + 20));
      }

      ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: hsl(from var(--secondary-color) h s calc(l + 20));
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: hsl(from var(--secondary-color) h s calc(l + 20));
      }

      a {
        color: var(--font-color);
        text-decoration: none;

        &:hover {
          color: hsl(from var(--primary-color) h s calc(l - 30));
        }
      }
    }

    .copyright-login {
      font-size: 0.8rem;
      font-weight: 500;
    }

    .error-msg {
      //color: var(--green-color);
      color: var(--danger-color);
      font-size: 0.9rem;
      font-weight: 500;
      display: flex;
      align-items: center;

      i {
        margin-right: 0.2rem;
      }
    }
  }

  h4 {
    color: var(--font-color);
    font-size: var(--normal-title-sz);
    font-weight: 600;
  }
}

.min-H-inner-new {
  height: calc(100% - 55px);
}

/*------- user menu navigation---------*/
.scroll-menu-container {
  .nav-scroller {
    background: transparent;
  }

  .nav-scroller-nav {
    position: relative;
    width: 100%;

    .nav-scroller-item {
      position: relative;
      display: flex;
      justify-content: center;
      padding: 0;
      margin: 0 0.2rem;
      border-left: none;
      border-right: none;
      width: 180px !important;
      //height: 120px;
      height: 125px;
      overflow: hidden;
      border-radius: 5px;
      border: 3px solid #ddd;
      transition: all 0.5s;
      align-items: center;
      background: var(--white-color);

      &:hover {
        border: 3px solid var(--primary-color);
        cursor: pointer;
        transition: all 0.5s;

        .menu-title {
          color: var(--primary-color);
          //bottom: 2.5rem;
          transition: all 0.5s;
        }
      }

      &.active {
        border: 3px solid var(--primary-color);

        .menu-title {
          color: var(--primary-color);
          //bottom: 2.5rem;
        }
      }

      img {
        //border-radius: 10px;
        //width: 100%;
        //height: 100%;
        //border-radius: 2px;
        width: auto;
        height: 100%;
        border-radius: 2px;
        display: inline-block;
      }

      .menu-title {
        position: absolute;
        z-index: 1;
        //bottom: 0.5rem;
        bottom: 0;
        text-align: center;
        color: var(--white-color);
        padding: 0 5px;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        background: #575757;
        border-radius: 0 0 2px 2px;
      }

      .overlay {
        display: block;
        width: 100%;
        height: 100%;
        //background: #00000099;
        position: absolute;
        border-radius: 0px;
      }

      &:hover {
        .menu-title {
          color: var(--primary-color);
        }
      }
    }
  }

  .main-menu {
    .slick-slide {
      > div {
        display: flex;
      }
    }
  }
}

/*------side menu (admin)-----*/
.logo-sec {
  display: block;

  img {
    max-width: 80%;
    text-align: center;
  }
}

.logo-sec-left {
  /* display: flex;
  height: 100%;
  align-items: center;*/
  position: relative;
  display: flex;

  img {
    //max-width: 60%;
    height: 40px;
    text-align: center;
    //height: fit-content;
  }
}

.mobile-logo {
  img {
    height: 50px;
  }

  .animation-container-cincodemayo-logo {
    img {
      right: -8px;
    }
  }
}

.login-user-menu-item {
  font-weight: 500;
  color: var(--primary-color);
  background-color: hsl(from var(--primary-color) h s calc(l + 45));
  cursor: default !important;

  i {
    font-weight: 500;
  }

  &:hover {
    background-color: hsl(
      from var(--primary-color) h s calc(l + 45)
    ) !important;
  }
}

.sb-sidenav-light {
  background-color: var(--white-color);
  color: var(--font-color);
}

.sb-sidenav-light {
  background-color: var(--white-color);
}

#layoutSidenav_nav {
  background-color: var(--white-color);
}

.sb-topnav {
  &.bg-light {
    background-color: var(--white-color) !important;
    // box-shadow: 0 0rem 1rem rgba(0, 0, 0, 0.15) !important;
    //border-bottom: 1px solid var(--secondary-color);
    //--adjusted-lightness-10: calc(var(--primary-color-lightness) + var(--lightness-adjustment-2));
    //border-bottom: 1px solid hsl(var(--primary-color-hue), var(--primary-color-saturation), var(--adjusted-lightness-10));
    --adjusted-lightness: calc(
      var(--secondary-color-lightness) + var(--lightness-adjustment-7)
    );
    border-bottom: 1px solid
      hsl(
        var(--secondary-color-hue),
        var(--secondary-color-saturation),
        var(--adjusted-lightness)
      );
  }
}

.sb-sidenav-menu {
  .nav-link {
    padding: 0;
  }
}

.fixed-top,
#layoutSidenav #layoutSidenav_nav,
.sb-topnav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  // padding-left: 1rem;
}

#layoutSidenav_nav {
  padding-right: 0.5rem;
}

#layoutSidenav {
  display: flex;
  padding: 0;
  height: 100vh;
  background: var(--bg-color) !important;
}

#layoutSidenav #layoutSidenav_nav {
  flex-basis: 295px;
  flex-shrink: 0;
  transition: transform 0.15s ease-in-out;
  z-index: 1038;
  //transform: translateX(-295px);
  width: 295px;
  position: absolute;
  // box-shadow: 2px 0 5px -2px rgba(0, 0, 0, 0.15);
  //box-shadow: 0 0rem 0.5rem rgba(0, 0, 0, 0.1) !important;

  --adjusted-lightness: calc(
    var(--secondary-color-lightness) + var(--lightness-adjustment-7)
  );
  border-right: 1px solid
    hsl(
      var(--secondary-color-hue),
      var(--secondary-color-saturation),
      var(--adjusted-lightness)
    );
}

#layoutSidenav #layoutSidenav_content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;
  flex-grow: 1;
  min-height: calc(100vh - 60px);
  margin-left: -295px;
}

.sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
  transform: translateX(0);
  width: 295px;
}

.menu-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*.sb-sidenav-toggled #layoutSidenav #layoutSidenav_content:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 1037;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}*/

@media (min-width: 1180.98px) {
  #layoutSidenav #layoutSidenav_nav {
    transform: translateX(0);
  }

  #layoutSidenav #layoutSidenav_content {
    margin-left: 0;
    transition: margin 0.15s ease-in-out;
  }

  .sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
    // transform: translateX(-295px);
    // width: 80px;
    // width: 70px;
    width: 77px;
    padding-right: 0;
    transition: all;
    transition: all 0.5s ease;

    .copyright-container {
      .copyright {
        display: none;
      }

      .copyright-collapse {
        display: block !important;
        padding-left: 0;
      }
    }

    .menu-text {
      display: none;
      transition: all;
    }

    .sb-sidenav {
      .sb-sidenav-menu {
        .nav {
          padding-left: 0;
          align-items: center;
          margin-left: 5px;

          .nav-link {
            transition: all;
            width: 100%;
            //align-items: center;
            //justify-content: center;
            padding-left: 20px;
            border-radius: 5px;

            .sb-nav-link-icon {
              margin-right: 5px;
              transition: all;
            }
          }

          .sb-sidenav-menu-nested {
            display: none;
            transition: all;
          }
        }
      }
    }
  }

  .sb-sidenav-toggled #layoutSidenav #layoutSidenav_content {
    margin-left: -295px;
  }

  .sb-sidenav-toggled #layoutSidenav #layoutSidenav_content:before {
    display: none;
  }
}

.sb-topnav {
  //left:295px;
  left: 0;
  right: 0;
  //position: relative;
  position: fixed;
  padding-left: 5px;
}

#layoutSidenav #layoutSidenav_nav {
  // width: 295px;
  height: 100vh;
  padding-top: 60px;
  z-index: 1036;
  transition: all 0.5s ease;
}

#layoutSidenav #layoutSidenav_nav .sb-sidenav {
  padding-top: 0.5rem;
}

#layoutSidenav #layoutSidenav_nav .sb-sidenav .sb-sidenav-menu {
  overflow-y: auto;
  //scrollbar-color: #ccc;
  //scrollbar-width: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    //background-color: #ccc;
    width: 15px;
    border-radius: 10px;
    --adjusted-lightness-11: calc(
      var(--primary-color-lightness) + var(--lightness-adjustment-4)
    );
    /* Apply the dynamically adjusted HSL color */
    background-color: hsl(
      var(--primary-color-hue),
      var(--primary-color-saturation),
      var(--adjusted-lightness-11)
    );
  }
}

#layoutSidenav #layoutSidenav_content {
  margin-left: 295px;
  overflow: hidden;
  min-height: 100vh;
  //margin-left: 70px;
}

.sb-topnav {
  padding-left: 5px;
  height: 60px;
  z-index: 1037;
}

.sb-topnav .navbar-brand {
  width: 295px;
  margin: 0;
}

.sb-topnav.navbar-light #sidebarToggle {
  color: var(--primary-color);
}

.sb-sidenav {
  display: flex;
  flex-direction: column;
  //height: calc(100% - 70px);
  height: 100%;
  flex-wrap: nowrap;
}

.sb-sidenav .sb-sidenav-menu {
  flex-grow: 1;
}

.sb-sidenav .sb-sidenav-menu .nav {
  flex-direction: column;
  flex-wrap: nowrap;
  padding-left: 5px;
  margin-right: 5px;
}

.sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-heading {
  padding: 1.75rem 1rem 0.75rem;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
}

.sb-sidenav .sb-sidenav-menu .nav .nav-link {
  display: flex;
  align-items: center;
  //padding: 0.5rem;
  padding: 0.8rem;
  position: relative;
  font-size: var(--button-font-sz);
  margin-bottom: 5px;
  border-radius: var(--rd-5px);
  white-space: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &[aria-expanded="true"] {
    .sb-sidenav-collapse-arrow {
      transform: translateX(-5px) rotate(0deg) !important;
      transition: all 0.3s ease !important;
    }
  }
}

.sb-sidenav {
  .sb-sidenav-menu-nested {
    .nav-link {
      padding: 0.6rem !important;
    }
  }
}

.sb-sidenav .sb-sidenav-menu .nav .nav-link .sb-nav-link-icon {
  font-size: var(--sidebar-icn-sz);
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sb-sidenav .sb-sidenav-menu .nav .nav-link .sb-sidenav-collapse-arrow {
  display: inline-block;
  margin-left: auto;
  transition: transform 0.15s ease;
}

.sb-sidenav
  .sb-sidenav-menu
  .nav
  .nav-link.collapsed
  .sb-sidenav-collapse-arrow {
  transform: rotate(-90deg);
}

.sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-nested {
  //margin-left: 1.5rem;
  flex-direction: column;
  padding-left: 1.5rem;
  background: #ccc;
  background: hsl(from var(--primary-color) h s calc(l + 46));
  //border-left-style: solid;
  //border-left-width: thin;
  //border-left-color: hsl(from var(--secondary-color) h s calc(l + 60));

  .nav-link {
    &:hover {
      color: var(--primary-color);
      background-color: transparent;
    }

    &.active {
      color: var(--primary-color);
      background-color: transparent;
      //font-weight: 500;

      .sb-nav-link-icon {
        color: var(--primary-color);
        background-color: transparent;
      }
    }
  }

  .sb-nav-link-icon {
    font-size: 1.1rem;
  }
}

.sb-sidenav .sb-sidenav-footer {
  padding: 0.75rem;
  flex-shrink: 0;
}

.sb-sidenav-light {
  background-color: var(--white-color);
  color: var(--font-color);
}

.sb-sidenav-light .sb-sidenav-menu .sb-sidenav-menu-heading {
  color: #adb5bd;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link {
  //color: var(--side-menu-icn-color);
  color: var(--font-color);
  font-weight: normal;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link .sb-nav-link-icon {
  color: var(--font-color);
}

.sb-sidenav-light .sb-sidenav-menu .nav-link .sb-sidenav-collapse-arrow {
  color: hsl(from var(--secondary-color) h s calc(l + 40));
}

.sb-sidenav-light .sb-sidenav-menu .nav-link:hover {
  color: var(--primary-color);
  background-color: hsl(from var(--primary-color) h s calc(l + 40));
  cursor: default;

  .sb-nav-link-icon {
    color: var(--primary-color);
  }

  .sb-sidenav-collapse-arrow {
    color: var(--primary-color);
  }
}

.sb-sidenav-light .sb-sidenav-menu a.nav-link:hover {
  cursor: pointer;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link.active {
  color: var(--primary-color);
  background-color: hsl(from var(--primary-color) h s calc(l + 42));

  .sb-nav-link-icon {
    color: var(--primary-color);
  }

  .sb-sidenav-collapse-arrow {
    color: var(--primary-color);
  }

  &[aria-expanded="true"] {
    .sb-sidenav-collapse-arrow {
      transform: translateX(-5px) rotate(0deg) !important;
      transition: all 0.3s ease !important;
    }
  }
}

.sb-sidenav-light .sb-sidenav-menu .nav-link.active .sb-nav-link-icon {
  color: var(--primary-color);
  background-color: hsl(from var(--primary-color) h s calc(l + 40));

  .sb-nav-link-icon {
    color: var(--primary-color);
  }
}

.sb-sidenav-light .sb-sidenav-footer {
  background-color: #e9ecef;
}

.sb-sidenav-toggled {
  .sb-topnav {
    // width: 100% !important;
    //left: 80px;
    left: 0px;
  }

  #layoutSidenav {
    #layoutSidenav_content {
      // margin-left: 80px;
      margin-left: 77px;
    }
  }

  .sb-sidenav-menu {
    .sb-nav-link-icon {
      // margin-left: 4px;
      margin-left: 0px;
    }
  }
}

@media (max-width: 1180.98px) {
  #layoutSidenav {
    #layoutSidenav_content {
      //margin-left: 80px;
      margin-left: 70px;
      transition: margin 0.15s ease-in-out;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .sb-topnav {
      left: 0px;
    }

    #layoutSidenav_nav {
      width: 70px;
      transition: all;
    }
  }

  .sb-nav-fixed {
    #layoutSidenav {
      #layoutSidenav_content {
        margin-left: 80px;
        overflow-y: hidden;
        min-height: 100vh;
      }
    }
  }

  .sb-sidenav-toggled {
    #layoutSidenav {
      #layoutSidenav_content {
        // margin-left: 295px;
        // margin-left: 70px;
        margin-left: 0px;
      }

      .sb-topnav {
        //width: 100% !important;
        //left: 295px;
        left: 0px;
      }
    }
  }

  .sb-sidenav-menu {
    .sb-nav-link-icon {
      // margin-left: 4px;
      margin-left: 0px;
    }
  }
}

/*-----dashboard-content (admin)-----*/
.content-sec {
  position: relative;
  width: 100%;
  /*min-height: calc(100vh - 56px);*/
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 60px;

  .page-title {
    h5 {
      font-size: var(--normal-title-sz);
      color: var(--font-color);
      font-weight: 600;
      margin-bottom: 1rem;
    }
  }
}

/*----loader---*/
.custom-loader {
  .modal-content {
    background: transparent;
    align-items: center;
    padding: 1rem 0;
    border: none;
    color: var(--primary-color);

    .spinner-border {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}

/*-----accordian------*/

/*.accordion-button-custom {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: var(--white-color);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
}*/
.accordion-button {
  color: var(--font-color);
  background-color: var(--bg-color);
  font-weight: 600;
}

.accordion-button:not(.collapsed) {
  color: var(--font-color);
  background-color: var(--bg-color);
  font-weight: 600;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' %3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
  fill: var(--primary-color);
}

/*----accordian header arrow----*/
.accordian-custom-arrow {
  .accordion-button::after {
    background-image: none;
  }
}

/*---toast-----*/

.custom-toast {
  top: 3.5rem !important;
  z-index: 9999;
  //position: absolute;
  position: fixed;
  display: inline-block;
  width: 100%;
  right: 0;
}

/*-------button---*/
.btn {
  min-width: 70px;
}

.survey-floating-btn {
  position: fixed;
  right: 3px;
  width: 52px;
  height: 52px;
  z-index: 9;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  font-size: 2rem;
  color: var(--white-color);
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  opacity: 0;
  top: 47%;
  transition: all 0.9s;

  &.active {
    opacity: 1;
    top: 50%;
  }
}

/*-----model------*/
.modal-header {
  background: var(--background-color-quaternary);
}

h5 {
  &.modal-title {
    font-size: 1rem;
    color: var(--font-color);
    font-weight: 600;
  }
}

/*----------*/

.admin-ic {
  .rounded-circle {
    border: 1px solid var(--secondary-color);
  }
}

/*---upload---*/
.upload-c {
  input[type="file"] {
    display: none;
  }

  .custom-file-upload {
    display: inline-block;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed !important;
      background: var(--background-color-quaternary);
      border: 1px solid #ccc;
      color: #a7a7a7;
    }
  }
}

/*---search-box--*/
.has-search {
  position: relative;

  .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
    right: 0.5rem;
  }
}

/*------card-----*/
.card-title-custom {
  h5 {
    //font-size: var(--button-font-lg);
    font-size: 1rem;
    color: var(--font-color);
    font-weight: 600;
  }
}

/*-------form control----*/
.form-control:focus,
.form-select:focus {
  @include box-shadow-primary-custom;
  border-color: var(--primary-color);
  color: var(--font-color);
}

/*-------logout-----*/
.logout-container {
  .btn {
    &:focus {
      box-shadow: none;
      color: inherit;
    }

    img {
      height: 40px !important;
    }
  }

  .dropdown-menu {
    padding: 0 0 0 0;

    a {
      &.menu-item {
        &:hover {
          color: var(--primary-color) !important;
        }
      }

      &.dropdown-item {
        &:hover {
          background-color: transparent;
          color: var(--green-color);
        }
      }
    }

    .dropdown-item {
      display: flex;

      i {
        margin-right: 0.5rem;
      }
    }
  }

  .dropdown-logout {
    color: var(--red-color);
    border-top: 1px solid #dddddd;
    font-weight: 500;

    &:hover {
      color: hsl(from var(--red-color) h s calc(l - 30)) !important;
    }
  }
}

.dropdown-login {
  border-top: 1px solid #dddddd;
  font-weight: 500;
  //color: var(--green-color);
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*---------browser scroll-----*/
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--background-color-quaternary);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  //background: rgb(158, 158, 158);
  // background: var(--primary-color);
  border-radius: 10px;
  --adjusted-lightness-9: calc(
    var(--primary-color-lightness) + var(--lightness-adjustment-3)
  );
  /* Apply the dynamically adjusted HSL color */
  background-color: hsl(
    var(--primary-color-hue),
    var(--primary-color-saturation),
    var(--adjusted-lightness-9)
  );
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  //background: rgb(109, 109, 109);
  --adjusted-lightness-10: calc(
    var(--primary-color-lightness) + var(--lightness-adjustment-2)
  );
  background-color: hsl(
    var(--primary-color-hue),
    var(--primary-color-saturation),
    var(--adjusted-lightness-10)
  );
}

.admin-ic {
  img {
    height: 35px;
    width: 35px;
  }
}

.min-H-new {
  height: 100vh;
  overflow: auto;
}

.manage-user-sticky {
  position: sticky;
  top: 0;
  z-index: 9;
}

/*-------form-control-----*/
.form-control {
  &.is-invalid-custom {
    background-image: none;
    border-color: var(--danger-color);

    &:focus {
      box-shadow: 0 0 0 0.25rem var(--green-color);
    }
  }
}

.form-select {
  &.is-invalid-custom {
    background-image: none;
    border-color: var(--danger-color);

    &:focus {
      box-shadow: 0 0 0 0.25rem var(--green-color);
    }

    .invalid-feedback {
      display: block;
    }
  }
}

.invalid-feedback {
  .is-invalid-custom ~ & {
    display: block;
  }
}

/*-----------radio button-----*/
.form-check-input:focus {
  //border-color: #86b7fe;
  outline: 0;
  @include box-shadow-primary-custom-txtBx;
}

.form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

/*-------multiselect in data grid-----*/
.p-multiselect {
  &.is-invalid-custom {
    border-color: var(--danger-color);
  }
}

.multiselect-demo .p-multiselect {
  min-width: 15rem;
}

.multiselect-demo
  .multiselect-custom
  .p-multiselect-label:not(.p-placeholder):not(.p-multiselect-items-label) {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.multiselect-demo .multiselect-custom .country-item-value {
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: 0.5rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}

.multiselect-demo .multiselect-custom .country-item-value img.flag {
  width: 17px;
}

.p-multiselect {
  width: 100%;

  .p-multiselect-label {
    //padding: 0.375rem 0.75rem !important;
    padding: 0.444rem 0.75rem !important;
    /* padding: 0.375rem 0.75rem !important; */
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }
}

.p-multiselect-header {
  display: none !important;
}

.custom-multiselect {
  .p-multiselect-header {
    display: flex !important;
  }
}

.p-multiselect {
  &:hover {
    border-color: #000;
  }

  &.p-component {
    &.p-inputwrapper {
      &:hover {
        border: 1px solid var(--primary-color);
      }
    }
  }

  &:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 0.2rem hsl(from var(--primary-color) h s calc(l + 40)) !important;
    border-color: var(--primary-color);
  }
}

/*---prime react checkbox---*/
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: var(--primary-color);
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--primary-color);
  background: var(--primary-color);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: var(--primary-color);
  background: hsl(from var(--primary-color) h s calc(l + 45));
}

/*----p-dropdown---*/
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.2rem hsl(from var(--primary-color) h s calc(l + 40)) !important;
  border-color: var(--primary-color);
}

.p-dropdown:not(.p-disabled):hover {
  border-color: var(--primary-color);
}

.p-datatable {
  .p-datatable-tbody {
    tr {
      &.p-highlight {
        background: #eef2ff;
        color: var(--font-color) !important;
      }
    }
  }
}

/**********/
.save-btn-section {
  width: 100%;
  background-color: hsl(from var(--primary-color) h s calc(l + 40));
  padding: 1rem;
  position: sticky;
  bottom: 0px;
  //bottom: 3px;
  text-align: right;
  z-index: 9;

  .btn {
    margin-left: 5px;
  }
}

.crop-image {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: max-content !important;
}

.img-thumb-main {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  //border: 1px solid #ebebeb;
  //border-radius: 5px;

  img {
    //border-radius: 5px 5px 0 0;
    height: 100%;
  }

  .view-img-preview {
    //flex: 1 1;
    display: flex;

    width: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 5px 5px 0 0;
    border: 1px solid #ebebeb;
    // min-width: 180px;
    min-width: 177px;
    min-height: 120px;
    height: 120px;
    overflow: hidden;
    background-color: #ccc;

    &.welcome-img-box {
      min-width: 177px;
      min-height: 220px;
      height: 220px;
    }
  }
}

.preview-tool {
  display: flex;
  width: 100%;
  background: #ebebeb;
  flex: 0 1;
  align-items: flex-end;
  justify-content: center;
  border: 1px solid #ebebeb;
  border-radius: 0 0 5px 5px;
  line-height: 2rem;
}

.no-image-avathar {
  pointer-events: none;

  .delete {
    color: #c6c3c2;
  }
}

.video-thumb-main {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    border-radius: 5px;
  }

  .video-preview {
    transition: all 1s;
    flex: 1;
    display: flex;
    background-color: #ccc;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
  }
}

.history-img-container {
  display: flex;
  flex-wrap: wrap;
}

.history-img-view {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #ebebeb;
  //min-height: 140px;
  //height: 140px;
  width: 270px;
  height: 180px;
  overflow: hidden;
  background: var(--background-color-quaternary);
  padding: 3px;

  img {
    height: 100%;
    border-radius: 5px;
  }
}

.save-btn-absolute {
  position: absolute;
  left: 0;
}

.save-btn-fixed {
  position: fixed;
  left: 0;
  bottom: 0;
}

.btn-transparent {
  border: none;
  background: transparent;
  padding: 0;

  &:hover {
    color: var(--primary-color);
  }
}

.btn-additional-info {
  background-color: hsl(from var(--white-color) h s calc(l - 5)) !important;
  border-radius: 3px !important;
  padding: 5px 1rem !important;
}
.additional-info-link {
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: 500;
  color: var(--blue-color);
  border-bottom: 1px solid var(--blue-color);

  i {
    margin-right: 5px;
  }

  &:hover {
    cursor: pointer;
    //color: var(--font-color);
    color: hsl(from var(--blue-color) h s calc(l - 10)); //for dark background
  }
}

.img-flex {
  display: flex;
  flex-wrap: wrap;
}

.img-thumb-main {
  //margin-right: 0.5rem;
}

.modal-custom-title {
  &.h4 {
    font-size: 1.25rem;
  }
}

.img-thumb-main-small {
  transition: all 0.3s;

  img {
    //width: 60px;
    //height: 40px;
    border-radius: 3px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    //margin-right: 5px;
  }
}

.admin-ic {
  transition: all 1s;

  .delete {
    display: none;
    position: absolute;
    /* left: 50%; */
    /* top: 50%; */
    background: var(--white-color);
    border-radius: 50%;
    height: 30px;
    width: 30px;
    align-items: center;
    justify-content: center;
    transition: all 1s;
    font-size: 1rem;
  }

  .delete-dp {
    color: var(--green-color);
    font-size: 1.5rem;

    &:hover {
      cursor: pointer;
      color: hsl(from var(--red-color) h s calc(l - 8));
    }
  }

  &:hover {
    transition: all 1s;

    .delete {
      display: flex;
    }
  }
}

/*-----------guest---------*/

#GuestMain {
  .bg {
    background-color: var(--bg-color);
  }

  .headerBar {
    position: relative;
    display: flex;
    //padding: 1rem 0.5rem;
    padding: 0.242rem 0 0.242rem 0.75rem;
    flex-wrap: nowrap;
    justify-content: space-between;
    z-index: 1037;
    background-color: var(--white-color);
    left: 0;
    right: 0;
  }

  .subnavbar {
    position: relative;
    background-color: var(--primary-color);
    position: sticky;
    //top: -1px;
    z-index: 9;
    //padding: 1rem;

    .nav-custom-bg {
      background-color: var(--primary-color) !important;
      //padding-top: 0.3rem;
      //padding-bottom: 0.3rem;

      .navbar-nav {
        .nav-link {
          color: var(--font-color);
          font-size: 14px;
          font-weight: 600;
          padding-right: 0.7rem;
          padding-left: 0.7rem;
          position: relative;

          &:nth-last-child(1) {
            padding-right: 0;
          }

          &:hover {
            color: var(--white-color);

            &::after {
              content: "";
              position: absolute;
              margin: 0 auto;
              left: 0;
              right: 0;
              width: 50%;
              bottom: -4px;
              height: 4px;
              background-color: var(--white-color);
            }
          }

          &.active {
            color: var(--white-color);

            &::after {
              color: var(--white-color);
              content: "";
              position: absolute;
              margin: 0 auto;
              left: 0;
              right: 0;
              width: 50%;
              bottom: -4px;
              height: 4px;
              background-color: var(--white-color);
            }
          }
        }
      }
    }
  }

  .header-sticky {
    top: -1px;
  }

  .category-additional-info {
    color: hsl(from var(--secondary-color) h s calc(l + 40));
    font-size: var(--font-size);
    line-height: calc(var(--font-size) * 1.5);
  }

  h3 {
    font-size: var(--normal-title-sz);
    font-weight: 600;
    text-align: center;

    &.category-title-main {
      position: relative;
      display: inline-block;
      color: var(--primary-color);
      margin-bottom: 0.5rem;
      padding: 0.5rem 1rem;
      box-shadow: 0 0px 10px hsl(from var(--primary-color) h s calc(l + 40));
      border-radius: 30px;

      &::after {
        content: "";
        position: absolute;
        //left: 0;
        width: 30%;
        bottom: -0.8rem;
        // height: 4px;
        background-color: var(--font-color);
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }

  .item-list-main {
    background-color: var(--white-color);
    border-radius: 5px;
    display: flex;
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    word-break: break-all;
    align-items: center;
    height: 100%;
    transition: all 0.2s;

    &:hover {
      background-color: var(--background-color-tertiary);
      box-shadow: 0 0px 10px #0000001f;
      cursor: pointer;

      .item-arrow {
        i {
          color: var(--font-color) !important;
          cursor: pointer;
          animation: moveToRight 0.3s ease-in-out;
          animation-delay: 0.3s;
          animation-fill-mode: both;
        }

        @keyframes moveToRight {
          0% {
            //transform: translateX(0px);
            opacity: 0;
            right: 15px;
          }

          100% {
            //transform: translateX(300px);
            opacity: 1;
            right: -10px;
          }
        }
      }
    }

    .item-list-image {
      border-radius: 5px;
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      justify-content: center;
      background: #e3e3e3;
      overflow: hidden;
      aspect-ratio: 1 / 1;
      // width: 50%;
      //aspect-ratio: 16/9;
    }

    .item-list-content {
      display: flex;
      flex-direction: column;
      flex: 1;

      .title {
        font-weight: 600;
        //line-height: calc((var(--normal-title-sz)) * 1.5);
        font-size: var(--normal-title-sz);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .price-ft {
        font-weight: 600;
        font-size: 1.2rem;
        color: hsl(from var(--secondary-color) h s calc(l + 20));

        span {
          color: hsl(from var(--secondary-color) h s calc(l + 20));
          font-size: 1rem;
          font-weight: 400;
          //word-break: break-word;
          //display: -webkit-box;
          // -webkit-box-orient: vertical;
          //-webkit-line-clamp: 1;
          // overflow: hidden;
        }
      }

      .price-scnd {
        font-weight: 500;
        font-size: var(--button-font-sz);

        span {
          color: hsl(from var(--secondary-color) h s calc(l + 50));
          font-size: 1rem;
          font-weight: 400;
        }
      }

      .not-available-menu {
        font-size: 1rem;
        color: var(--red-color);
      }

      .item-arrow {
        // position: absolute;
        // right: -0.5rem;
        // bottom: -1.5rem;
        i {
          font-size: 2.5rem;
          color: var(--primary-color);
          transition: all 0.3s;
          position: absolute;
          bottom: 5px;
          right: 15px;
          opacity: 0;

          &:hover {
            //color: var(--font-color);
            //cursor: pointer;
          }
        }
      }
    }

    &.item-recommendation {
      .title {
        font-size: 1rem;
      }
    }
  }

  .adbox-container {
    background-image: url(../../images/free_dinner_bg.png);
    //background-image: url(../../images/free_dinner-main.png);
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: center;
    background-size: cover;
    // background-attachment: fixed;
    position: relative;
    border-radius: 5px;
    //padding: 1rem 0;

    .overlay {
      display: block;
      width: 100%;
      height: 100%;
      background: #000000c7;
      position: absolute;
      border-radius: 5px;
      top: 0;
      bottom: 0;
      left: 0;
    }

    /*  .ad_img_container{
        background-image: url(../../images/free_dinner-main.png);
        background-repeat: no-repeat;
      background-position-x: 50%;
      background-position-y:center ;
      background-size: cover;
      } */
    h3 {
      //font-family: 'Water Brush', cursive;
      font-size: 3rem;
      color: var(--primary-color);
      font-weight: 400;
    }

    h4 {
      color: var(--green-color);
      font-size: 2rem;
      font-weight: 500;
    }

    .txt-section {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        margin-left: 5px;
      }
    }

    .flex-grow-1 {
      position: relative;
      z-index: 1;
      align-items: center;
      display: flex !important;
      padding-left: 2rem;
    }

    .flex-grow-0 {
      position: relative;
      z-index: 1;
    }

    .survey-header {
      h6 {
        color: var(--white-color);
        font-size: 1.5rem;
        font-weight: 400;
      }

      h5 {
        color: var(--primary-color);
        font-size: 2rem;
        font-weight: 500;
      }
    }
  }

  /*---item view----*/
  .item-list-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    img {
      //width: auto;
      //height: 100%;
      width: auto;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      //border-radius: 5px;
    }
  }

  .item-list-image-large {
    display: flex;
    align-items: baseline;
    justify-content: center;
    //height: 100%;
    //min-height: auto;
    //max-height: 500px;
    //overflow: hidden;

    height: 400px;
    max-height: 400px;
    //height: 400px;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;

    img {
      //width: 95%; //size for large screens
      //height: auto;
      //width: auto;
      //border-radius: 0px;
      height: 100%;
      width: auto;
      border-radius: 0px;
      object-fit: cover;
    }
  }

  .image-large-sticky {
    position: sticky;
    top: 90px;
    height: 100%;
  }

  .item-view-main {
    h3 {
      &.item-ttl {
        font-size: var(--item-title-size);
        color: var(--font-color);
        font-weight: 500;
        text-align: left;

        span {
          color: hsl(from var(--secondary-color) h s calc(l + 40));
          font-size: 1.2rem;
          font-weight: 400;
        }
      }

      &.item-price {
        font-size: 1.2rem;
        width: 100%;
        color: hsl(from var(--secondary-color) h s calc(l + 20));

        span {
          font-size: 1rem;
        }
      }

      &.display-price {
        font-size: 1.5rem;
        //margin-bottom: 1.5rem !important;
        color: var(--font-color);

        span {
          color: hsl(from var(--secondary-color) h s calc(l + 10));
        }
      }
    }

    .item-view-bg {
      //background: #ccc;
      height: 100%;
      line-height: 1.6rem;

      //padding: 1rem;
    }
  }

  .price-main {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
  }

  .image-video-main {
    .img-box {
      background-color: var(--white-color);
      box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.08);
      border-radius: 5px;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-right: 0.5rem;
      transition: all 0.3s;

      img {
        border-radius: 5px;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }

    .video-box {
      background-color: var(--white-color);
      box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.08);
      border-radius: 5px;
      padding: 0.5rem;
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-right: 0.5rem;

      transition: all 0.3s;

      &:hover {
        cursor: pointer;
        opacity: 0.8;

        .play-icon {
          color: var(--white-color);
        }
      }

      .overlay {
        display: block;
        width: 100%;
        height: 100%;
        //background: #000000c7;
        position: absolute;
        top: 0;
        bottom: 0;
        border-radius: 5px;
      }

      .play-icon {
        color: var(--primary-color);
        font-size: 2rem;
        position: absolute;
        z-index: 9;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .add-extra-box {
    display: flex;
    flex-direction: column;

    h5 {
      font-weight: 500;
    }

    .add-extra-price {
      word-break: break-all;
    }
  }

  .additional-info-box {
    p {
      //color: hsl(from var(--secondary-color) h s calc(l + 40));
      // font-size: 0.9rem;
    }
  }

  .price {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dfdfdfcc;
    padding: 0.5rem 0;
    align-items: center;

    span {
      //margin-right: 5rem;
    }
  }

  /*----wine----*/
  .view-wine-history {
    //display: inline-flex;
    //align-items: center;
    //font-weight: 500;
    color: var(--primary-color);
    font-weight: 500;
    font-size: 1rem;

    //background-color: var(--background-color-quaternary);
    background: hsl(
      from var(--primary-color-2) h s calc(l + 40)
    ); //for light background
    border-radius: 4px;
    justify-content: space-between;
    padding: 0.5rem 0.5rem;
    transition: all 0.3s;

    i {
      margin-right: 0.5rem;
    }

    &:hover {
      cursor: pointer;
      color: var(--font-color);
    }
  }

  .recomendation-ttle {
    span {
      color: var(--primary-color);

      & span {
        &:first-child {
          color: var(--secondary-color);
        }
      }
    }
  }

  /*------wine history page----*/
  .flag-box {
    background-color: var(--white-color);
  }

  .gallery-bg {
    background-color: hsl(from var(--primary-color) h s calc(l + 40));
  }

  .vgr-cards {
    .card {
      display: flex;
      flex-flow: wrap;
      flex: 100%;
      margin-bottom: 40px;
      border-bottom: 1px solid #dee2e6;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-radius: 0;

      &:nth-child(even) .card-img-body {
        order: 2;
      }

      &:nth-child(even) .card-body {
        padding-left: 0;
        padding-right: 1.25rem;
      }

      @media (max-width: 576px) {
        display: block;
      }
    }

    .card-img-body {
      flex: 1;
      overflow: hidden;
      position: relative;

      @media (max-width: 576px) {
        width: 100%;
        height: 200px;
        margin-bottom: 20px;
      }
    }

    .card-img {
      width: 100%;
      height: auto;
      position: absolute;
      margin-left: 50%;
      transform: translateX(-50%);

      @media (max-width: 1140px) {
        margin: 0;
        transform: none;
        width: 100%;
        height: auto;
      }
    }

    .card-content-body {
      flex: 2;
      padding: 0 0 0 1.25rem;

      @media (max-width: 576px) {
        padding: 0;
      }
    }
  }

  .origin-sec {
    .rounded {
      height: 100%;
    }
  }

  .item-badge {
    display: inline-flex;
    width: auto;
    flex-direction: column;
    align-self: self-start;
    font-weight: 500;
    background-color: hsl(from var(--primary-color) h s calc(l + 40));
    //background-color:  var(--primary-color);
    //color: var(--white-color);
    color: var(--primary-color);

    padding: 0rem 0.6rem;
    border-radius: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    margin-right: 0.3rem;
    //font-size: 0.9rem;
    font-size: 12px;
  }

  .description {
    //max-height: 3.5em;
    max-height: 4em;
    overflow: hidden;
    //white-space:normal;
    text-overflow: ellipsis;
    font-family: var(--custom-font-family) !important;
    //color: var(--font-color);
    //color: hsl(from var(--secondary-color) h s calc(l + 20));
    color: hsl(from var(--secondary-color) h s calc(l + 35));
    word-break: break-word;
    //font-size: var(--font-size) !important;
    font-size: 1rem !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    p {
      font-family: var(--custom-font-family) !important;
      color: var(--font-color) !important;
      font-size: var(--font-size) !important;

      span {
        font-family: var(--custom-font-family) !important;
        color: var(--font-color) !important;
        font-size: var(--font-size) !important;
      }
    }
  }
}

.shadow-custom {
  // box-shadow: 0 6px 20px #9797974d;
  box-shadow: 0 5px 16px #0000001f !important;
}

.back-btn {
  background-color: var(--white-color);
  font-weight: 500;
  font-size: var(--button-font-sz);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  align-items: center;
  display: inline-flex;

  &:hover {
    color: var(--primary-color);
  }

  i {
    margin-right: 5px;
  }
}

.custom-shadow {
  //box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.08);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

@media (max-width: 1199.98px) {
  #GuestMain {
    .container {
      max-width: 100% !important;
    }
  }
}

/*---wysiwyg editor-----*/

.ql-toolbar {
  &.ql-snow {
    background: var(--background-color-quaternary);
  }
}

.quill > .ql-toolbar:first-child {
  // display: none !important;
}

/*---new editor--*/
.wrapper-border {
  border: 1px solid #ccc;
  border-radius: 5px;
}

.editor-outer {
  padding: 0.5rem;
}

.toolbar-box {
  background: var(--background-color-quaternary) !important;
  border-radius: 5px 5px 0 0;
}

.css-1s2u09g-control {
  &:focus {
    border-color: var(--primary-color) !important;
  }
}

.css-1pahdxg-control {
  &:focus {
    border-color: var(--primary-color) !important;
    box-shadow: 0 0 0 1px var(--primary-color);
  }

  &:hover {
    border-color: var(--primary-color) !important;
    box-shadow: 0 0 0 1px var(--primary-color);
  }
}

.image-menu-sticky {
  position: sticky;
  top: -1px;
  z-index: 8;
}

/*-----loader----------*/

.custom-loader-new {
  width: 50px;
  height: 50px;
  display: grid;
  color: var(--primary-color);
  background: radial-gradient(
    farthest-side,
    currentColor calc(100% - 6px),
    #0000 calc(100% - 5px) 0
  );
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(100% - 13px),
    #000 calc(100% - 12px)
  );
  border-radius: 50%;
  animation: s9 2s infinite linear;
}

.custom-loader-new::before,
.custom-loader-new::after {
  content: "";
  grid-area: 1/1;
  background: linear-gradient(currentColor 0 0) center,
    linear-gradient(currentColor 0 0) center;
  background-size: 100% 10px, 10px 100%;
  background-repeat: no-repeat;
}

.custom-loader-new::after {
  transform: rotate(45deg);
}

@keyframes s9 {
  100% {
    transform: rotate(1turn);
  }
}

/*-------additional info---*/
.additional-info-box {
  font-family: var(--custom-font-family) !important;
  //font-size: 1rem;

  p {
    font-family: var(--custom-font-family) !important;

    span {
      font-family: var(--custom-font-family) !important;
    }
  }

  span {
    font-family: var(--custom-font-family) !important;
  }
}

.wine-history-info {
  font-family: var(--custom-font-family) !important;

  p {
    font-family: var(--custom-font-family) !important;

    span {
      font-family: var(--custom-font-family) !important;
    }
  }

  span {
    font-family: var(--custom-font-family) !important;
  }
}

/*-----gallery-----*/

.img-box {
  //margin:  0 10px;
  background-color: var(--white-color);
  //box-shadow: 5px 5px 30px rgb(0 0 0 / 8%);
  border-radius: 5px;
  //padding: 0.5rem;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 0.5rem;
  transition: all 0.3s;
  width: auto !important;
  border: 1px solid #ebebeb;

  img {
    border-radius: 5px;
  }
}

.p-galleria-item {
  img {
    cursor: pointer;
  }
}

.p-galleria-mask {
  .p-galleria-item {
    img {
      cursor: default !important;
    }
  }
}

.video-box {
  //margin:  0 10px;
  background-color: var(--white-color);
  //box-shadow: 5px 5px 30px rgb(0 0 0 / 8%);
  border-radius: 5px;
  //padding: 0.5rem;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 0.5rem;
  transition: all 0.3s;
  width: auto !important;
  border: 1px solid #ebebeb;
  justify-content: center;
  position: relative;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;

  .overlay {
    position: absolute;
    background: #00000066;
    //top: 0;
    height: 90%;
    width: 90%;
    border-radius: 5px;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.8;

    .play-icon {
      color: var(--white-color);
    }
  }

  img {
    border-radius: 5px;
  }

  .play-icon {
    color: var(--primary-color);
    font-size: 1.5rem;
    position: absolute;
    display: flex;
  }
}

.gallery-thumb {
  width: 80px !important;
  height: 80px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-left: 0.3rem;
  //padding: 0;
  border-radius: 0px;
  border-radius: 5px;
  padding: 0.3rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0px;
  }

  &:hover {
    cursor: pointer;
  }
}

.slick-prev {
  &:before {
    color: var(--secondary-color) !important;
    font-size: 2rem !important;
  }
}

.slick-next {
  &:before {
    color: var(--secondary-color) !important;
    font-size: 2rem !important;
  }
}

.slick-prev {
  left: -20px !important;
  z-index: 27 !important;
}

.slick-next {
  right: -25px !important;
}

.slick-prev,
.slick-next {
  width: auto !important;
  height: auto !important;
}

.slick-slider {
  margin: 0 1.5rem !important;
}

.gallery-slider-outer {
  width: 100%;
  padding: 1rem;

  &.bg {
    background: var(--bg-color) !important;
  }

  .p-galleria-thumbnail-container {
    img {
      width: 80px !important;
      height: 60px !important;
      max-width: 80px !important;
      max-height: 60px !important;
    }
  }

  .p-galleria-item-container {
    video {
      width: 100%;
      height: 100%;
    }
  }

  .p-galleria-item {
    height: 350px;

    img {
      max-height: 100% !important;
      min-height: 100% !important;
    }
  }

  .p-galleria-thumbnail-item-content {
    video {
      width: 80px !important;
      height: 60px !important;
      max-width: 80px !important;
      max-height: 60px !important;
    }
  }

  @media (max-width: 520.98px) {
    .p-galleria-item {
      height: 280px;
    }
  }
}

.slick-track {
  margin-left: 0 !important;
}

.gallery-slide-thumb {
  .slick-slider {
    margin: 0 0rem !important;

    .slick-prev {
      left: -8px !important;
      z-index: 27 !important;
    }

    .slick-next {
      right: -8px !important;
    }
  }
}

.custom-primary-header {
  .accordion-button {
    //background-color: hsl(from var(--primary-color) h s calc(l + 40));
  }
}

.wine-history-title {
  word-break: break-word;

  h2 {
    //font-size: 1.3rem;
  }
}

.wine-spec {
  .wine-spec-des {
    display: flex;
    flex-wrap: wrap;
    word-break: break-all;
  }
}

.filter-icon {
  color: var(--primary-color);
  font-size: 2rem;
  cursor: pointer;

  &:hover {
    color: var(--font-color);
  }
}

.filter-btn {
  > .btn {
    background-color: transparent;
    border-color: transparent;
    color: var(--font-color);
    min-width: 0;
    padding: 0;
    font-size: 1.8rem;
    height: 36px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: var(--primary-color);
    }

    &.dropdown-toggle::after {
      display: none;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .dropdown-menu {
    min-width: 265px !important;
  }

  &.show {
    i {
      color: var(--primary-color);
    }

    .btn-success {
      &.dropdown-toggle {
        background-color: transparent;
        border-color: transparent;
        color: var(--font-color);

        &:focus {
          box-shadow: none;
          color: var(--primary-color);
        }
      }
    }
  }
}

.no-action {
  color: #ccc;
  margin-top: 5rem;

  i {
    font-size: 5rem;
    color: #ccc;
  }
}

.invalid-feedback ~ .is-invalid-custom {
  display: block;
}

.word-break-grid {
  word-break: break-word;
  //line-height: 1.2rem;
}

.border-red {
  border: 1px solid var(--green-color);
}

.clone {
  color: var(--light-blue-color);
  font-size: 1.2rem;
}

.acrdn-custom-icon {
  transition: transform 0.2s ease-in-out;

  i {
    font-size: 1.7rem;
  }

  &.icon-rotate {
    transform: rotate(-180deg);
    transition: transform 0.2s ease-in-out;
  }
}

.custom-accordian-card {
  .accordion-button {
    background-color: var(--background-color-tertiary) !important;
  }
}

.input-group-append {
  .btn {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.download {
  color: var(--dashboard-green);
  font-size: 1.5rem;
  cursor: pointer;
}

/*----qr code---*/
#qr-img {
  display: inline-block;
}

#qr-img {
  display: inline-block;
}

#qr-img ~ div {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0.5rem 0;
}

.custom-badge-qr {
  position: absolute;
  right: -7px;
  top: -7px;
  border-radius: 30px !important;
  text-align: center;
  padding: 0 0.3rem !important;
  font-size: 10px !important;
  font-weight: normal !important;
  width: 30px;
  height: 30px;

  &.p-badge {
    &.p-badge-info {
      background-color: var(--primary-color) !important;
    }
  }
}

/*---sticky grid column ---*/

.p-frozen-column {
}

.bg-sticky {
  background-color: var(--background-color-quaternary) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-sticky-color {
  background-color: var(--background-color-quaternary) !important;
  text-align: center;
  vertical-align: middle;
}

.td-center {
  display: flex;
  justify-content: center;
}

/*---footer---*/

footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: var(--primary-background-color) !important;
  color: var(--primary-color) !important;
}

.copyright-container {
  //position: absolute;
  //bottom: 0.5rem;
  width: 100%;
  left: 0;

  .copyright {
    font-size: 0.8rem;
    text-align: center;
    color: #b9b9b9;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-left: 0rem;

    p {
      margin-bottom: 0;
    }
  }

  .copyright-collapse {
    text-align: center;
    width: 100%;
    padding-left: 1rem;
    display: none;
    color: #b9b9b9 !important;
    transition: all;
  }
}

/*--------login password-----*/
.custom-login-password {
  position: relative;

  .custom-password-textbox {
    padding-right: 3rem;
  }

  .login-password-icon {
    z-index: 1;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    right: 0;
    width: 2rem;
    cursor: pointer;
    position: absolute;
    padding-right: 1rem;
    font-size: 1.5rem;
  }
}

.width-0 {
  width: 0;
}

/*----slider-----*/
.nav-scroller-content-new {
  .slick-slider {
    margin: 0 0rem !important;

    .slick-prev {
      left: 0px !important;
      width: 2rem !important;
      height: 100% !important;
      //background: hsl(from var(--primary-color) h s calc(l + 35)) !important;
      background-color: #0000008c;
      border-radius: 5px 0 0 5px;

      &::before {
        color: var(--white-color) !important;
        font-size: 1.5rem !important;
      }
    }

    .slick-next {
      right: 0px !important;
      width: 2rem !important;
      height: 100% !important;
      border-radius: 0px 5px 5px 0px;
      //background: hsl(from var(--white-color) h s calc(l + 42)) !important;
      background-color: #0000008c;

      &::before {
        color: var(--white-color) !important;
        font-size: 1.5rem !important;
        opacity: 1;
      }
    }
  }
}

.update {
  .btn {
    min-width: auto !important;
  }
}

.dis-key {
  caret-color: transparent;
  cursor: pointer;
}

/*--manage-user---*/
.user-delete {
  position: relative;
  overflow: hidden;
  border-radius: 50%;

  .delete-user-icon {
    align-items: center;
    background: var(--white-color);
    border-radius: 0;
    height: 100%;
    justify-content: center;
    position: absolute;
    transition: all 1s;
    width: 100%;
    display: flex;
    color: var(--green-color);
  }
}

/*---card---*/

.card-header {
  .filter-section {
    display: flex;
    justify-content: end;
  }
}

.fs-1-3 {
  font-size: 1.3rem;
}

.email-text {
  // text-decoration: underline;
  color: var(--blue-color);
  cursor: pointer !important;

  a {
    color: var(--blue-color) !important;
  }
}

.disabled-custom {
  pointer-events: none;
  opacity: 0.65;
  color: #ccc !important;
  border-color: #ccc !important;
  filter: none;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.display-price + .display-price {
  margin-bottom: 1.5rem !important;
}

/*----prime side bar---*/
.p-sidebar-right {
  .p-sidebar {
    width: 40rem !important;
    height: 100%;
  }
}

.custom-offcanvas {
  &.offcanvas-end {
    width: 45rem !important;
  }

  &.minimum-employee-sidebar {
    width: 35rem !important;
  }

  &.offcanvas {
    z-index: 1041 !important;
  }
}

.p-multiselect-panel {
  z-index: 1042 !important;
}

/*-------prime react tab view--------*/
.custom-prime-tab-main {
  &.p-tabview {
    .p-tabview-nav {
      li {
        &.p-highlight {
          .p-tabview-nav-link {
            border-color: var(--primary-color);
            color: var(--font-color);
          }
        }
      }

      .p-tabview-ink-bar {
        background-color: var(--primary-color);
      }

      .p-tabview-nav-link:not(.p-disabled):focus {
        //box-shadow: inset 0 0 0 0.2rem #000722;
        box-shadow: inset 0 0 0 0.2rem
          hsl(from var(--primary-color) h s calc(l + 45));
      }
    }
  }

  .p-tabview-panels {
    overflow: hidden;
    padding: 0.5rem !important;
    flex-grow: 1;
  }

  .p-tabview-panel {
    height: 100%;
  }
}

.p-tabview {
  .p-tabview-nav {
    li {
      .p-tabview-nav-link {
        &:focus {
          // box-shadow: inset 0 0 0 0.2rem hsl(from var(--primary-color) h s calc(l + 40)) !important;
          //margin: 0 0 -1px 0;
        }
      }
    }
  }
}

.custom-prime-dropdown {
  &.p-dropdown:not(.p-disabled) {
    &:hover {
      border-color: var(--primary-color);
    }

    &.p-focus {
      box-shadow: 0 0 0 0.2rem hsl(from var(--primary-color) h s calc(l + 45));
      border-color: var(--primary-color);
    }
  }
}

.p-dropdown-panel {
  .p-dropdown-items {
    .p-dropdown-item {
      &.p-highlight {
        color: var(--primary-color) !important;
        background-color: hsl(
          from var(--primary-color) h s calc(l + 42)
        ) !important;
      }
    }
  }
}

/*------prime react thumb button-----*/
.custom-thumb-icon {
  cursor: pointer;

  &.p-disabled {
    filter: grayscale(100%);
    opacity: 0.4;
    cursor: not-allowed !important;
  }
}

/*------lock drag and drop button in F&B menu-----*/
.custom-input-switch {
  &.p-inputswitch {
    //height: 1.45rem;
  }

  &.p-inputswitch {
    &.p-inputswitch-checked {
      .p-inputswitch-slider {
        background: var(--primary-color) !important;
      }
    }
  }

  &.p-inputswitch-checked:not(.p-disabled):hover {
    .p-inputswitch-slider {
      background: var(--primary-color) !important;
    }
  }
}

.lock-dnd {
  color: var(--danger-color-2);
  margin-left: 0.5rem;

  :hover {
    color: black;
  }
}

.unlock-dnd {
  color: var(--success-color);
  margin-left: 0.5rem;

  :hover {
    color: black;
  }
}

.invoice-table-settings {
  float: right;
  position: sticky;
}

.skeleton-loader {
  display: flex;

  .p-skeleton {
    // width: 20vw !important;
    height: 125px !important;
    margin: 0 0.2rem;
    opacity: 0.5;
  }

  @media (max-width: 1199.98px) {
    .p-skeleton {
      &:nth-last-child(1) {
        display: none;
      }
    }
  }

  @media (max-width: 721.98px) {
    .p-skeleton {
      &:nth-last-child(1) {
        display: none;
      }

      &:nth-last-child(2) {
        display: none;
      }
    }
  }

  @media (max-width: 480.98px) {
    .p-skeleton {
      &:nth-last-child(1) {
        display: none;
      }

      &:nth-last-child(2) {
        display: none;
      }

      &:nth-last-child(3) {
        display: none;
      }
    }
  }

  @media (max-width: 360.98px) {
    .p-skeleton {
      &:nth-last-child(1) {
        display: none;
      }

      &:nth-last-child(2) {
        display: none;
      }

      &:nth-last-child(3) {
        display: none;
      }

      &:nth-last-child(4) {
        display: none;
      }
    }
  }
}

.visibility-hidden {
  visibility: hidden;
}

/*-------------qr code scanner---------*/

.qr-code-scan {
  cursor: pointer;
  .p-button {
    &.p-button-warning {
      &:enabled {
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  &.icon-only-link {
    font-size: 1.3rem;
  }
  &:hover {
    color: hsl(from var(--font-color) h s calc(l + 40)); //for light background
  }
}

.p-card {
  &.p-component {
    font-family: var(--custom-font-family) !important;
  }
}

.qr-status {
  .p-card-content {
    padding: 0;
  }

  .p-card-title {
    font-size: 1.2rem;
    font-weight: normal;
    margin-bottom: 1rem;
  }
}

.qr-cam-wrapper {
  width: 400px;
}

.modal-dialog {
  .qr-cam-wrapper {
    width: 100%;
  }
}

.custom-badge {
  padding: 0.2rem 0.6rem !important;
  border-radius: 25px;
  display: inline-flex !important;
  /* flex-direction: row; */
  align-items: center !important;
  justify-content: center !important;
  font-size: 0.9rem !important;
  font-family: var(--custom-font-family) !important;
  font-weight: normal !important;
}
.holiday-list-card {
  .custom-badge {
    font-size: 0.8rem !important;
  }
}
/*-------custom calendar------*/
.custom-calendar {
  background-color: white !important;
}

.flatpickr-months {
  background: var(--primary-color) !important;

  .flatpickr-month {
    background: var(--primary-color) !important;
  }
}

.flatpickr-weekdays {
  background: var(--primary-color) !important;
}

span {
  &.flatpickr-weekday {
    background: var(--primary-color) !important;
  }
}

.flatpickr-current-month {
  .flatpickr-monthDropdown-months {
    background: var(--primary-color) !important;
  }
}

.flatpickr-current-month {
  .flatpickr-monthDropdown-months {
    .flatpickr-monthDropdown-month {
      background-color: var(--white-color) !important;
      color: var(--font-color);
      font-size: 1rem;
    }
  }
}

.flatpickr-calendar {
  &.arrowBottom:after {
    border-top-color: var(--primary-color) !important;
  }
}

.flatpickr-calendar {
  &.arrowBottom:before {
    border-top-color: var(--primary-color) !important;
  }
}

.flatpickr-calendar {
  &.arrowTop:after {
    border-bottom-color: var(--primary-color) !important;
  }
}

.disabled-div {
  opacity: 0.5;
  /* Set the opacity to visually indicate the div is disabled */
  pointer-events: none;
  /* Disable pointer events to prevent user interaction */
}

.checkbox-container {
  background: #dbdbdb;
  padding: 0.5rem;
  border-radius: 3px 0 0 3px;
}

.label-container {
  background: var(--background-color-tertiary);
}

.p-panel-content-custom {
  border: 1px solid #dee2e6 !important;
  border-radius: 6px !important;

  .p-panel-content {
    border: none !important;
    border-radius: none !important;
    border-top-right-radius: 6px !important;
    border-top-left-radius: 6px !important;
    padding: 1rem;
  }
}

.drag-bg {
  background: #eee;
  padding: 0.5rem 1rem;
  border-radius: 6px;
}

.market-price-text {
  font-size: 1rem;
  font-weight: 500;
  font-style: italic;
  //color: #626262;
  color: hsl(from var(--secondary-color) h s calc(l + 20));
  word-break: break-word;
}

.global-text-selection {
  -webkit-user-select: none;
  /* Disable selection for Chrome, Safari, and Opera */
  -moz-user-select: none;
  /* Disable selection for Firefox */
  -ms-user-select: none;
  /* Disable selection for Internet Explorer and Microsoft Edge */
  user-select: none;
  /* Disable selection for all browsers */
}

/*----profile photo---*/
.profile-bg {
  background-color: hsl(from var(--primary-color) h s calc(l + 45));
  padding: 1rem;
  //border: 1px dashed var(--primary-color);
  border-radius: 5px;
  position: relative;

  .profile-container {
    position: relative;

    .p-avatar {
      border: 1px solid var(--primary-color);
      width: 8rem;
      height: 8rem;
    }

    .cam-icon {
      position: absolute;
      bottom: 5px;
      right: 3px;
      /* font-size: 2rem; */
      background: var(--primary-color);
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 1.1rem;
        color: var(--white-color);
      }

      &:hover {
        background-color: hsl(from var(--primary-color) h s calc(l + 10));
        cursor: pointer;
      }
    }
  }

  .profile-help {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    right: 5px;
    top: 1px;
  }

  .user-delete-profile {
    position: relative;

    .delete-user-icon {
      position: absolute;
      bottom: 5px;
      left: 3px;
      /* font-size: 2rem; */
      background: var(--danger-color);
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--white-color);

      &:hover {
        background-color: hsl(from var(--red-color) h s calc(l + 8));
        cursor: pointer;
      }
    }
  }

  .help-main {
    color: var(--primary-color);

    &:hover {
      color: hsl(from var(--primary-color) h s calc(l + 25)) !important;
    }
  }
}

.password-checklist {
  li {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    line-height: 16px;

    span {
      opacity: 0.7;
    }
  }
}

.welcome-img-left {
  min-height: 380px;
}

/*---custom datepicker-----*/
.react-datepicker-wrapper {
  width: 100%;
}

.custom-datepicker {
  &.react-date-picker {
    display: block;

    .react-date-picker__wrapper {
      border: 1px solid #ced4da;
      border-radius: 0.25rem;

      .react-date-picker__inputGroup {
        padding: 7px 5px;
        box-sizing: content-box;
        display: flex;
        align-items: center;

        .react-date-picker__inputGroup__input {
          padding: 1.5px 5px;
          width: 30.33% !important;
          //letter-spacing: 3px;
          //background: var(--background-color-quaternary);
          //text-align: center;

          &:invalid {
            background: rgba(255, 0, 0, 0.1) !important;
          }
        }

        .react-date-picker__inputGroup__leadingZero {
          visibility: hidden;
        }
      }
    }

    .react-date-picker__button {
      svg {
        stroke: #5c5c5c;
      }
    }
  }
}

:where(.css-dev-only-do-not-override-15rg2km).ant-picker-focused {
  border-color: var(--primary-color);
}

:where(.css-dev-only-do-not-override-15rg2km).ant-picker:hover,
:where(.css-dev-only-do-not-override-15rg2km).ant-picker-focused {
  border-color: var(--primary-color);
}

:where(.css-dev-only-do-not-override-15rg2km).ant-btn-primary:not(
    :disabled
  ):active {
  background-color: var(--primary-color);
}

:where(.css-dev-only-do-not-override-15rg2km).ant-btn-primary:not(
    :disabled
  ):hover {
  background-color: var(--primary-color);
}

:where(.css-dev-only-do-not-override-15rg2km).ant-btn-primary {
  background-color: var(--primary-color);
}

.react-datepicker__close-icon::after {
  background-color: var(--primary-color) !important;
}

@keyframes beating {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.buttonBeat {
  animation: beating 1.5s infinite;
}

/*----alert----*/
.custom-alert {
  &.alert-danger {
    color: var(--green-color);
    background-color: hsl(from var(--red-color) h s calc(l + 30));
    border-color: var(--danger-color-2);
    padding: 0.8rem;
    font-size: 0.9rem;

    p {
      margin-bottom: 0.5rem;
    }
  }
}

.updateAvailable {
  .p-dialog-content {
    padding: 1rem !important;
  }

  .p-dialog-header {
    padding: 1rem 1.5rem !important;
    background-color: hsl(
      from var(--primary-color) h s calc(l + 40)
    ) !important;
  }

  @media screen and (max-width: 340px) {
    .updateFooter {
      text-align: center;
      gap: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;

      button {
        width: 100%;
        margin: 0 !important;
      }
    }
  }
}

/*----custom country phone number-----*/
.custom-country-phone {
  input {
    border: none;

    &:focus-visible {
      outline: none;
    }

    &.is-invalid-custom {
      background-image: none;
      border-color: var(--danger-color);
    }
  }
}

/*-----custom matrix slider---*/
.ant-slider-mark {
  display: none !important;
  visibility: hidden;
}

.ant-slider-horizontal .ant-slider-rail {
  width: 100%;
  height: 9px;
}

.ant-slider-horizontal .ant-slider-track {
  height: 9px;
}

.ant-slider .ant-slider-dot {
  width: 20px;
  height: 20px;
}

.ant-slider-horizontal .ant-slider-dot {
  inset-block-start: -5px;
}

.ant-slider-horizontal .ant-slider-step {
  height: 9px;
}

.ant-slider .ant-slider-handle {
  width: 15px;
  height: 15px;
}

.ant-slider-horizontal .ant-slider-handle {
  inset-block-start: 2px;
}

.ant-slider .ant-slider-handle::after {
  width: 15px;
  height: 15px;
  box-shadow: 0 0 0 3px var(--primary-color);
}

.ant-slider .ant-slider-handle:hover::after,
:where(.css-dev-only-do-not-override-15rg2km).ant-slider
  .ant-slider-handle:active::after,
:where(.css-dev-only-do-not-override-15rg2km).ant-slider
  .ant-slider-handle:focus::after {
  width: 15px;
  height: 15px;
  inset-inline-start: 0px;
  inset-block-start: -1px;
}

/**
slider updates
*/
.ant-slider .ant-slider-handle:hover::after,
.ant-slider .ant-slider-handle:active::after,
.ant-slider .ant-slider-handle:focus::after {
  width: 15px;
  height: 15px;
}

.ant-slider .ant-slider-handle:hover::after,
.ant-slider .ant-slider-handle:active::after,
.ant-slider .ant-slider-handle:focus::after {
  box-shadow: 0 0 0 4px var(--primary-color);
}

.ant-slider .ant-slider-dot-active {
  border-color: var(--primary-color);
}

.ant-slider .ant-slider-handle:active::after {
  box-shadow: 0 0 0 4px var(--primary-color) !important;
}

.ant-slider:hover .ant-slider-handle::after {
  box-shadow: 0 0 0 2px var(--primary-color);
}

.ant-slider .ant-slider-handle:after {
  box-shadow: 0 0 0 3px var(--primary-color);
  height: 15px;
  width: 15px;
}

/**
slider updates ends
*/

/*j test*/

.ant-slider .ant-slider-handle:hover::after,
.ant-slider .ant-slider-handle:active::after,
.ant-slider .ant-slider-handle:focus::after {
  box-shadow: 0 0 0 4px var(--primary-color) !important;
  width: 12px;
  height: 12px;
  inset-inline-start: 0px !important;
  inset-block-start: 0px !important;
}

.ant-slider .ant-slider-handle:hover::after,
.ant-slider .ant-slider-handle:active::after,
.ant-slider .ant-slider-handle:focus::after {
  box-shadow: 0 0 0 4px var(--primary-color) !important;
  width: 12px;
  height: 12px;
  inset-inline-start: -1px;
  inset-block-start: -1px;
}

.ant-slider-handle::after {
  box-shadow: 0 0 0 4px var(--primary-color) !important;
}

.ant-slider-dot-active {
  border-color: var(--primary-color-3) !important;
}

/*j test ends*/

/*----slider for menu config---*/
.overflow-x-hidden {
  overflow-x: hidden !important;
}

.no-option-selected {
  color: var(--danger-color-1);
}

/*----floating button----*/
.floating-container {
  /* position: fixed;
  bottom: 17px;
  right: 17px;
  display: flex;
  flex-direction: column;
  z-index: 9; */

  //padding: 2.5px;
  position: fixed;
  bottom: 0.75rem;
  right: 0;
  /* right: 50%; */
  display: flex;
  flex-direction: row;
  z-index: 999;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 5px;
  transition: all 0.5s ease;

  .search-bg {
    background-color: hsl(from var(--primary-color) h s calc(l + 40));
    display: flex;
    padding: 0.5rem;
    //gap: 0.5rem;
    border-radius: 30px;
    border: 1px solid hsl(from var(--primary-color) h s calc(l + 25));
    transition: all 2s ease;
    align-items: center;

    .form-control {
      background-color: transparent;
      background-clip: padding-box;
      border: none;
      border-bottom: 2px solid var(--primary-color) !important;
      color: var(--secondary-color);

      &:focus {
        box-shadow: none !important;
      }
    }
  }

  .search-container {
    /* padding: 2.5px;
    position: fixed;
    bottom: 0.75rem;
    right: calc(50%);
    display: flex;
    flex-direction: column;
    z-index: 9; */

    .p-button {
      // color: var(--secondary-color);
      font-weight: 600;

      &.search-cancel {
        color: var(--secondary-color);
        font-weight: 600;
      }

      &:hover {
        background: none;
        border-color: transparent;
        color: var(--green-color);
      }

      &:active {
        background: none;
        border-color: transparent;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  .filter-container {
    gap: 5px;
  }

  .categroy-container {
    /* padding: 2.5px;
    position: fixed;
    bottom: 0.75rem;
    right: calc(50% - 50px);
    display: flex;
    flex-direction: column;
    z-index: 9; */
  }

  .tags-container {
    /*  padding: 2.5px;
    position: fixed;
    bottom: 0.75rem;
    right: calc(50% - 100px);
    display: flex;
    flex-direction: column;
    z-index: 9; */
  }

  .bottom-65:nth-child(1) {
    // bottom: 50px;
    position: relative;
  }

  .bottom-65:nth-child(2) {
    // bottom: 120px;
    position: relative;
  }
}

.popover_wrapper {
  min-width: 15rem;
  z-index: 99999 !important;

  ul {
    padding-left: 5px;
    max-height: 150px;
    min-height: 150px;
    overflow-y: auto;

    li {
      list-style: none;
      margin-bottom: 5px;
    }
  }
}

.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999 !important;
}

.custom-overlay:not(:empty) {
  + .categroy-container {
    z-index: 9999999;
  }
}

body.no-scroll {
  overflow: hidden;
}

.bottom-65 {
  /* bottom: 50px !important;
  position: relative;
  transition: 2s all; */
}

.category-filter-btn {
  &.p-button {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);

    &:enabled {
      &:hover {
        background: hsl(from var(--primary-color) h s calc(l - 2));
        color: var(--white-color);
        border-color: hsl(from var(--primary-color) h s calc(l - 2));
      }
    }

    &:focus {
      box-shadow: 0 0 0 2px var(--white-color),
        0 0 0 4px hsl(from var(--primary-color) h s calc(l + 20));
    }
  }
}

.tags-filter-btn {
  &.p-button {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);

    &:enabled {
      &:hover {
        background: hsl(from var(--primary-color) h s calc(l - 2));
        color: var(--white-color);
        border-color: hsl(from var(--primary-color) h s calc(l - 2));
      }
    }

    &:focus {
      box-shadow: 0 0 0 2px var(--white-color),
        0 0 0 4px hsl(from var(--primary-color) h s calc(l + 20));
    }
  }
}

.p-scrolltop.p-link {
  background: rgba(0, 0, 0, 0.5) !important;
}

/* qr code generator  **/
.qr-code-parent {
  position: relative;
  display: flex;
  width: 350px;
  // display: flex;
  // width: min-content;
  // justify-content: center; /* Center items horizontally */
  // align-items: center; /* Center items vertically */
  // text-align: center; /* Center text content */
  // background: rgba(255, 255, 255, 0.8); /* Semi-transparent white background, adjust as needed */
}

.qr-image-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .fnb-qr-code-logo {
    position: absolute;
    width: 55px;
    height: 55px;
    border: 5px solid white;
    /* Increase border width and use a darker color */
    background-color: white;
  }
}

.cus-menu-qr-code-logo {
  position: absolute;
  width: 55px;
  height: 55px;
  border: 5px solid white;
  /* Increase border width and use a darker color */
  background-color: white;
  border-radius: 5%;
  /* Make it a circular shape */
  margin-left: calc(50% - 25px);
  margin-top: calc(50% - 25px);
  left: 0;
  top: 0;
}

.user-qr-code-logo {
  position: absolute;
  width: 55px;
  height: 55px;
  border: 5px solid white;
  /* Increase border width and use a darker color */
  background-color: white;
  border-radius: 5%;
  /* Make it a circular shape */
  margin-left: calc(50%);
  margin-top: calc(50%);
  left: 0;
  top: 0;
}

.qr-img {
  position: relative;
}

.qr-code-child {
  text-align: center;
  padding: 1rem 0;
  white-space: normal !important;
}

.qr-code-label-top {
  font-size: 1.5rem;
  font-weight: bold;
  white-space: nowrap;
  margin-top: 1rem;
  margin-bottom: -0.5rem;
}

.qr-code-preferred-name {
  text-align: center;
  white-space: normal;
  font-size: 1.5rem;
  width: 100%;
  display: block;
  font-weight: 500;
}

.qr-code-label {
  text-align: center;
  white-space: normal;
  width: 100%;
  display: block;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.qr-code-label-bottom {
  font-size: 1.25rem;
}

.qr-code-text-area {
  height: 125px;
  max-height: 125px;
}

// add on page
.addon-price {
  min-width: 110px;
  justify-content: end;
  display: flex;
}

.capsule {
  display: inline-flex;
  padding: 5px 10px;
  margin: 0 0.25rem 0.25rem 0;
  border-radius: 50px;
  //background-color: hsl(from var(--primary-color) h s calc(l + 40));
  //color: var(--primary-color);
  background-color: hsl(from var(--secondary-color) h s calc(l + 76));
  color: var(--secondary-color);
}

.wysiwyg-editor {
  z-index: 0;
}

/*-----breadcrumb----*/
.back-btn-new {
  min-width: auto !important;
  font-size: 1.5rem;
  background-color: hsl(from var(--primary-color) h s calc(l + 38));
  color: var(--primary-color);
  transition: all 0.5s ease;
  //box-shadow: 0 0 0 1px var(--primary-color);
  //box-shadow: 5px 5px 30px hsl(from var(--primary-color) h s calc(l + 38));

  i {
    margin-right: 0;
    right: 0;
    transition: all 0.5s ease;
  }

  &:hover {
    background-color: hsl(from var(--primary-color) h s calc(l + 35));

    & i {
      right: 2px;
      position: relative;
      //transition: all 0.5s;
      transition: all 0.5s ease;
    }
  }
}

.custom-breadcrumb {
  background: none !important;
  border: none !important;
  font-family: var(--custom-font-family) !important;
  font-size: 1rem !important;

  &.link-visibility {
    a {
      cursor: default !important;

      &:hover {
        span {
          color: var(--font-color) !important;
        }
      }
    }
  }

  ul {
    width: max-content;

    li {
      .p-menuitem-link {
        &:hover {
          span {
            color: var(--primary-color);
          }
        }

        &:focus {
          box-shadow: none !important;
        }
      }

      &:first-child {
        display: none;
      }

      &:last-child {
        & a {
          font-weight: normal;
        }
      }
    }
  }

  ul {
    & li {
      & a {
        font-weight: 500;
      }
    }
  }
}

.p-breadcrumb ul li .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #c7d2fe;
}

.card {
  border: none !important;
  border-radius: 0.5rem !important;

  .card-header {
    background: var(--white-color) !important;
  }
}

.hover-bg {
  margin: 2px 0 0 0;
  padding: 2px;
}

.ingredient-description {
  height: 200px;
}

@keyframes beat {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.beat-effect {
  animation: beat 1s infinite;
}

.glow-effect {
  animation: glow 2s infinite;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 0 var(--primary-color-2);
  }

  50% {
    box-shadow: 0 0 10px 5px var(--primary-color-2);
  }

  100% {
    box-shadow: 0 0 0 var(--primary-color-2);
  }
}

.ingredients-list {
  li {
    &::before {
      color: var(--primary-color);
    }
  }
}

.key-ingredients-link {
  background-color: var(--background-color-quaternary);
  border-radius: 4px;
  justify-content: space-between;
  padding: 0.5rem 0.5rem;
  transition: all 0.3s;

  .ing-link {
    &.icon {
      color: var(--primary-color);
    }
  }

  .bullet {
    //font-size: 0.5rem;
    color: var(--primary-color);
    font-size: 0.9rem;
    padding-left: 8px;

    i {
      color: var(--primary-color);
    }
  }

  .ing-link:last-child {
    margin-left: auto;
  }

  &:hover {
    //background-color: hsl(from var(--primary-color) h s calc(l + 25));
    //color: var(--white-color);
    background-color: #e4e4e4;
    cursor: pointer;
    transition: all 0.3s;

    .bullet {
      //font-size: 0.5rem;
      //color: var(--white-color);
      color: var(--font-color);
    }
  }
}

.offcanvas-body {
  padding-bottom: 5rem;
}

/*----translation----*/

.customLangTranslate {
  margin-right: 5px;

  &.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none !important;
  }

  .country-item {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      // border-radius: 50%;
      // height: 30px;
      // width: 30px;
    }

    span {
      margin-left: 0.5rem;
    }
    .translate-text {
      font-size: 0.8rem;
      display: flex;
    }
    .language-arrow {
      font-size: 1rem;
    }
  }

  &.p-dropdown {
    border: none;
    border-radius: 50%;
  }

  .p-dropdown-label {
    padding: 0;
  }

  .p-dropdown-trigger {
    display: none;
  }

  .p-dropdown-panel {
    left: -5px !important;

    .p-dropdown-item {
      padding: 0.75rem 1rem;
      display: flex;
      justify-content: flex-start;
    }
  }
}

/*---custom menu----*/
.custom-menu {
  h2 {
    font-size: 1.5rem;
    color: var(--primary-color);
  }
}

.custom-menu-banner {
  height: 250px;

  img {
    width: 100%;
    border-radius: 0.3rem;
    height: 100%;
    background-size: cover;
    object-fit: cover;
    object-position: center center;
  }
}

.custom-menu-content {
  p {
    font-size: 1.1rem;
  }
}

.splitter {
  display: block;
  text-align: center;

  i {
    color: var(--primary-color);
    font-size: 1.5rem;
  }

  hr {
    border: none;
    height: 20px;
    width: 90%;
    height: 50px;
    margin-top: 0;
    border-bottom: 1px solid #9d9d9d;
    box-shadow: 0 20px 20px -20px #4a4a4a;
    margin: -50px auto 10px;
    background-color: transparent;
  }
}

.f-b-height {
  height: calc(100% - 1rem);
}

.rdw-dropdown-wrapper.rdw-fontsize-dropdown {
  width: 60px !important;
}

.display-none {
  display: none !important;
}

/*----ingredients---*/
.ingredients-gallery {
  .p-galleria-thumbnail-item-content {
    video {
      max-width: 150px;
      width: 100px;
      max-height: 50px;
      height: 50px;
      object-fit: cover;
    }
  }

  .p-galleria-item-wrapper {
    video {
      width: 100%;
    }
  }
}

.pl-165 {
  padding-left: 165px !important;
}

.addonAlignment {
  justify-content: flex-end;
  // padding-right: 50px !important;
}

/*-----news and events-----*/
.h-40 {
  height: 160px;
}

.object-fit-cover {
  object-fit: cover;
}

.newsEventsWrapper {
  h3 {
    font-size: 1.3rem;
    font-weight: 500;
    text-align: left !important;
  }

  .news-box {
    h5 {
      font-weight: 500;
      font-weight: 800;
      line-height: 22px;
      font-size: 1.125rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    hr {
      opacity: 0.1;
    }

    &:hover {
      box-shadow: 0px 0px 17px 0px rgb(154 161 171 / 18%);
      cursor: pointer;
    }

    .description-news-events {
      max-height: fit-content !important;
    }
  }

  .eventDate {
    background-color: hsl(
      from var(--primary-color) h s calc(l + 35)
    ) !important;
    color: var(--primary-color) !important;
    padding: 0.3rem 0.8rem;
    flex-shrink: 0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .month {
      color: var(--primary-color);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      flex-shrink: 0;
    }

    .day {
      color: var(--primary-color);
      font-size: 2rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      flex-shrink: 0;
    }

    .year {
      color: var(--primary-color);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      flex-shrink: 0;
    }
  }
}

.shareButton {
  background-color: hsl(from var(--primary-color) h s calc(l + 35)) !important;
  color: var(--primary-color) !important;
  border: 1px solid hsl(from var(--primary-color) h s calc(l + 30)) !important;
  font-size: 1.3rem;
  padding: 0.3rem 0.8rem;
  border-radius: 0.25rem;
  transition: all;

  &:hover {
    background-color: hsl(
      from var(--primary-color) h s calc(l + 30)
    ) !important;
    transition: all;
  }
}

.social_container {
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .social_media_button {
    padding: 0.5rem;
    border-radius: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    background: #ccc;
    width: 50px !important;
    min-width: 50px !important;

    &.facebook {
      background: #4363b6;
      color: var(--white-color) !important;
    }

    &.twitter {
      background: black;
      color: var(--white-color) !important;
    }

    &.insta {
      background: #e5497b;
      color: var(--white-color) !important;
    }

    &.whatsapp {
      background: #30b944;
      color: var(--white-color) !important;
    }
  }
}

.ai-container {
  padding: 1rem;
  background: var(--background-color-quaternary);
  border-radius: 0.5rem;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.33s, opacity 0.33s linear;
}

.ai-transition {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.ai-containerA {
  padding: 1rem;
  background: var(--background-color-quaternary);
  border-radius: 0.5rem;
  display: none;
  height: 0;
  overflow: none;
  padding: 0;
  border: none !important;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0.15s;
  -moz-transition: all 0.3s ease 0.15s;
  -o-transition: all 0.3s ease 0.15s;
  -ms-transition: all 0.3s ease 0.15s;
  transition: all 0.3s ease 0.15s;
}

.ai-transitionA {
  display: block;
  opacity: 1;
  height: 100px;
  padding: 1rem;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.white-space-nowrap {
  white-space: nowrap;
}

.accordion-extra {
  opacity: 1 !important;

  a {
    display: flex;

    &:focus {
      box-shadow: none !important;
    }

    .p-accordion-toggle-icon {
      flex-grow: 0;
    }

    .p-accordion-header-text {
      flex-grow: 1;
    }

    .header-price {
      display: flex;
      justify-content: space-between;
    }
  }

  ul {
    padding-left: 0;
    margin-bottom: 0;

    &.extra-item-list {
      li {
        list-style: none;
        line-height: 2rem;

        .extra-list-icon {
          font-size: 0.5rem;
          margin-right: 0.4rem;
        }
      }
    }
  }
}

.p-component {
  font-family: var(--custom-font-family) !important;
}

.image-prompt-textarea {
  height: 250px;
}

.auto-height {
  height: 65vh !important;
}

/*-----gallery----*/
.p-galleria {
  .p-galleria-item-nav {
    background: #4c4c4c4f !important;
    z-index: 1;
  }
}

.reason {
  height: 75px;
}

/*-----badge---*/
.custom-badge {
  font-weight: 500 !important;
  min-width: 80px !important;

  &.p-badge-success {
    color: hsl(from var(--green-color) h s calc(l - 8)) !important;
    background-color: hsl(from var(--green-color) h s calc(l + 52)) !important;
  }

  &.p-badge-danger {
    color: hsl(from var(--red-color) h s calc(l - 8)) !important;
    background-color: hsl(from var(--red-color) h s calc(l + 25)) !important;
  }

  &.p-badge-warning {
    color: hsl(from var(--primary-color) h s calc(l - 5)) !important;
    background-color: hsl(
      from var(--primary-color) h s calc(l + 35)
    ) !important;
  }

  &.p-badge-info {
    color: hsl(from var(--secondary-color) h s calc(l - 5)) !important;
    background-color: hsl(
      from var(--secondary-color) h s calc(l + 70)
    ) !important;
  }
}

/*-----Add On Tab----*/
.add-on-tab {
  li.p-highlight .p-tabview-nav-link {
    border-color: var(--primary-color) !important;
    color: var(--primary-color) !important;
  }

  .p-tabview-ink-bar {
    background-color: var(--primary-color) !important;
  }

  .p-tabview-panels {
    display: none !important;
  }
}

/*-----Prepend Input Group------*/
.custom-prepend-input {
  .input-group-prepend {
    .input-group-text {
      font-weight: 500;
      color: hsl(from var(--side-menu-icn-color) h s calc(l + 25));
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
}

/*-----Prime react fieldset----*/
.pr-fieldset {
  min-width: initial;
  margin: initial;
  border: initial;

  legend {
    padding: 0.85rem !important;
    float: initial;
    width: initial;
    margin-bottom: initial;
    font-size: initial;
    line-height: initial;
  }

  .p-fieldset-legend-text {
    font-weight: 500;
  }

  .capsule {
    padding: 0px 10px !important;
    font-size: 0.85rem !important;
  }

  .delete-icon {
    cursor: pointer;
    color: var(--green-color) !important;
  }
}

/*----survey form----*/
.survey-form {
  .form-control {
    padding: 0.671rem 0.75rem;
  }
}

/*-----Add on group in box ----*/
.add-on-group-box {
  background-color: hsl(from var(--white-color) h s calc(l - 5));
  border-radius: 4px;

  .empty-addon-group {
    .noAddon-icon {
      color: hsl(from var(--white-color) h s calc(l - 40));
    }

    span {
      color: hsl(from var(--white-color) h s calc(l - 40));
    }
  }

  hr {
    background-color: hsl(from var(--white-color) h s calc(l - 30));
  }
}

.addon-group-list {
  background-color: hsl(from var(--white-color) h s calc(l - 2));
}

/*-----Add on list in item view ----*/
.add-on-list {
  border: 0.75px solid hsl(from var(--white-color) h s calc(l - 10));
  border-radius: 0.5rem;
  //background-color: var(--background-color-quaternary);
  background-color: transparent;

  .border-line {
    border-bottom: 0.75px solid hsl(from var(--white-color) h s calc(l - 10));
  }

  .fw-bold {
    font-weight: 500 !important;
  }
}

.add-on-title {
  font-size: 1rem;
  font-weight: 600;
  line-height: 20px;
}

.p-accordion-header-link {
  padding: 1rem 1.25rem !important;
}

.add-category-container {
  .p-panel-header {
    label {
      font-weight: 500;
    }
  }

  .custom-panel {
    &:first-of-type {
    }

    &:last-of-type {
    }

    &:not(:first-of-type) {
      .p-panel-header {
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
      }
    }
  }
}

/*----branding---*/
.branding-upload {
  overflow: hidden;

  .ant-upload-select {
    overflow: hidden;
    height: 200px !important;
    width: 200px !important;
  }
}

.upload-container {
  .ant-upload-list-item-container {
    overflow: hidden;
    height: 200px !important;
    width: 200px !important;
  }
}

.ant-upload {
  &.ant-upload-select {
    &:hover {
      border-color: var(--primary-color) !important;
    }
  }
}

.dashboard-alt {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h5 {
    color: var(--primary-color) !important;
  }
}

.p-confirm-popup-accept {
  background: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;

  &:focus {
    box-shadow: 0 0 0 2px var(--white-color), 0 0 0 4px var(--primary-color),
      0 1px 2px 0 black !important;
  }
}

.p-confirm-popup-reject {
  color: var(--primary-color) !important;

  &:hover {
    background: hsl(from var(--primary-color) h s calc(l + 48)) !important;
  }

  &:focus {
    box-shadow: 0 0 0 2px var(--white-color), 0 0 0 4px var(--primary-color),
      0 1px 2px 0 black !important;
  }
}

.screen-img {
  width: 500px;
}

/*----prime react-----*/
.custom-dropdown .p-inputtext {
  padding: 0.47rem 0.75rem;
}

.custom-dropdown .p-dropdown:not(.p-disabled):hover {
  border-color: var(--primary-color) !important;
}

.custom-dropdown .p-dropdown:not(.p-disabled):focus {
  box-shadow: 0 0 0 0.2rem hsl(from var(--primary-color) h s calc(l + 45)) !important;
}

.custom-antd-time-picker {
  .ant-picker-time-panel-column::after {
    display: none !important;
  }
}

.p-selectbutton .p-button.p-highlight {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: var(--white-color);
}

.offcanvas-header {
  background: var(--background-color-quaternary);
}

.holiday-list-card {
  background-color: var(--background-color-quaternary);
  border-radius: 0.25rem;

  .holiday-name {
    color: hsl(from var(--secondary-color) h s calc(l + 48)) !important;
  }

  .custom-badge {
    min-width: 50px !important;
    white-space: nowrap;
  }

  .danger {
    color: var(--danger-color);
  }

  .success {
    color: var(--green-color);
  }
}

.formik-error {
  color: var(--danger-color);
  font-size: 0.9rem;
}

/*---prime react update---*/
.p-tabview-nav-content {
  dl,
  ol,
  ul {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.p-datatable {
  .p-datatable-tbody {
    tr {
      td {
        font-size: 0.9rem;
      }
    }
  }
}

.p-button-rounded {
  border-radius: 50% !important;

  &:focus {
    box-shadow: 0 0 0 0.2rem hsl(from var(--primary-color) h s calc(l + 40)) !important;
  }
}

.p-button {
  &:hover {
    background: hsl(
      from var(--primary-color-2) h s calc(l - 7)
    ); //for dark background
  }
}

.btn-prime-primary-outline {
  &:hover {
    background: hsl(
      from var(--primary-color-2) h s calc(l + 40)
    ); //for light background
  }
}

.p-button-outlined {
  &:hover {
    background: hsl(
      from var(--primary-color-2) h s calc(l + 40)
    ); //for light background
  }
}

.p-button-icon-only {
  &:hover {
    background: hsl(
      from var(--primary-color-2) h s calc(l + 42)
    ); //for light background
  }
}

.next-week-btn {
  &.p-button-icon-only {
    &:hover {
      background: hsl(
        from var(--primary-color-2) h s calc(l + 7)
      ); //for light background
    }
  }
}

.publish-button {
  background-color: var(--success-color);
  border-color: var(--success-color) !important;

  &:hover {
    background: hsl(
      from var(--success-color) h s calc(l - 10)
    ) !important; //for light background
  }
}

.card-header {
  .p-button {
    //padding: 0.55rem 1.25rem;
  }

  .publish-button {
    // padding: 0.75rem 1.25rem;
  }
}

.p-scrolltop {
  border-radius: 50% !important;
}

.scroll-top {
  position: fixed !important;
}

.form-prime-checkbox {
  .p-checkbox-box:hover,
  .p-checkbox-box.p-focus {
    border-color: var(--primary-color) !important;
    box-shadow: 0 0 0.2rem var(--primary-color) !important;
  }

  .p-checkbox-box.p-highlight {
    border-color: hsl(from var(--primary-color) h s calc(l + 70)) !important;
    background: var(--primary-color) !important;
  }
}

/*-------login---*/
.login-link-msg {
  .p-message {
    font-size: 0.9rem !important;

    &.p-message-success {
      //background: hsl(from var(--primary-color) h s calc(l + 40));
      border: none !important;

      .p-message-icon {
        display: none;
      }

      .p-message-close {
        padding: 10px;
        margin-left: 1rem;
      }
    }
  }
}

#GuestMain {
  background: var(--white-color);
  position: relative;
}

.container-custom {
  margin-left: 10%;
  margin-right: 10%;
}

body {
  // background: var(--white-color);
}

#GuestMain {
  .bg {
    background-color: var(--white-color);
  }
}

.image-menu-sticky {
  background-color: var(--white-color);
  box-shadow: 0 0px 10px #ccccccbd;
}

.scroll-menu-container .nav-scroller-nav .nav-scroller-item img {
  width: auto;
  height: 100%;
  border-radius: 2px;
  display: inline-block;
  object-fit: cover;
  object-position: center center;
}

.scroll-menu-container .nav-scroller-nav .nav-scroller-item {
  background: var(--white-color);
  border: 3px solid #ededec;
}

#GuestMain .item-list-main.shadow-custom {
  box-shadow: none !important;
  border: 1px solid #dfdfdf;
}

#GuestMain .item-list-main:hover {
  background-color: var(--white-color);
  //border-color: rgb(232, 232, 232);
  box-shadow: rgba(28, 28, 28, 0.12) 0px 0rem 2rem 0rem !important;
}

.card-header {
  &.card-breadcrumb {
    background: var(--white-color) !important;
    position: sticky;
    top: 0;
    z-index: 2;
  }
}

.item-view-bg {
  &.shadow-sm {
    box-shadow: 0px 0px 6px 0px #cccccca8 !important;
  }
}

.increase-font-container {
  display: flex;
  cursor: pointer;

  .increaseFont {
    .increase-button {
      padding: 0.5rem;
      height: 30px;
      width: 30px;
      background-color: hsl(from var(--primary-color) h s calc(l + 40));
      color: var(--secondary-color);
      font-weight: 600;
      margin-right: 5px;
      margin-left: 5px;
      display: flex;
      align-items: center;
      font-size: 0.8rem;
      justify-content: center;

      &:hover {
        background-color: hsl(from var(--primary-color) h s calc(l + 30));
      }

      &:focus {
        box-shadow: 0 0 0 2px var(--white-color),
          0 0 0 4px hsl(from var(--primary-color) h s calc(l + 30)),
          0 1px 2px 0 black;
      }
    }
  }
  &.icon-only-link {
    font-size: 1.3rem;
    .notification-icon-badge {
      position: absolute;
      left: 10px;
      top: 0px;
      z-index: 99;
    }
  }
  &:hover {
    color: hsl(from var(--font-color) h s calc(l + 40)); //for light background
  }
}

@media (max-width: 1398.98px) {
  .container-custom {
    margin-left: 7%;
    margin-right: 7%;
  }
}

@media (min-width: 1200px) {
  .container {
    padding-left: 5%;
    padding-right: 5%;
    max-width: 100%;
  }

  .login {
    .container {
      max-width: 1140px;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media (min-width: 1400px) {
  .container {
    padding-left: 10%;
    padding-right: 10%;
    max-width: 100%;
  }

  .login {
    .container {
      max-width: 1320px;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media (max-width: 1199.98px) {
  .container-custom {
    margin-left: 2%;
    margin-right: 2%;
  }
}

/*----gallery----*/
.no-gallery-image {
  //height: 100%;
  display: flex;
  align-items: baseline;

  img {
    width: 100%;
  }

  &.gallery-slider-outer {
    &.bg {
      background: var(--white-color) !important;
    }
  }
}

/*----animation---*/
.nav-link {
  .sb-nav-link-icon {
    transform: translateX(1) !important;
    transition: transform 0.3s ease !important;
  }

  .menu-text {
    transform: translateX(1);
    transition: transform 0.3s ease;
  }

  .sb-sidenav-collapse-arrow {
    transform: translateX(-5px) rotate(-90deg) !important;
    transition: all 0.3s ease !important;
  }

  &:hover {
    .sb-nav-link-icon {
      transform: translateX(7px);
    }

    .sb-sidenav-collapse-arrow {
      transform: translateX(2px) rotate(-90deg) !important;
    }

    .menu-text {
      transform: translateX(7px);
    }
  }
}

.scrollableDiv {
  height: 45vh;
  overflow-y: scroll;
}

.availability-form-accordion {
  .accordion-body {
    padding: 0.5rem;
  }

  .accordion-button:focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 0.25rem rgb(251 170 7 / 40%);
  }

  .availability-header {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .form-switch {
    .form-check-input {
      height: 1.45em;
      width: 2.45em;
    }
  }

  .custom-group-box {
    background-color: hsl(from var(--white-color) h s calc(l - 5));
    border-radius: 4px;
  }

  .add-p-bg {
    //background-color: var(--primary-color-2);
    background-color: hsl(from var(--primary-color-2) h s calc(l + 35));
    border-radius: 5px;
  }
}

.p-datatable-availability {
  .p-datatable-thead {
    .word-break-grid {
      justify-content: center;
    }

    tr {
      border-color: #efefef !important;
      border-style: solid;
      border-width: 1px;
    }
  }

  .available-template {
    .availability-block {
      position: relative;
      border-radius: 3px;

      .text-reason {
        font-size: 0.65rem;
      }

      .mandate-block {
        position: absolute;
        background-color: hsl(from var(--primary-color) h s calc(l + 35));
        top: 8px;
        width: 100%;
        left: 50%;
        height: 15px;
        display: flex;
        transform: translate(-50%, -50%);
        justify-content: center;
        align-items: center;

        i,
        span {
          color: var(--primary-color) !important;
        }
      }
    }

    .availability-active {
      background-color: hsl(from var(--green-color) h s calc(l + 50));
    }

    .availability-inactive {
      background-color: hsl(from var(--red-color) h s calc(l + 25));
    }

    .availability-holiday {
      background-color: hsl(from var(--secondary-color) h s calc(l + 75));
    }

    .availability-mandate {
      background-color: hsl(from var(--primary-color) h s calc(l + 45));
    }
  }

  tr:not(.p-highlight):not(.p-datatable-emptymessage):hover {
    background: var(--white-color) !important;
  }

  .bg-sticky {
    background-color: var(--white-color) !important;
  }

  th.word-break-grid,
  td.word-break-grid {
    // border: 1px solid rgb(222 226 230 / 35%) !important;
  }

  .availability-names {
    span {
      &:last-child {
        font-size: 0.85rem;
      }
    }
  }

  .p-datatable-scrollable-table > .p-datatable-thead {
    top: -1px !important;
  }

  .p-rowgroup-header {
    top: 80px !important;

    td {
      padding: 0px !important;
    }

    .availability-shift-header {
      min-height: 3.5rem;
      background: hsl(from var(--primary-color) h s calc(l + 35));
      padding-inline-start: 0.5rem;
      font-weight: 500;
      font-size: 1rem;
    }
  }

  .p-header-first-column {
    .p-column-header-content {
      width: 100%;

      .p-column-title {
        width: 100%;
      }
    }
  }
}

.regular-availability-row {
  display: flex;
  flex-direction: row;
  //background: hsl(from var(--primary-color) h s calc(l + 45));
  //border: 0.2rem solid hsl(from var(--primary-color) h s calc(l + 40));

  --adjusted-lightness: calc(
    var(--primary-color-lightness) + var(--lightness-adjustment-5)
  );
  --adjusted-lightness-2: calc(
    var(--primary-color-lightness) + var(--lightness-adjustment-4)
  );
  /* Apply the adjusted HSL background color */
  background-color: hsl(
    var(--primary-color-hue),
    var(--primary-color-saturation),
    var(--adjusted-lightness)
  );
  border: hsl(
    var(--primary-color-hue),
    var(--primary-color-saturation),
    var(--adjusted-lightness-2)
  );

  .border-right {
    border-right: 0.2rem solid hsl(from var(--primary-color) h s calc(l + 40));
  }

  div {
    flex-grow: 1;
    flex-basis: 0;

    p {
      margin: 0;

      &:first-child {
        font-weight: bold;
      }

      &:nth-child(2) {
        font-weight: 500;
      }
    }

    .success {
      color: var(--green-color);
    }

    .danger {
      color: var(--danger-color);
    }
  }
}

.availability-border {
  border-bottom: 1px solid (hsl(from var(--white-color) h s calc(l - 15)));
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.custom-week-picker {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;

  .ant-picker-suffix {
    svg {
      width: 1.35em !important;
      height: 1.35em !important;
    }
  }

  .ant-picker-active-bar {
    background: none !important;
  }
}

.mandatory-days-table {
  .p-datatable-thead {
    z-index: 0 !important;
  }
}

/*---shift settings---*/
.shift-card {
  background-color: var(--white-color);
  border: 1px solid hsl(from var(--white-color) h s calc(l - 6));
  border-radius: 0.3rem;
}

.edit-card {
  background: var(--background-color-quaternary);
}

.shift-delete {
  background: hsl(from var(--red-color) h s calc(l + 30));
  color: var(--green-color);
  padding: 0.7rem;
  border-radius: 50%;
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;

  &:hover {
    background: hsl(from var(--red-color) h s calc(l + 25));
    cursor: pointer;
  }
}

.shift-add-container {
  i {
    color: var(--primary-color);
    font-size: 1.5rem;
  }
}

.shift-save-area {
  position: absolute;
  bottom: 1px;
  left: 0;
  right: 0;
}

.shift-sticky {
  position: sticky;
  top: 0rem;
}

.rounded-background {
  //margin-inline-end: 1.5rem;
  background: var(--white-color);
  padding: 8px 10px;
  border-radius: 50%;
  margin-right: 0.5rem;

  .edit {
    margin: 2px 0px 3px 2px;
    color: var(--success-color);
    font-size: 1.2rem;
  }

  &:nth-last-child(1) {
    margin-right: 0;
  }
}

.data-view-card {
  border: 1px solid #e5e5e5;
  box-shadow: 0 1px 5px 1px #e5e5e5;

  .p-card-body {
    padding: 0px !important;
  }

  .p-card-content {
    padding: 10px;

    .view-header,
    .font-bold {
      font-weight: 600;
    }

    div {
      &:nth-child(2) {
        font-size: 0.85rem;
      }
    }

    .view-footer {
      border-top: 1px solid #e5e5e5;

      .btn {
        min-width: auto !important;
      }
    }
  }
}

.p-dataview-timeoff {
  margin-top: 10px;

  .p-paginator-element {
    min-width: 2rem;
    height: 2rem;
  }
}

/*--inActive--*/
.inactive-height {
  align-items: center;
  display: flex;
  justify-content: center;
  height: calc(100vh - 60px) !important;
  color: #898989;
  font-weight: 500;
  flex-direction: column;
  line-height: 25px;
}

@media (max-width: 420.98px) {
  .inactive-height {
    img {
      width: 80%;
    }
  }
}

.preferred-language-dropdown {
  .p-dropdown-label {
    padding: 0.38rem 0.65rem;
    color: #000;
  }
}

.full-screen-dropdown-panel,
.p-dropdown-panel {
  z-index: var(--custom-dropdown-z-index, 1042) !important;
}

/*----font slider---*/

.font-slider {
  z-index: 1040 !important;
  border: 1px solid var(--white-color);
  margin-top: 0.5rem;

  .ant-slider {
    margin-top: 20px;
  }

  &.p-menu {
    width: 210px !important;
    padding: 3px 12px !important;
  }

  .ant-slider-horizontal .ant-slider-rail {
    height: 4px;
  }

  .ant-slider .ant-slider-dot {
    width: 8px;
    height: 8px;
  }

  .ant-slider-horizontal .ant-slider-dot {
    inset-block-start: -2px;
  }

  .ant-slider-horizontal .ant-slider-handle {
    inset-block-start: 1px;
  }

  .ant-slider .ant-slider-handle::after {
    width: 10px;
    height: 10px;
  }

  .ant-slider .ant-slider-handle {
    width: 10px;
    height: 10px;
  }

  .ant-slider-mark {
    display: inline-block !important;
    visibility: visible !important;
    font-weight: bold;
    font-size: 12px;
    margin-top: 6px;
  }

  .ant-slider-horizontal .ant-slider-step {
    height: 4px;
  }

  .ant-slider-handle::after {
    box-shadow: 0 0 0 3px var(--primary-color) !important;
  }
}

.ant-picker-time-panel-cell-selected {
  .ant-picker-time-panel-cell-inner {
    background: #e6f4ff !important;
  }
}

.font-slider-button {
  height: 32px;
  width: 32px;
  margin-top: 3px;
  font-size: 14px;
}

/*---gallery---*/
.gallery-slider-outer .p-galleria-thumbnail-container img {
  max-width: 70px !important;
  width: 70px !important;
}

.dummy-style {
  padding: 1rem;
  background-color: lightcyan;
  border: solid 2px lightgrey;
}

.dummy-style-2 {
  padding: 1rem;
  background-color: lightcyan;
  border: solid 2px lightgrey;
  justify-content: center;
  align-items: center;
}

.logout-container {
  button {
    font-size: 14px;
    padding: 5.25px 10.5px;
  }

  .dropdown-menu {
    min-width: 140px;
    font-size: 14px;

    a {
      padding: 3.5px 14px;
      font-size: 14px;
    }

    .my-2 {
      margin-top: 7px !important;
      margin-bottom: 7px !important;
    }

    .py-3 {
      padding-top: 14px !important;
      padding-bottom: 14px !important;
    }
  }
}

.ham-container {
  .tg-btn-clps {
    //padding-left: 14px !important;
    padding-left: 17px !important;
    margin-right: 14px !important;

    .btn {
      padding: 8.4px 2.8px !important;
    }
  }
}
@media (max-width: 768.98px) {
  .ham-container {
    .tg-btn-clps {
      padding-left: 5px !important;
      margin-right: 0px !important;
    }
  }
}

/*---error page----*/
.apology-text {
  color: var(--green-color);
  line-height: calc((var(--normal-title-sz)) * 1.5);
  font-size: var(--normal-title-sz);
}

/*---blur image----*/
/*.static-img{
  position: absolute;
  z-index: 1;
}
.blur-img{
  filter: blur(10px);
  height: auto;
  width: 100% !important;
  z-index: 0;
}
.item-img-main-container{
  position: relative;
  overflow: hidden;
}*/
.hire-date-input {
  .react-datepicker-wrapper {
    width: 100%;
  }
}

.zoom-slider {
  .pi {
    color: var(--primary-color);
    font-size: 1.45rem;
  }

  .p-slider {
    .p-slider-range {
      background: var(--primary-color);
    }

    .p-slider-handle {
      border: 2px solid var(--primary-color);
    }
  }
}

.small-text {
  font-size: var(--small-font-sz);
}

.p-dataview-customers {
  &.p-paginator {
    padding: 1rem 0rem;
  }
}

@media (max-width: 800px) {
  .regular-availability-row {
    flex-direction: column;

    div {
      flex-direction: row !important;
      justify-content: space-between !important;
      border-right: 0px !important;

      &:not(:first-child) {
        border-top: 0.2rem solid var(--primary-color-2);
      }
    }
  }
}

.edit-profile {
  .profile-bg {
    background-color: transparent !important;

    .profile-container {
      .p-avatar {
        width: 10rem !important;
        height: 10rem !important;
      }
    }
  }
}

.primary-title-bg {
  background: hsl(from var(--primary-color) h s calc(l + 40));
  padding: 0.65rem;
  border-radius: 3px;
  font-size: 1.2rem;
}

.festival-form {
  .festival-dropdown {
    //width: 100%;
    &:not(.p-disabled).p-focus,
    &:not(.p-disabled):hover {
      box-shadow: none !important;
      border-color: var(--primary-color);
    }
  }

  .preview-icon {
    border-radius: 0px 5px 5px 0px !important;
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    min-width: 95px;
    padding: 0.5rem 0.5rem;

    &:focus {
      box-shadow: none !important;
    }

    &.p-button {
      &:hover {
        transition: none !important;

        .p-button-icon {
          &.p-button-icon-right {
            left: 0 !important;
          }
        }
      }
    }
  }

  .festival-page-selection {
    padding: 5px;
    background: hsl(from var(--font-color) h s calc(l + 80));
    border-radius: 5px;

    .p-button.p-button-icon-only {
      width: 2.5rem;
      padding: 0.5rem 0;
      border-radius: 5px !important;
      background: var(--white-color);
      border: 1px var(--white-color);
      color: var(--secondary-color);
    }
  }
}

.general-settings-dropdown-text {
  color: hsl(from var(--secondary-color) h s calc(l + 40));
}

.search-filter-btn {
  &.p-button {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);

    &:enabled {
      &:hover {
        background: hsl(from var(--primary-color) h s calc(l - 2));
        color: var(--white-color);
        border-color: hsl(from var(--primary-color) h s calc(l - 2));
      }
    }

    &:focus {
      box-shadow: 0 0 0 2px var(--white-color),
        0 0 0 4px hsl(from var(--primary-color) h s calc(l + 20));
    }
  }
}

.block-inactive {
  filter: grayscale(100%);
}

/*----news and events---*/
.background-switch-container {
  padding: 5px 10px;
  background: hsl(from var(--font-color) h s calc(l + 80));
  border-radius: 5px;

  .icon-container-switch {
    width: 2.5rem;
    padding: 0.5rem 0;
    border-radius: 5px !important;
    background: var(--white-color);
    border: 1px var(--white-color);
    color: var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 500;
  }
}

.select-recurrent {
  color: var(--green-color);
  //font-weight: 500;
  padding: 0 5px;
  font-size: 1rem;
}

.width-50 {
  width: 50px;

  .p-inputtext {
    width: 100%;
  }

  .p-dropdown {
    width: 100%;
  }
}

.width-90 {
  width: 90px;

  .p-inputtext {
    width: 100%;
  }

  .p-dropdown {
    width: 100%;
  }
}

.width-110 {
  width: 145px;

  .p-inputtext {
    width: 100%;
  }

  .p-dropdown {
    width: 100%;
  }
}

.recurrence-bg {
  background-color: var(--white-color);
  padding: 6px;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--primary-color);
  background: var(--primary-color);
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.2rem hsl(from var(--primary-color) h s calc(l + 40));
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: var(--primary-color);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--primary-color);
}

/*----menu slider---*/

.embla {
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 50%;
}

.embla__viewport {
  overflow: hidden;
  position: relative;
}

.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
  position: relative;
}

.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}

.embla__slide__number {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
}

.slider-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  background-color: #0000008c;
  border: none;

  i {
    opacity: 1;
    background-color: var(--white-color);
    border-radius: 50%;
    font-size: 1.5rem;
  }
}

.embla__button--prev {
  left: 0px;
}

.embla__button--next {
  right: 0px;
}

/*---settings---*/
.settings-tab {
  &.p-tabview {
    .p-tabview-nav {
      .p-tabview-ink-bar {
        background-color: var(--primary-color) !important;
      }

      li {
        &.p-highlight {
          .p-tabview-nav-link {
            border-color: var(--primary-color) !important;
          }
        }
      }
    }
  }

  &.hide-tab-arrow {
    .p-tabview-nav-container {
      .p-tabview-nav-btn {
        visibility: hidden;
      }
    }
  }
}

.custom-panel {
  .p-panel-title {
    width: 100%;
  }

  .custom-primary-header {
    display: flex;
  }
}

/*---event recurrence--*/
.every-month-main {
  border-bottom: 1px dashed hsl(from var(--secondary-color) h s calc(l + 70));
  padding-bottom: 0.9rem;
}

.line-height-0 {
  line-height: 0;
}

.p-button-primary-outline {
  background: transparent;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);

  &:hover {
    background-color: hsl(from var(--white-color) h s calc(l - 5));
  }
}

.notification-panel {
  // overflow-y: auto;
  .notification-list {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-radius: 5px;

    &.unread {
      //background: hsl(from var(--primary-color) h s calc(l + 45));

      --adjusted-lightness: calc(
        var(--primary-color-lightness) + var(--lightness-adjustment-5)
      );
      /* Apply the adjusted HSL color */
      background-color: hsl(
        var(--primary-color-hue),
        var(--primary-color-saturation),
        var(--adjusted-lightness)
      );

      &:hover {
        // background: hsl(from var(--primary-color) h s calc(l + 40));

        --adjusted-lightness: calc(
          var(--primary-color-lightness) + var(--lightness-adjustment-4)
        );
        /* Apply the dynamically adjusted HSL color */
        background-color: hsl(
          var(--primary-color-hue),
          var(--primary-color-saturation),
          var(--adjusted-lightness)
        );
      }
    }

    &.read {
      background: hsl(from var(--secondary-color) h s calc(l + 80));

      &:hover {
        background: hsl(from var(--secondary-color) h s calc(l + 75));
      }
    }

    .notification-type {
      background: var(--white-color);
      display: flex;
      padding: 0.35rem;
      border-radius: 50%;

      i {
        font-size: 20px;
        font-weight: 500;
        color: hsl(from var(--primary-color) h s calc(l + 20));
        padding: 3px;
      }
    }

    .notification-message {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .notification-action {
      display: flex;
      align-items: center;
    }
  }

  &.notification-center {
    overflow-y: auto;
  }
}

.f-600 {
  font-weight: 600;
}

.notification-icon-panel {
  position: relative;
  cursor: pointer;

  .notification-icon-badge {
    position: absolute;
    left: 25px;
    top: 5px;
    z-index: 99;
  }

  .notification-icon-button {
    height: 32px;
    width: 32px;
    margin-top: 3px;
    font-size: 14px;
  }

  &.icon-only-link {
    font-size: 1.3rem;
    .notification-icon-badge {
      position: absolute;
      left: 10px;
      top: 0px;
      z-index: 99;
      // left: 25px;
      // position: absolute;
      // top: 5px;
      // z-index: 99;
    }

    &:hover {
      color: hsl(
        from var(--font-color) h s calc(l + 40)
      ); //for light background
    }
  }
}

.notification-popup-panel {
  z-index: 9999 !important;
  width: 350px;
  //height: 490px;
  min-height: auto;

  &::before {
    content: none;
  }

  .p-overlaypanel-content {
    padding: 0px;
    height: 100%;

    .notification-panel {
      padding: 0.5rem !important;
    }

    .notification-button-read {
      background: hsl(from var(--secondary-color) h s calc(l + 78));
      color: var(--secondary-color);
      border: hsl(from var(--secondary-color) h s calc(l + 40));
      border-radius: 5px !important;
      padding: 0px 5px;

      .p-button-label {
        font-weight: normal;
        font-size: 11px;
      }
    }

    .notification-button-clear {
      background: hsl(from var(--red-color) h s calc(l + 30));
      color: hsl(from var(--red-color) h s calc(l + 5));
      border: hsl(from var(--red-color) h s calc(l + 30));
      border-radius: 5px !important;
      padding: 0px 5px;

      .p-button-label {
        font-weight: normal;
        font-size: 10px;
      }
    }

    .notification-button-footer {
      // background: hsl(from var(--primary-color) h s calc(l + 5));
      color: var(--white-color);
      border: hsl(from var(--primary-color) h s calc(l + 5));

      --adjusted-lightness: calc(
        var(--primary-color-lightness) + var(--lightness-adjustment-1)
      );
      /* Apply the dynamically adjusted HSL color */
      background-color: hsl(
        var(--primary-color-hue),
        var(--primary-color-saturation),
        var(--adjusted-lightness)
      );

      .p-button-label {
        font-weight: normal;
      }

      &:hover {
        background: hsl(from var(--primary-color) h s calc(l + 1));
      }
    }
  }

  .notification-wrapper {
    max-height: 310px;
  }
}

/*----prime react button---*/
.p-button {
  &:hover {
    transition: all 0.5s ease;

    .p-button-icon {
      &.p-button-icon-right {
        left: 2px;
        position: relative;
        transition: all 0.5s ease;
      }

      &.p-button-icon-left {
        right: 2px;
        position: relative;
        transition: all 0.5s ease;
      }
    }
  }
}

/*----tooltip---*/
.p-tooltip {
  .p-tooltip-text {
    box-shadow: none;
  }
}

/*--prime react popover---*/
.mlx-overlay-popover-custom {
  width: 270px;
  z-index: 1055 !important;

  .overlay-popover-custom-body {
    font-size: 0.9rem;
  }

  .popover-heading {
    font-weight: 600;
    padding: 0.5rem 0.8rem;
    background-color: hsl(from var(--white-color) h s calc(l - 5));
    border-bottom: 1px solid hsl(from var(--white-color) h s calc(l - 8));
  }

  .overlay-popover-custom-body {
    padding: 0.8rem;
  }

  .p-overlaypanel-content {
    padding: 0;
  }
}

// (Ai prompt) - START
.quill-editor-for-ai {
  font-size: 1rem !important;
  background-color: white;
  border-radius: 5px;
  border: 1px solid var(--primary-color) !important;
  padding: 1rem 0 1rem 0;
}

// (Ai prompt) - END

/*----menu scroll---*/
.menu-config-menu {
  .embla__container {
    padding-left: 3rem;
  }
}

/*---primereact tooltip---*/
.p-tooltip {
  .p-tooltip-text {
    padding: 0.2rem 0.5rem;
    font-size: 0.9rem;
  }
}

.w-max-content {
  width: max-content !important;
}

/*---toast for offline notification--*/
.offline-toast {
  color: var(--green-color);

  .offline-title {
    font-weight: 600;
  }

  .network-detail {
    color: hsl(from var(--secondary-color) h s calc(l + 40));
  }
}

.online-toast {
  color: var(--green-color);

  .online-title {
    font-weight: 600;
  }

  .network-detail {
    color: hsl(from var(--secondary-color) h s calc(l + 40));
  }
}

.p-toast {
  &.network-toast {
    max-width: 30rem;

    .p-toast-message-content {
      justify-content: space-between;
    }
  }
}

.offline-toast-wrapper {
  &.p-toast-message-error {
    background: var(--white-color);
  }
}

.online-toast-wrapper {
  &.p-toast-message-success {
    background: var(--white-color);

    .p-toast-icon-close {
      color: hsl(from var(--secondary-color) h s calc(l + 25));
    }
  }
}

.availability-shift-tabs {
  .p-tabview-nav-container {
    width: 100%;

    .p-tabview-nav-content {
      .p-tabview-nav {
        border: none;

        .p-unselectable-text {
          margin-right: 10px;

          .p-tabview-nav-link {
            padding: 10px;
            margin: 0px;
            border: 1px solid var(--primary-color); //hsl(from var(--primary-color) h s calc(l + 40));
            border-radius: 30px;
            color: hsl(from var(--primary-color) h s calc(l + 10));
            font-weight: normal;
          }
        }

        .p-highlight {
          .p-tabview-nav-link {
            background-color: hsl(
              from var(--primary-color) h s calc(l + 45)
            ) !important;
          }
        }

        .p-tabview-ink-bar {
          display: none;
        }

        .p-tabview-title {
          font-size: 0.865rem;
        }
      }
    }

    .p-tabview-nav-btn.p-link {
      color: var(--primary-color);
    }
  }

  .p-tabview-panels {
    display: none;
  }
}

.availability-header {
  position: relative;

  .availability-progress-bar {
    .p-progressbar-value {
      background: hsl(from var(--primary-color) h s calc(l + 10)) !important;
    }
  }
}

.availability-date-picker {
  padding: 5px 11px;

  &:hover,
  &:focus-within {
    border: 1px solid var(--primary-color);
  }
}

.availability-role-dropdown {
  &:hover {
    border: 1px solid var(--primary-color);
  }

  &:not(.p-disabled).p-focus {
    box-shadow: none;
    border: 1px solid var(--primary-color);
  }

  .p-inputtext {
    padding: 5.5px 11px;
    min-width: 135px;
  }
}

.availability-role-dropdown-panel {
  .p-dropdown-filter-container {
    .p-inputtext {
      &:enabled:hover,
      &:focus:not(:hover) {
        box-shadow: none;
        border-color: var(--primary-color);
      }
    }
  }
}

.ant-picker-week-panel-row-selected {
  .ant-picker-cell:before {
    background: var(--primary-color) !important;
  }
}

.ant-picker-cell-today {
  .ant-picker-cell-inner::before {
    border: 1px solid hsl(from var(--primary-color) h s calc(l + 40)) !important;
  }
}

/*----dashboard card----*/
.dashboard-schedule-scroll {
  max-height: 195px;
  overflow-y: auto;
}

.staff-schedule-card-no-reservation {
  //background-color: hsl(from var(--red-color) h s calc(l + 30));
  //color: hsl(from var(--red-color) h s calc(l + 10));

  --adjusted-lightness: calc(
    var(--red-color-lightness) + var(--lightness-adjustment-red-3)
  );
  --adjusted-lightness-2: calc(
    var(--red-color-lightness) + var(--lightness-adjustment-red-1)
  );
  /* Apply the adjusted HSL color */
  background-color: hsl(
    var(--red-color-hue),
    var(--red-color-saturation),
    var(--adjusted-lightness)
  );
  color: hsl(
    var(--red-color-hue),
    var(--red-color-saturation),
    var(--adjusted-lightness-2)
  );
  border-radius: 3px;
  font-size: 0.85rem;
}

.dashboard-schedule-card {
  > .p-card-body {
    > .p-card-content {
      padding-bottom: 0;
    }
  }
}

.card {
  .p-card-title {
    font-size: var(--button-font-sz);
    font-weight: 600;
    position: sticky;
    top: 0px;
    z-index: 9;
    background: var(--white-color);
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    --bs-gutter-x: 0.5rem;
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }

  .p-card-body {
    padding-top: 0px;
  }
}

.dashboard-status {
  font-size: 0.7rem;
}

.dashboard-notification-card {
  img {
    // margin-top: calc(50% - 119px);
    width: 85%;
  }

  .p-card-title {
    position: relative;
  }
}

.dashboard-badge {
  background-color: hsl(from var(--red-color) h s calc(l + 25)) !important;
  color: var(--red-color);
}

.dashboard-btn {
  //background-color: hsl(from var(--primary-color) h s calc(l + 45)) !important;
  color: var(--primary-color) !important;
  border-color: hsl(from var(--primary-color) h s calc(l + 20)) !important;
  min-width: 90px !important;
  border-radius: 30px;
  background-color: transparent !important;

  &:hover {
    background-color: hsl(
      from var(--primary-color) h s calc(l + 45)
    ) !important;
    color: var(--primary-color) !important;
  }
}

.upcoming-schedule {
  .p-card-content {
    padding: 0;
  }

  &.min-hgt-195 {
    min-height: 195px;
  }
}

.success-text {
  color: var(--green-color);
}

.accordion-no-children {
  a {
    svg {
      visibility: hidden;
    }
  }
}

.delete-event-confirm-popup {
  .p-confirm-popup-content {
    padding: 1.3rem 1.1rem;

    .p-confirm-popup-message {
      margin-left: 10px;
    }
  }

  .p-confirm-popup-footer {
    text-align: right;
    padding: 0rem 1rem 0.9em 1rem;

    button {
      padding: 0.3rem 1.5rem;
    }

    .p-confirm-popup-accept {
      border-radius: 4px;
    }
  }
}

.accordion-no-children {
  a {
    svg {
      visibility: hidden;
    }
  }
}

.rdw-dropdown-carettoopen {
  right: 6% !important;
}

.rdw-dropdown-selectedtext {
  span {
    margin-right: 5px;
  }
}

/*---dashboard--*/
.dashboard-widget-height {
  min-height: 200px;
  max-height: 350px !important;
  overflow-y: auto;
}

.dashboard-timeoff-card {
  .p-card-title {
    position: relative;
  }
}

.dashboard-timeoff-card .p-card-title + .p-card-content {
  padding: 0 !important;
}

.dashboard-notification-card .p-card-title + .p-card-content {
  padding: 0 !important;
}

.dashboard-notification-card {
  .p-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .p-card-title {
    display: flex;
    flex-grow: 0;
    justify-content: space-between;
  }

  .p-card-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}

/*---badge---*/
.custom-badge-primary {
  background-color: var(--primary-color);
  font-weight: 500;
}

.custom-badge-danger {
  font-weight: 500;
}

.choose-food-type {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;

  .margin {
    margin-left: 0.5rem;
  }
}

/*---search---*/
.has-search {
  input {
    &.form-control {
      padding-right: 2.5rem !important;
    }
  }
}

/**----close--*/
.add-time {
  color: var(--primary-color);
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: hsl(from var(--primary-color) h s calc(l - 5));
  }
}

/*---antD----*/
:where(.css-dev-only-do-not-override-1rqnfsa).ant-picker-outlined:focus,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-picker-outlined:focus-within {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 0.2rem hsl(from var(--primary-color) h s calc(l + 40)) !important;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-picker-outlined:hover {
  border-color: var(--primary-color) !important;
}

.staff-schedule-panel {
  &.p-tabview {
    .p-tabview-panels {
      // padding: 1.25rem 0rem !important;
      padding: 0rem !important;
    }
  }
}

.staff-schedule-panel {
  .p-tabview-nav-container {
    .p-tabview-nav-btn {
      display: none;
    }
  }
}

.availability-dropdown {
  width: 240px;
}

.user-img-availability {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  margin-right: 5px;
}

.product-description {
}
.overflow-y-auto {
  overflow-y: auto;
  overflow-x: hidden;
}

.ant-drawer {
  font-family: var(--custom-font-family) !important;
}

.ant-drawer-header {
  padding: 16px 14px !important;
}

.ant-drawer-title {
  font-size: 1.25rem !important;
}

.ant-drawer-body {
  padding: 14px !important;
  padding-bottom: 4rem !important;
}
.staff-availability-chip {
  &.available-all-day {
    .availability-settings-icon {
      color: var(--green-color) !important;
      margin-left: 0.3rem;
      font-size: 1rem;
    }
  }
}

.staff-availability-chip {
  &.not-available {
    .availability-settings-icon {
      color: var(--red-color) !important;
      margin-left: 0.3rem;
      font-size: 1rem;
    }
  }
}
.staff-availability-chip {
  .availability-settings-icon {
    color: var(--primary-color) !important;
    margin-left: 0.3rem;
    font-size: 1rem;
  }

  &:hover {
    background: hsl(from var(--primary-color) h s calc(l + 30));
  }
}

.scheduler-availability-switch {
  margin-top: 5px;
  height: 35px;
  background-color: #f5f5f5;
  align-items: center;
  align-content: center;
  padding: 5px 10px;

  .form-switch {
    .form-check-input {
      height: 1.45em;
      width: 2.45em;
    }
  }
}

.map-container {
  position: relative;

  .map-search {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 9;
  }
  .gmnoprint {
    display: none;
  }
}

.form-control {
  &.antD-shiftcard {
    padding: 0.686rem 0.75rem;
  }
}

/*---shift copy---*/
.ant-picker-dropdown {
  z-index: 1055;
}

.mlx-fullscreen {
  position: fixed;
  width: 100vw;
  height: 100vh !important;
  top: 0;
  left: 0;
  z-index: 1040;
  padding: 0px !important;
  margin: 0px !important;
}

.ant-popover {
  z-index: 1041 !important;
}

.border-primary {
  border-color: var(--primary-color) !important;
}

.p-multiselect-panel {
  z-index: 1600 !important;
}

.select-staff-checkbox {
  border-radius: 50%;
  padding: 0.75rem;
  margin-top: 0.38rem;
  margin-bottom: 0.38rem;
  margin-right: 10px;
  background-color: #f1f5f9;
}

.schedule-avatar {
  min-width: 3rem;
  min-height: 3rem;
  margin: 0.4rem 0.7rem 0.4rem 0.1rem;
}

.staffCountCustom{
  //display: flex;
.p-accordion-header{
  .p-accordion-header-text{
      flex-grow: 1;
  }
}  
}
.input-number-custom{
  .p-inputnumber-button{
      border-radius: 5px;
     
     &.p-inputnumber-button-up {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
     }
    
    &.p-inputnumber-button{
     &.p-inputnumber-button-down{
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
     }
    }
   
  }
  .p-button-icon-only {
    &:hover {
      background: hsl(
        from var(--primary-color-2) h s calc(l + 5)
      ); //for light background
    }
  }
  
}