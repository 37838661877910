
.login{

    .login-header{

    img{
       // width: 50%;
    }
}
.spacing {
    margin-right: 1rem;
}
}

