@import "../../../assets/styles/scss/variables";
@import "../../../assets/styles/scss/mixins";
@import "../../../assets/styles/scss/typography";

/*----fb menu---*/

.addMenuContainer {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .add-btn-outer {
        display: flex;
        flex: 0;
        height: calc(100% - 5px);

        .addMenubox-container {
            display: flex;
            background-color: hsl(from var(--primary-color) h s calc(l + 40));
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            border: 1px dashed var(--primary-color);
            padding: 1.5rem;
            flex-direction: column;
            margin-right: 0.5rem;
            min-width: 140px;

            h6 {
                color: var(--primary-color);
                font-weight: 500;
                font-size: 1rem;
            }

            i {
                font-size: 2rem;
                color: var(--primary-color);
            }

            &:hover {
                color: var(--font-color);

                i {
                    color: var(--font-color);
                }

                h6 {
                    color: var(--font-color);
                }
            }
        }

        a {
            color: var(--primary-color);
            text-decoration: none;

            &:hover {
                color: var(--font-color);

                i {
                    color: var(--font-color);
                }

                h6 {
                    color: var(--font-color);
                }
            }
        }
    }

    .view-btn-scroll-container {
        display: flex;
        flex: 1;
        overflow-y: auto;
        padding-bottom: 5px;

        .viewMenubox-container {
            min-width: 180px;
            max-width: 180px;
            display: flex;
            background-color: var(--bg-color);
            flex-direction: column;
            color: #fff;
            border-radius: 5px;
            margin-right: 0.5rem;
            border: 3px solid #efefef;
            border-radius: 5px;
            // min-width: 180px;
            //width: 180px;
            height: 163px;

            &.custom-height {
                height: 142px;
                min-height: 135px;
            }

            &:hover {
                border: 3px solid var(--primary-color);
                // @include box-shadow-primary-custom-txtBx;
            }

            &:active {
                border: 3px solid var(--primary-color);
                color: var(--primary-color);
            }

            &.active {
                border: 3px solid var(--primary-color);
                color: var(--primary-color);
            }

            .menubox-img-contanier {
                min-height: 120px;
                display: flex;
                flex: 1;
                /* justify-content: center;
                text-align: center; */
                flex-direction: column;
                color: #fff;
                position: relative;
                justify-content: center;
                height: 120px;
                min-height: 120px;
                overflow: hidden;
                align-items: center;
                justify-content: center;

                img {
                    //border-radius: 5px 5px 0 0;
                    height: 100%;
                    width: auto;
                    object-fit: cover;
                    object-position: center center;
                }

                h6 {
                    position: absolute;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                }
                .menu-title {
                    position: absolute;
                    z-index: 1;
                    /* bottom: 0.5rem; */
                    text-align: center;
                    color: var(--font-color);
                    padding: 0 5px;
                    overflow: hidden;
                    width: 100%;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    &.position-btm {
                        bottom: 0;
                        //background-color: #575757;
                        --adjusted-lightness: calc(var(--primary-color-lightness) + var(--lightness-adjustment-4));
                        background-color: hsl(var(--primary-color-hue), var(--primary-color-saturation), var(--adjusted-lightness)); /* Apply the adjusted HSL background color */
                        color: var(--secondary-color);
                    }
                }

                .overlay {
                    background: rgba(var(--secondary-color), 0.6);
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    border-radius: 5px 5px 0 0;
                }

                &.category {
                    min-height: 120px;
                    width: 100%;
                    color: var(--font-color);
                    font-weight: 500;
                    border-bottom: 1px solid #fff;
                    position: relative;
                    justify-content: center;

                    &.custom-height {
                        height: 90px;
                        min-height: 90px;
                        overflow: inherit;
                    }
                }
            }

            .menubox-toolbox {
                display: flex;
                flex: 2;
                justify-content: space-between;
                font-size: 2rem;
                padding: 0.3rem 0.3rem;
                align-items: center;

                .view {
                    color: var(--primary-color);
                    font-size: 1.9rem;

                    &:hover {
                        color: var(--font-color);
                        cursor: pointer;
                    }
                }

                .edit {
                    --adjusted-lightness: calc(var(--secondary-color-lightness) + var(--lightness-adjustment-2));
                    color: hsl(var(--secondary-color-hue), var(--secondary-color-saturation), var(--adjusted-lightness)); 
                    //color: var(--green-color);
                    
                    font-size: 1.5rem;

                    &:hover {
                        color: var(--font-color);
                        cursor: pointer;
                    }
                }

                .schedule {
                    //color: var(--blue-color);
                    //color: hsl(from var(--blue-color) h s calc(l + 20));
                    --adjusted-lightness: calc(var(--secondary-color-lightness) + var(--lightness-adjustment-2));
                    color: hsl(var(--secondary-color-hue), var(--secondary-color-saturation), var(--adjusted-lightness)); 
                    font-size: 1.5rem;

                    &:hover {
                        color: var(--font-color);
                        cursor: pointer;
                    }
                }

                .delete {
                    //color: var(--red-color);
                    --adjusted-lightness: calc(var(--secondary-color-lightness) + var(--lightness-adjustment-2));
                    color: hsl(var(--secondary-color-hue), var(--secondary-color-saturation), var(--adjusted-lightness)); 
                    font-size: 1.5rem;

                    &:hover {
                        color: var(--font-color);
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .p-skeleton {
        // height: calc(100% - 5px) !important;
    }
}

.viewMenubox-container {
    display: flex;
    background-color: var(--bg-color);
    flex-direction: column;
    color: #fff;
    border-radius: 5px;
    margin-right: 0.5rem;
    border: 3px solid #efefef;
    border-radius: 5px;
    //min-width: 180px;
    min-height: 210px;
    height: 210px;
    //width: 180px;

    &:hover {
        border: 3px solid var(--primary-color);
        // @include box-shadow-primary-custom-txtBx;
    }

    &:active {
        border: 3px solid var(--primary-color);
        color: var(--primary-color);
    }

    &.active {
        border: 3px solid var(--primary-color);
        color: var(--primary-color);
    }
    &.item {
        //height: 255px;
    }
    &.menu-new-height {
        height: 170px !important;
        min-height: 150px !important;
    }
}

.menubox-img-contanier {
    display: flex;
    flex: 1;
    /* justify-content: center;
    text-align: center; */
    flex-direction: column;
    color: #fff;
    position: relative;
    justify-content: center;
    height: 120px;

    img {
        //border-radius: 5px 5px 0 0;
        height: 100%;
        //height: auto;
    }

    h6 {
        position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }
    .menu-title {
        position: absolute;
        z-index: 1;
        /* bottom: 0.5rem; */
        text-align: center;
        color: var(--font-color);
        padding: 0 5px;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .overlay {
        background: rgba(var(--secondary-color), 0.6);
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 5px 5px 0 0;
    }

    &.category {
        min-height: 120px;
        display: flex;
        flex: 1;
        width: 100%;
        color: var(--font-color);
        flex-direction: column;
        font-weight: 500;
        border-bottom: 1px solid #fff;
        position: relative;
        justify-content: center;
        height: 120px;
        overflow: hidden;
        align-items: center;
        justify-content: center;

        img {
            height: 100%;
            width: auto;
            object-fit: cover;
            object-position: center center;
        }
    }
}

.menubox-toolbox {
    display: flex;
    flex: 0;
    justify-content: space-between;
    font-size: 2rem;
    padding: 0.3rem 0.3rem;
    align-items: center;
    .view {
        color: var(--primary-color);
        font-size: 1.9rem;

        &:hover {
            color: var(--font-color);
        }

        &.disabled {
            pointer-events: none;
            filter: none;
            opacity: 0.5;

            &:hover {
                cursor: not-allowed !important;
            }
        }
    }

    .edit {
        //color: var(--green-color);
        --adjusted-lightness: calc(var(--secondary-color-lightness) + var(--lightness-adjustment-2));
        color: hsl(var(--secondary-color-hue), var(--secondary-color-saturation), var(--adjusted-lightness)); 
        font-size: 1.5rem;

        &:hover {
            color: var(--font-color);
        }
        &.disabled {
            pointer-events: none;
            filter: none;
            opacity: 0.5;

            &:hover {
                cursor: not-allowed !important;
            }
        }
    }

    .delete {
        //color: var(--red-color);
        --adjusted-lightness: calc(var(--secondary-color-lightness) + var(--lightness-adjustment-2));
        color: hsl(var(--secondary-color-hue), var(--secondary-color-saturation), var(--adjusted-lightness)); 
        font-size: 1.5rem;

        &:hover {
            color: var(--font-color);
        }
        &.disabled {
            pointer-events: none;
            filter: none;
            opacity: 0.5;

            &:hover {
                cursor: not-allowed !important;
            }
        }
    }

    .menu-title {
        //position: absolute;
        // z-index: 1;
        /* bottom: 0.5rem; */
        text-align: center;
        color: var(--font-color);
        padding: 0 5px;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 500;
        font-size: 1rem;
    }

    &.tools {
        background-color: #fff;
    }
    + .menubox-toolbox {
        border-top: 1px solid #fff;
    }
}
.add-btn-outer {
    display: flex;
    flex: 0;
    // height:255px;
    height: 210px;

    .addMenubox-container {
        display: flex;
        background-color: hsl(from var(--primary-color) h s calc(l + 40));
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        border: 1px dashed var(--primary-color);
        padding: 1.5rem;
        flex-direction: column;
        margin-right: 0.5rem;
        min-width: 140px;

        &:disabled {
            background: #f3f3f3;
            border: dashed 1px #dfdfdf;
            color: #dfdfdf !important ;
            pointer-events: none;

            i {
                color: #dfdfdf;
            }

            h6 {
                color: #dfdfdf;
            }

            &:hover {
                color: #dfdfdf !important ;
                pointer-events: none;
            }
        }

        h6 {
            color: var(--primary-color);
            font-weight: 500;
            //font-size: 1.2rem;
        }

        i {
            font-size: 2rem;
            color: var(--primary-color);
        }

        &:hover {
            color: var(--font-color);

            i {
                color: var(--font-color);
            }

            h6 {
                color: var(--font-color);
            }
        }
    }

    a {
        color: var(--primary-color);
        text-decoration: none;

        &:hover {
            color: var(--font-color);

            i {
                color: var(--font-color);
            }

            h6 {
                color: var(--font-color);
            }
        }
    }
}

.add-item-outer {
    display: flex;

    .p-skeleton {
        height: 100% !important;
    }
}

.add-new-section {
    flex: 0;
}
.menu-item-section {
    flex: 1;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    overflow-x: hidden;
}

.menu-ttle-bg {
    background: #f3f3f3;
    padding: 0.4rem;
    border-radius: 3px;
    //font-weight: 600;
    font-size: 1.2rem;
}

.back-icon {
    font-size: $normal-title-sz;
    font-weight: 600;

    &:hover {
        cursor: pointer;
        color: var(--primary-color);
    }
}
.title {
    font-size: $button-font-sz;
    font-weight: 500;
}

.history-item {
    color: #085fe1;
    text-decoration: underline;
    cursor: pointer;
    word-break: break-word;

    &:hover {
        color: var(--font-color);
    }
}

.view-preview {
    transition: all 1s;
    .delete {
        display: none;
        position: absolute;
        /* left: 50%; */
        /* top: 50%; */
        background: #fff;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        align-items: center;
        justify-content: center;
        transition: all 1s;
    }

    &:hover {
        transition: all 1s;
        .delete {
            display: flex;
        }
    }
}

.wine-ad-info-delete {
    font-size: 1.2rem;
    background: var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 40px;
    height: 40px;
    color: var(--red-color);

    &:hover {
        background: #eae9e9;
        transition: all 0.5s;
        i {
            opacity: 0.8;
        }
    }
}
.img-thumb-main-small {
    transition: all 1s;
    margin-right: 5px;
    border: 1px solid #ebebeb;
    width: 60px;
    height: 40px;
    .delete {
        display: none;
        position: absolute;
        /* left: 50%; */
        /* top: 50%; */
        background: #fff;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        align-items: center;
        justify-content: center;
        transition: all 1s;
        font-size: 1rem;
    }

    &:hover {
        transition: all 1s;
        .delete {
            display: flex;
        }
    }
}

.item-display {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.in-active {
    background-color: #ededed !important;
    opacity: 1;

    .menu-title {
        color: #919191 !important;
        
    }
    .position-btm{
        --adjusted-lightness: calc(var(--secondary-color-lightness) + var(--lightness-adjustment-5));
          background-color: hsl(var(--secondary-color-hue), var(--secondary-color-saturation), var(--adjusted-lightness)) !important;
    }

    .view {
        color: #919191 !important;

        &:hover {
            color: var(--primary-color) !important;
        }
    }

    .tools {
        background-color: #ededed !important;
    }
}
.viewMenubox-container {
    &.in-active {
        .move-btn-outer {
            .btn-success {
                z-index: 1;
                position: relative;
            }
        }

        img {
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
        }
    }
}

.add-price-container {
    background: #f7f7f7;
    padding: 0.5em;
    border-radius: 5px;
    border: 1px solid #dbdbdb;
}

.add-p-bg {
    background-color: hsl(from var(--primary-color) h s calc(l + 40));
    border-radius: 5px;
}
.shift-add-p-bg {
    background-color: hsl(from var(--primary-color) h s calc(l + 45));
    border-radius: 5px;
}
.add-p-bg-sub {
    //background-color: #f7f7f7;
    background-color: #ffffff;
    border-radius: 5px;
    border-bottom: 2px solid #dfdfdf;
}
.add-prize-scroll {
    overflow-y: auto;
    max-height: 275px;
    border-top: 3px solid #ddd;
    padding-top: 0.8rem;
}

.category-tab {
    position: sticky;
    top: 240px;
    z-index: 9;

    .nav-pills {
        .nav-link {
            color: var(--primary-color);
            &.active {
                background-color: var(--primary-color);
                color: var(--white-color);
            }
        }
    }
}

/*---------move item--------*/
.move-btn-outer {
    position: absolute;
    right: 0;
    top: 0;
    //z-index: 0;

    > .btn {
        background-color: transparent !important;
        border-color: transparent !important;
        min-width: 0;
        padding: 0;
        display: flex;
        align-items: center;
        cursor: pointer;

        &.dropdown-toggle::after {
            display: none;
        }

        &:focus {
            box-shadow: none !important;
        }

        &:hover {
            i {
                right: 0;
            }
        }
    }
}

.move-item {
    position: relative;
    .treeselect-demo {
        width: 100%;
        width: 100%;
        z-index: 9;
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 0;

        .card {
            padding: 0.2rem;

            .p-treeselect {
                &.p-treeselect-label {
                    padding: 0.55rem 0.55rem;
                }
            }
        }
    }
}

.move-round {
    position: absolute;
    right: 0.3rem;
    top: 0.3rem;
    padding: 0.5rem;
    color: #000;
    font-weight: bolder;
    font-size: 1.5rem;

    i {
        height: 15px;
        width: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.move-list {
    display: flex !important;
    i {
        margin-right: 0.3rem !important;
    }
}

.item {
    .custom-height {
        overflow: inherit;
    }
}
.breadcrumb-custom {
    background: #f1f1f1;
    padding: 0.5rem;
    font-size: 0.98rem !important;
    //color: #706f6f;
}
