@import "../../../assets/styles/scss/_variables.scss";
@import "../../../assets/styles/scss/_typography.scss";

.delete {
    color: var(--red-color);
    font-size: 1.2rem;

    &:hover {
        cursor: pointer;
        color: hsl(from var(--red-color) h s calc(l - 8));
    }
}

.edit {
    color: var(--green-color);
    font-size: 1.2rem;

    &:hover {
        cursor: pointer;
        color: hsl(from var(--green-color) h s calc(l - 10));
    }
}
.view {
    color: var(--primary-color);
    font-size: 1.2rem;

    &:hover {
        cursor: pointer;
        color: hsl(from var(--primary-color) h s calc(l - 10));
    }
}

.copy {
    color: var(--blue-color);
    font-size: 1.5rem;

    &:hover {
        cursor: pointer;
        color: hsl(from var(--blue-color) h s calc(l - 10));
    }
}

.react-datepicker-wrapper {
    .datePicIc {
        position: absolute;
        right: 13px;
        bottom: 14px;
        cursor: pointer;
    }
}

img.dataAvatar {
    height: 32px;
    width: 32px;
    border-radius: 50% !important;
    margin-right: 0.5rem;
}

.customer-badge {
    background-color: rgba(var(--blue-color), 0.12);
    border-radius: 1rem;
    //display: block;
    text-align: center;
    padding: 0.2rem 0.8rem;
    min-width: 90px;
    color: var(--blue-color);

    &.admin {
        color: var(--primary-color);
        background-color: rgba(var(--primary-color-rgb, 0.12));
    }
    &.manager {
        color: var(--table-violet);
        background-color: rgba(var(--table-violet), 0.12);
    }
    &.host {
        color: var(--green-color);
        background-color: rgba(var(--green-color), 0.12);
    }
    &.server {
        color: var(--red-color);
        background-color: rgba(var(--red-color), 0.12);
    }
    /* &.user{
        color: var(--blue-color);
        background-color: rgba(var(--blue-color),.12);
    } */
}

.react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: #ccc;
}

.position-ralative {
    position: relative;
}

.viewPassSmall {
    position: absolute;
    right: 18px;
    bottom: 4px;
    cursor: pointer;
}

.mandatory {
    color: var(--red-color);
}

.custom-input-group {
    input {
        border-top-left-radius: 0.25rem !important;
        border-bottom-left-radius: 0.25rem !important;
    }

    //border-radius: 0.25rem;
}
/****-------scroll fix----*/

.scroll-fix-container {
    height: calc(100vh - 75px);
    flex-direction: column;
}
.user-scroll-fix {
    overflow-y: auto;
    flex: 1;
}

.user-list-conainer {
    height: 100%;
    overflow: auto;
}

.sticky-card-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #f7f7f7;
}

@media (max-width: 767.98px) {
    .manage-user-sticky {
        position: relative;
    }
    .scroll-fix-container {
        height: auto;
    }
}

/***---scroll fix new---*/
/* .user-list-conainer {
    height: 100%;
    overflow: hidden;
} */

/*----datagrid----*/
.p-datatable {
    .p-datatable-thead {
        tr {
            th {
                padding: 0.5rem 0.5rem;
                font-weight: 500;
            }
        }
    }
    .p-datatable-tbody {
        tr {
            td {
                padding: 0.5rem 0.5rem;
                border: solid #dee2e6;
                border-width: 0 0 1px;
            }
        }
    }
}

.p-paginator {
    padding: 0rem 1rem;

    .p-dropdown {
        height: 2.5rem;
    }
    .p-paginator-pages {
        .p-paginator-page {
            min-width: 2rem;
            height: 2rem;
            margin: 0.143rem;
        }
    }
    .p-dropdown-label {
        &.p-inputtext {
            padding: 0.35rem 0.35rem;
        }
    }
}
