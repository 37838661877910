@import "../../../assets/styles/scss/_variables";
@import "../../../assets/styles/scss/_typography";
@import "../../../assets/styles/scss/_mixins";

.nav-scroller,
.nav-scroller-wrapper {
  display: flex;
  background-color: #f9f9f9;
}

.nav-scroller {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.nav-scroller-nav {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  z-index: 1;
  align-self: center;
  //padding-bottom: 30px;
  //margin-bottom: -30px;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.nav-scroller-content,
.nav-scroller-items {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.nav-scroller-content {
  min-height: 44px;
  will-change: transform;
}

.nav-scroller-content:not(.no-transition) {
  transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

ul.nav-scroller-content {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-scroller-item,
.nav-scroller-group-title {
  padding: 0.25rem 1rem;
  margin: 0.25rem 0;
}

.nav-scroller-item {
  flex: 1 0 auto;
  display: block;
  font-size: 1.125rem;
  text-align: center;
  border-left: 1px solid transparent;
  border-right: 1px solid #ddd;
}

.nav-scroller-item:last-child {
  border-right-color: transparent;
}

.nav-scroller-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 44px;
  min-height: 44px;
  height: 100%;
  padding: 0.5rem;
  font-size: 1.25rem;
  color: #000;
  background: #fff;
  border: 1px solid #ddd;
  transition:
    color 0.3s,
    opacity 0.3s;
  cursor: pointer;
}

.nav-scroller-btn:hover {
  color: #666;
}

.nav-scroller-btn:focus {
  outline: 0;
}

.nav-scroller-btn:not(.active) {
  opacity: 0;
  pointer-events: none;
}

.nav-scroller-icon {
  display: inline-block;
  position: relative;
  top: -0.1em;
  width: 1em;
  height: 1em;
  fill: currentColor;
  vertical-align: middle;
}

.nav-scroller-btn--left {
  left: 0;
}

.nav-scroller-btn--right {
  right: 0;
}

.nav-scroller-logo {
  display: inline-block;
  padding: 0.5rem;
  margin-right: 1rem;
  font-size: 1.75rem;
  font-weight: bold;
}

.nav-scroller--controls-inline .nav-scroller-content {
  padding: 0 44px;
}

.nav-scroller--controls-inline .nav-scroller-btn:not(.active) {
  color: #ddd;
  opacity: 1;
}

.nav-scroller--expand .nav-scroller-nav,
.nav-scroller--expand .nav-scroller-content {
  width: 100%;
}

.nav-scroller--right .nav-scroller-nav {
  margin-left: auto;
}

.nav-scroller--snap .nav-scroller-nav {
  -ms-scroll-snap-type: x proximity;
  scroll-snap-type: x proximity;
  scroll-padding: 0 44px;
}

.nav-scroller--snap .nav-scroller-content:not(.no-transition) .nav-scroller-item {
  scroll-snap-align: start;
}

.nav-scroller--snap .nav-scroller-content:not(.no-transition).snap-align-end .nav-scroller-item {
  scroll-snap-align: end;
}

.nav-scroller-group {
  flex-direction: column;
  align-items: flex-start;
}

.nav-scroller-group:not(:first-child) {
  border-left: 1px solid #ddd;
  margin-left: -1px;
}

.nav-scroller-group-title {
  font-weight: bold;
}

.nav-scroller-items .nav-scroller-item {
  text-align: center;
  min-width: 80px;
}

/*-------menu navigation---------*/
/* .scroll-menu-container{

    .nav-scroller{
        background: transparent;
    }

    .nav-scroller-nav {
        position: relative;  

        .nav-scroller-item{
            position: relative;
            display: flex;
            justify-content: center;
            padding: 0;
            margin:.25rem 0.2rem;
            border-left: none;
            border-right: none;

            img{
                border-radius: 10px;
            }

            .menu-title{
                position: absolute;
                z-index: 1;
                bottom: 0.5rem;
                text-align: center;  
                color: #fff;
                padding: 0 5px;
                overflow: hidden;
                width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .overlay{
                display: block;
                width: 100%;
                height: 100%;
                background: #00000099;
                position: absolute;
                border-radius: 10px;
            }
        }
    }

}
 */

/*-----guest landing-----*/

.user-landing {
  padding: 1rem 0 1rem 0;

  .link-container {
    border: 1px solid var(--white-color);
    border-radius: 10px;
    padding: 1.5rem;

    h5 {
      color: var(--font-color);
      font-size: var(--normal-title-sz);
      font-weight: 600;
    }
  }

  .login-header {
    img {
      &.welcome-logo {
        width: 50%;
      }
    }
    .welcome-user-profile {
      display: flex;
      align-items: center;
      //overflow: hidden;

      img {
        // width: auto !important;
      }
    }
  }
  .login-header {
    img {
      // width: 25%;
    }
    &.welcome-login {
      .logo-sec-left {
        img {
          height: 40px;
        }
      }

      .mobile-logo {
        img {
          //width: 100%;
        }
      }
    }
  }

  .res-img {
    border-radius: 10px;
  }
}
.image-left {
  float: left;
  min-width: 260px;
  max-width: 50% !important;
  margin: 0 1rem 1rem 0px;
}
.sticky-welcome-menu {
  position: sticky;
  bottom: 0;
  padding: 0.5rem 0;
  border-radius: 0.5rem;
  background-color: #ffffff42;
  //color: hsl(from var(--secondary-color) h s calc(l + 20));
}
@media (max-width: 802.98px) {
  .image-left {
    max-width: 100% !important;
  }
}

@media (max-width: 767.98px) {
  .image-left {
    max-width: 100% !important;
  }

  .welcomePage {
    .container {
      max-width: 100% !important;
    }
  }
}

@media (max-width: 560.98px) {
  .welcomePage {
    .container {
      max-width: 100% !important;
    }
  }
}

.image-right {
  float: right;
  min-width: 260px;
  max-width: 50% !important;
  margin: 0 1rem 1rem 0px;
}
