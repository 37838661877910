
.center-logo-container {
    display: flex;
    justify-content: space-between;
    .center-logo {
        img {
            max-width: 50%;
            text-align: center;
        }
    }
}

.CircularProgressbar-text {
    font-size: 22px !important;
    font-weight: 700;
}