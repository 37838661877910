@import "../../../assets/styles/scss/_variables.scss";
@import "../../../assets/styles/scss/_typography.scss";

.password-criteria {
    ul {
        // margin: 0;
        padding: 0;

        li {
            color: var(--font-color);
            font-size: 14px;
            list-style: none;
        }
    }
}
