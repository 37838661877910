/* .animation-container {
  position: absolute;
  animation: moveAnimation 5s infinite;
}

@keyframes moveAnimation {
  0% { top: 0; left: 0; }
  50% { top: 50%; left: 50%; }
  100% { top: 100%; left: 100%; }
} */

.animation-container-character {
  /* position: fixed;
  transform: translate(0px, 0px);
  transition: transform 5s ease-in-out; */
  z-index: 999999 !important;
   width: 80px; /* Default size */
  height: 80px; /* Default size */

}

/* Medium screens (e.g., tablets) */
@media (min-width: 768px) {
  .animation-container-character {
    width: 100px;
    height: 100px;
  }
}

/* Large screens (e.g., laptops, desktops) */
@media (min-width: 1024px) {
  .animation-container-character {
    width: 120px;
    height: 120px;
  }
}

/* Extra large screens */
@media (min-width: 1440px) {
  .animation-container-character {
    width: 150px;
    height: 150px;
  }
}